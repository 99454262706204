import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    TextField,
    Typography,
} from '@mui/material';
import {fetchProjects} from "../../api/api";
import {useTheme} from "@mui/styles";

const ProjectListDialog = (props) => {
    const [projects, setProjects] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const theme = useTheme();
    useEffect(() => {
        if (props.open) { // Optional: Fetch only when dialog is open
            fetchProjects(-1, 0).then((response) => {
                if (response === null || response === undefined) {
                    console.error("Could not fetch project data...");
                    return;
                }
                setProjects(response);
            }).catch((error) => {
                console.error("Error fetching projects:", error);
            });
        }
    }, [props.open]); // Re-fetch if 'open' changes

    const filteredProjects = projects.filter((project) => {
        const title = project.title.toLowerCase();
        const query = searchQuery.toLowerCase();
        return title.includes(query) || project.id.toString().includes(query);
    });

    return (
        <Dialog
            sx={{minWidth: "300px"}}
            open={props.open}
            onClose={() => props.onClose(null)}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Select a Project</DialogTitle>
            <DialogContent dividers>

                <TextField
                    label="Search Projects"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by title or ID"
                />

                {filteredProjects.length > 0 ? (
                    <List>
                        {filteredProjects.map((project) => (
                            <ListItem key={project.id} disablePadding>
                                <ListItemButton
                                    sx={{
                                        background: theme.palette.lightGreyLight,
                                        borderWidth: "1px",
                                        borderColor: "#000000",
                                        color: "black",
                                        padding: "10px",
                                        marginBottom: "5px",
                                        borderRadius: "4px",

                                        '&:hover': {
                                            backgroundColor: props.currentUserID === project.id ? "#0d47a1" : theme.palette.lightGreyDark,
                                        },
                                    }}
                                    onClick={() => props.onClose(project.id)}
                                >
                                    <Typography variant="body1">
                                        {project.title} {"<"}ID: {project.id}{">"}{" "}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No projects found.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectListDialog;
