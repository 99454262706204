import React, {useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {Add, Delete, Edit} from '@mui/icons-material';
import {DataGrid} from '@mui/x-data-grid';
import {changeRubric, createRubric, deleteRubric, getAllRubrics, getRubricByID} from "../../api/api";

import RubricEditDialog from './RubricEditDialog';
import RubricCreateDialog from './RubricCreateDialog';
import RubricView from "../rubric_judge_view/RubricView";

const RubricList = ({currentUserID}) => {
    const [rubrics, setRubrics] = useState([]);
    const [filteredRubrics, setFilteredRubrics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [selectedRubric, setSelectedRubric] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [notification, setNotification] = useState({open: false, message: '', severity: 'success'});

    const [rubricDialogOpen, setRubricDialogOpen] = useState(false);

    const [viewedRubric, setViewedRubric] = useState(null);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
    const fetchRubrics = async () => {
        try {
            const res = await getAllRubrics();
            if (res != null) {
                setRubrics(res);
                setFilteredRubrics(res);
            }
        } catch (error) {
            setNotification({open: true, message: 'Failed to fetch rubrics.', severity: 'error'});
        } finally {
            setLoading(false);
        }
    };
    fetchRubrics();
}, []);

useEffect(() => {
    // get search query from URL for initial search
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('search');
    // url decode the search query
    if (searchQuery) {
        const decodedQuery = decodeURIComponent(searchQuery);
        setSearch(decodedQuery);
        onSearchChange(decodedQuery);
    }
    //eslint-disable-next-line
}, [rubrics]);

    const onSearchChange = (value) => {
        setSearch(value);
        if (value === "") {
            setFilteredRubrics(rubrics);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = rubrics.filter(rubric => {
                    return Object.values(rubric).some(val => {
                            return String(val).toLowerCase().includes(lowercasedValue)
                        }
                    ) || rubric.id.toString().toLowerCase().includes(lowercasedValue.substring(1))
                || rubric.judge_name.toLowerCase().includes(lowercasedValue)

                }
            );
            setFilteredRubrics(filtered);
        }
    }

    const handleSearchChange = (event) => {
        const value = event.target.value;
        onSearchChange(value);
    };

    const handleAdd = () => {
        setCreateDialogOpen(true);
    };

    const handleEditClick = (rubric) => {
        setSelectedRubric(rubric);
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (rubric) => {
        setSelectedRubric(rubric);
        setDeleteDialogOpen(true);
    };

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const confirmDelete = async () => {
        if (selectedRubric) {
            setLoading(true);
            const success = await deleteRubric(selectedRubric.id);
            if (success) {
                const updatedRubrics = rubrics.filter(r => r.id !== selectedRubric.id);
                setRubrics(updatedRubrics);
                setFilteredRubrics(updatedRubrics.filter(r =>
                    Object.values(r).some(val =>
                        String(val).toLowerCase().includes(search.toLowerCase())
                    )
                ));
                setNotification({open: true, message: 'Rubric deleted successfully!', severity: 'success'});
            } else {
                setNotification({open: true, message: 'Failed to delete rubric.', severity: 'error'});
            }
            setLoading(false);
            setDeleteDialogOpen(false);
            setSelectedRubric(null);
        }
    };

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false);
        setSelectedRubric(null);
    };

    const handleEditChange = async (updatedRubric) => {
        setProcessing(true);
        const updated = await changeRubric(updatedRubric);
        if (updated) {
            const updatedRubrics = rubrics.map(r => (r.id === updated.id ? updated : r));
            setRubrics(updatedRubrics);
            setFilteredRubrics(updatedRubrics.filter(r =>
                Object.values(r).some(val =>
                    String(val).toLowerCase().includes(search.toLowerCase())
                )
            ));
            setNotification({open: true, message: 'Rubric updated successfully!', severity: 'success'});
        } else {
            setNotification({open: true, message: 'Failed to update rubric.', severity: 'error'});
        }
        setProcessing(false);
        setEditDialogOpen(false);
        setSelectedRubric(null);
    };

    const handleEditCancel = () => {
        setEditDialogOpen(false);
        setSelectedRubric(null);
    };

    const handleCreate = async (newRubric) => {
        setProcessing(true);
        const createdRubric = await createRubric(newRubric);
        if (createdRubric) {
            const updatedRubrics = [...rubrics, createdRubric];
            setRubrics(updatedRubrics);
            setFilteredRubrics(updatedRubrics.filter(r =>
                Object.values(r).some(val =>
                    String(val).toLowerCase().includes(search.toLowerCase())
                )
            ));
            setNotification({open: true, message: 'Rubric created successfully!', severity: 'success'});
        } else {
            setNotification({open: true, message: 'Failed to create rubric.', severity: 'error'});
        }
        setProcessing(false);
        setCreateDialogOpen(false);
    };

    const handleCreateCancel = () => {
        setCreateDialogOpen(false);
    };

    const handleCellDoubleClick = async (params) => {
        let id = params.row.id;
        setRubricDialogOpen(true);

        const res = await getRubricByID(id)
        if (res === null) {
            console.error("Could not fetch rubric for rubric view");
            return;
        }
        setViewedRubric(res);
    };

    const handleRubricDialogClose = () => {
        setRubricDialogOpen(false);
        setViewedRubric(null);
    };

    const columns = useMemo(() => [
        {field: 'id', headerName: 'ID', width: 90},
        {field: "project_name", headerName: "Project Name", width: 200},
        {field: 'project_id', headerName: 'Project ID', width: 150},
        {field: 'judge_name', headerName: 'Judge Name', width: 200},
        {field: 'checked_in', headerName: 'Checked In', width: 200,
            renderCell: (params) => (
                <Tooltip title={(params.value === 1) ? "Yes" : "No"}>
                    <span>{(params.value === 1) ? "Yes" : "No"}</span>
                </Tooltip>
            )
        },
        {field: 'judge_id', headerName: 'Judge ID', width: 150},
        {field: 'score', headerName: 'Weighted Score', width: 150},
        {
            field: 'completed', headerName: 'Completed', width: 150,
            renderCell: (params) => (
                <Tooltip title={(params.value === 1) ? "Yes" : "No"}>
                    <span>{(params.value === 1) ? "Yes" : "No"}</span>
                </Tooltip>
            )
        },
        {
            field: 'recommended', headerName: 'Recommended', width: 150,
            renderCell: (params) => (
                <Tooltip title={(params.value === 1) ? "Yes" : "No"}>
                    <span>{(params.value === 1) ? "Yes" : "No"}</span>
                </Tooltip>
            )
        },
        {
            field: 'priv_feed',
            headerName: 'Private Feedback',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{(params.value === "")? "<none provided>" : params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'pub_feed',
            headerName: 'Public Feedback',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{(params.value === "")? "<none provided>" : params.value}</span>
                </Tooltip>
            )
        },
        {field: 'order', headerName: 'Order', width: 100},
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleEditClick(params.row)} color="primary">
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(params.row)} color="error">
                        <Delete/>
                    </IconButton>
                </>
            ),
        },
    ], []);

    const dataGridStyles = {
        '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'center',
        },
        '& .MuiDataGrid-columnHeader': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    return (
        <Box sx={{margin: 3, display: 'flex', flexDirection: 'column'}}>

            <Box sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'space-between',
                marginBottom: 2
            }}>
                <TextField
                    label="Search Rubrics"
                    variant="outlined"
                    value={search}
                    onChange={handleSearchChange}
                    sx={{width: isSmallScreen ? '100%' : '300px', marginBottom: isSmallScreen ? 2 : 0}}
                />
                <Button
                    variant="contained"
                    startIcon={<Add/>}
                    onClick={handleAdd}
                >
                    Add Rubric
                </Button>
            </Box>
            <Box sx={{flexGrow: 1}}>
                {loading || processing ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <DataGrid
                        rows={filteredRubrics}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 20, 50]}

                        sx={dataGridStyles}
                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        onCellDoubleClick={handleCellDoubleClick}
                    />
                )}
            </Box>
            <RubricEditDialog
                open={editDialogOpen}
                rubric={selectedRubric}
                onChange={handleEditChange}
                onCancel={handleEditCancel}
                currentUserID={currentUserID}
            />
            <RubricCreateDialog
                open={createDialogOpen}
                onCreate={handleCreate}
                onCancel={handleCreateCancel}
                currentUserID={currentUserID}
            />
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCancelDelete}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this rubric?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={rubricDialogOpen}
                onClose={handleRubricDialogClose}
            >
                <DialogTitle>Rubric Viewer</DialogTitle>
                <DialogContent>
                    <Box>
                        {(viewedRubric === null) ?

                            <div>
                                <Typography variant="body">Loading...</Typography>
                            </div>

                            :
                            <div>
                                <RubricView rubric={viewedRubric} showMode={true}/>
                                <Alert severity="warning">To edit a judge's rubric you must go into their mock view.
                                    You cannot edit scores from the admin view.
                                </Alert>
                            </div>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRubricDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={() => setNotification({...notification, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    onClose={() => setNotification({...notification, open: false})}
                    severity={notification.severity}
                    sx={{width: '100%'}}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    );

};

export default RubricList;