import React from 'react';
import {
    Alert,
    Box,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";

import RubricList from "../reusable/RubricList";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import DefaultPageContainer from "../reusable/DefaultPageContainer";

const RubricListPage = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <div style={{marginBottom: "15px", marginTop:"10px", padding: '0 16px'}}>
                {isSmallScreen
                    ? <Typography variant="h5">Rubric List</Typography>
                    : <Typography variant="h4">Rubric List</Typography>
                }

                <Typography variant="body3" sx={{fontSize: "15px", marginBottom: "15px"}}>
                    Add, assign, and remove rubrics.
                </Typography>
            </div>
            <div>
                <Alert icon={<TipsAndUpdatesIcon fontSize="inherit"/>} severity="info">
                    Tip: Double tap any rubric to view a judge's scoring in detail
                </Alert>
            </div>
            <Box style={{alignItems: "center", alignContent: "center", textAlign: "center"}}>
                <RubricList currentUserID={props?.userData?.real_person_id}/>
            </Box>
        </DefaultPageContainer>
    )
}

export default RubricListPage;