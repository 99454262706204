import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    TextField, // Import TextField for the search box
    Typography, Grid2,
} from '@mui/material';
import {fetchJudges, getJudgeRatingsForProject} from "../../api/api";
import {useTheme} from "@mui/styles";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const SmartJudgeListDialog = (props) => {
    const [judges, setJudges] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        // Fetch Judges when the dialog is opened
        if (props.open) { // Optional: Fetch only when dialog is open
            getJudgeRatingsForProject(props.projectID).then((response) => {
                if (response === null || response === undefined) {
                    console.error("Could not fetch judge data...");
                    return;
                }
                setJudges(response);
                setLoading(false)
            }).catch((error) => {
                console.error("Error fetching judges:", error);
                setLoading(false)
            });
            setLoading(true)
        }
    }, [props.open]); // Re-fetch if 'open' changes

    // Filter judges based on search query
    const filteredJudges = judges.filter((judge) => {
        const firstName = judge[0].first_name.toLowerCase();
        const lastName = judge[0].last_name.toLowerCase();
        const query = searchQuery.toLowerCase();
        return firstName.includes(query) || lastName.includes(query) || judge[0].id.toString().includes(query);
    });

    const getIcon = (points) => {
        if (points >= 2) {
            return <ThumbUpIcon sx={{float: "right", color:"#52be3b"}}/>;
        } else if (points <= 0) {
            return <ThumbDownIcon sx={{float: "right" , color:"#be3b3b"}}/>;
        } else {
            return <ThumbsUpDownIcon sx={{float: "right", color:"#de8544"}}/>;
        }
    }

    const getPoints = (str) => {
        try {
            return parseFloat(str).toFixed(2);
        }
        catch (e) {
            return 0;
        }
    }

    return (
        <Dialog
            sx={{minWidth: "300px", maxHeight: "600px"}} // Increased minWidth for better layout
            open={props.open}
            onClose={() => {
                setJudges([]); // Clear judges when dialog is closed
                props.onClose(null)
            }}
            fullWidth // Make dialog take full width up to minWidth
            maxWidth="sm" // Set a maximum width
        >
            <DialogTitle>Select a Judge</DialogTitle>
            <DialogContent dividers>
                {/* Search Box */}
                <TextField
                    label="Search Judges"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by first or last name"
                />
                {/* Judges List */}
                {filteredJudges.length > 0 ? (
                    <List>
                        {filteredJudges.map((judge) => (
                            <ListItem key={judge[0].id} disablePadding>
                                <ListItemButton
                                    sx={{
                                        background: props.currentUserID === judge[0].id ? "#1565c0" : theme.palette.lightGreyLight,
                                        borderWidth: "1px",
                                        borderColor: "#000000",
                                        color: props.currentUserID === judge[0].id ? "white" : "black",
                                        padding: "10px",
                                        marginBottom: "5px",
                                        borderRadius: "4px",
                                        '&:hover': {
                                            backgroundColor: props.currentUserID === judge[0].id ? "#0d47a1" : theme.palette.lightGreyDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setJudges([]);
                                        props.onClose(judge[0].id)
                                    }}
                                >
                                    <Grid2 container direction="column">
                                        <Grid2 item>
                                            <Typography variant="body1">
                                                {judge[0].first_name} {judge[0].last_name}{" "}
                                            </Typography>
                                        </Grid2>
                                        <Grid2 item container alignItems="center">
                                            <Typography variant="body2" color="textSecondary">
                                                {props.currentUserID === judge[0].id && "(You!) "}
                                                (Assigned to {judge[2]} rubric{(judge[2]=="1")? "" : "s"} - Rating: {getPoints(judge[1])})
                                            </Typography>
                                            <div style={{marginRight:"5px"}}/>
                                            {getIcon(getPoints(judge[1]))}
                                        </Grid2>
                                    </Grid2>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    loading === true? <Typography variant="body2" color="textSecondary">
                        Loading...
                    </Typography>:
                        <Typography variant="body2" color="textSecondary">
                            No judges found.
                        </Typography>


                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setJudges([]);
                    props.onClose(null)
                }} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SmartJudgeListDialog;
