import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';

const JudgeAddDialog = ({ open, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    highestEducation: '',
    strengths: '',
    avoid: '',
  });
  const [errors, setErrors] = useState({});

  const educationOptions = ['Bachelors', 'Masters', 'Doctorate'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateCSV = (input) => {
    return input
      .split(',')
      .map(item => item.trim())
      .join(',');
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formValues.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formValues.email.trim()) newErrors.email = 'Email is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    // Trim and format CSV inputs
    const formattedStrengths = validateCSV(formValues.strengths);
    const formattedAvoid = validateCSV(formValues.avoid);

    setFormValues({
      ...formValues,
      strengths: formattedStrengths,
      avoid: formattedAvoid,
    });

    if (validateForm()) {
      onSubmit({
        ...formValues,
        strengths: formattedStrengths,
        avoid: formattedAvoid,
      });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Judge</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            required
            fullWidth
            margin="normal"
            label="First Name"
            name="firstName"
            value={formValues.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastName"
            value={formValues.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            type="email"
            value={formValues.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            select
            fullWidth
            margin="normal"
            label="Highest Education Achieved"
            name="highestEducation"
            value={formValues.highestEducation}
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>
            {educationOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            label="Strengths (comma-separated)"
            name="strengths"
            value={formValues.strengths}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Avoid (comma-separated)"
            name="avoid"
            value={formValues.avoid}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JudgeAddDialog;
