import React from 'react';
import {useNavigate} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import {Box, Button, Typography} from "@mui/material";

const DashboardButton = (props) => {
    const navigate = useNavigate();

    const navigateToDashboard = async () => {
        navigate('/auth/dashboard');
    };

    if (props.version === "topbar") {
        return (
            <Box sx={{height:"100%", flexGrow: 1}}>
                <Button
                    onClick={navigateToDashboard}
                    sx={{
                        display: "flex", flexDirection: "column", alignItems: "center", height: '100%', flexGrow: 1,
                        '&:hover': {
                            backgroundColor: 'rgb(62,152,41)',
                        },
                    }}
                >
                    <div onClick={navigateToDashboard} aria-label="home">
                        <HomeIcon sx={{color: "#61EE57FF"}}/>
                    </div>
                    <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                        Home
                    </Typography>
                </Button>
            </Box>

        );
    } else {
        return (
            <Button
                onClick={navigateToDashboard}
                sx={{
                    textTransform: 'none',
                    width: "100%",
                    justifyContent: "flex-start",
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgb(53,119,47)',
                        color: 'white'
                    },
                }}
            >
                <div onClick={navigateToDashboard}  aria-label="home">
                    <HomeIcon sx={{color: "#48b240"}}/>
                </div>
                <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px"}}>
                    Dashboard
                </Typography>
            </Button>
        );
    }
};

export default DashboardButton;