import React, { useState, useEffect } from 'react';
import RubricCategoryEntry from "./RubricCategoryEntry";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/material";

const RubricCategory = (props) => {
    const [entries, setEntries] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [categoryWeight, setCategoryWeight] = useState(0);
    const [categoryTotalPoints, setCategoryTotalPoints] = useState(0);
    const [categoryScore, setCategoryScore] = useState(0);
    const [allGood, setAllGood] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    const recalculateScore = (key) => {

        let score = 0;
        entries.forEach((entry) => {
            score += entry.score;
        });
        setCategoryScore(score);
        props.data.score = score;
        entries[key].hasValue = true;
        setAllGood(allHasValue(entries));
        if(props.onChange !== undefined && !props.showMode)
            props.onChange();

    }

    const allHasValue = (entries) => {
        let all = true;
        entries.forEach((entry) => {
            if (!entry.hasValue) {
                all = false;
            }
        });
        return all;
    }

    const dictRef = props.data;

    useEffect(() => {
        setCategoryName(dictRef.name);
        setCategoryWeight(dictRef.weight);
        setCategoryTotalPoints(dictRef.max_score);
        setCategoryScore(dictRef.score);

        let entryList = []

        Object.entries(props.data.children).map(([key, value]) => {
            value.hasValue = false;
            entryList.push(value);
            return null;
        })
        setEntries(entryList);
        setLoading(true); // Set loading to true once data is loaded
    }, [dictRef, props.data.children]);

    const isRubric = categoryName.toLowerCase() === "rubric"
    const isShowMode = props.showMode === true; // Show mode is read-only

    const accordionStyle = {
        backgroundColor: allGood ? '#125598' : '#914040',
        color: 'white',
        margin: '10px',
    };

    if(isShowMode){
        accordionStyle.backgroundColor = '#383838';
    }

    if (!loading) {
        return null; // Do not render the component if data is not loaded
    }

    return (
        <div>
            {isRubric ? (
                <Typography style={{ marginLeft: "10px", marginTop: "15px" }} variant="h5" component="div">
                    Overall Evaluation
                </Typography>
            ) : null}

            <Accordion style={accordionStyle}>
                <AccordionSummary
                    expandIcon={allGood ?
                        <CheckIcon sx={{color:(props.showMode===true)? "#9aff78" : "#000000", paddingLeft: "5px"}}/> :
                        <WarningIcon sx={{color:(props.showMode===true)? "#ff7878" : "#000000", paddingLeft: "5px"}}/>}
                    sx={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: 'none',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'none',
                        },
                    }}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component="div">
                                {categoryName}
                            </Typography>
                            {!allGood && (
                                <Typography style={{ color: '#b0b0b0' }} variant="body2" component="span" sx={{ mt: 1 }}>
                                    (incomplete grade!)
                                </Typography>
                            )}
                        </Box>
                        <Typography variant="body3" component="div">{categoryScore} / {categoryTotalPoints}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {!isRubric && <i><Typography variant="body2" component="span">(Weight {categoryWeight})</Typography></i>}
                    <div style={{paddingTop: "15px"}}>
                        {entries.map((entry, index) => (
                            <RubricCategoryEntry
                                key={index}
                                index={index}
                                data={entry}
                                rubric={isRubric}
                                submitScore={recalculateScore}
                                showMode={isShowMode}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

export default RubricCategory;