import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Alert } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useTheme} from "@mui/styles";

const DiagnosticAccordion = ({ title, icon, messages, elements, status }) => {
    const theme = useTheme();
    
    const getAlertSeverity = (s) => {
        if (s === 1) {
            return "info";
        }
        if (s === 2) {
            return "warning";
        }
        if (s === 3) {
            return "error";
        }
        return "success";
    }

    const getSeverityIcon = (s) => {
        if (s === 1) {
            return <InfoIcon sx={{ color: "#bbbbbb" }} />;
        }
        if (s === 2) {
            return <WarningIcon sx={{ color: "#f1c40f" }} />;
        }
        if (s === 3) {
            return <ReportIcon sx={{ color: "#e74c3c" }} />;
        }
        return <CheckCircleIcon sx={{ color: "#5cb728" }} />;
    }

    const groupedMessages = messages.reduce((acc, msg) => {
        const severity = msg[0];
        if (!acc[severity]) {
            acc[severity] = [];
        }
        acc[severity].push(msg);
        return acc;
    }, {});

    return (
        <Accordion sx={{ backgroundColor: theme.palette.darkGreyLight, color: theme.palette.white, width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.white }} />}>
                {icon}
                <Typography sx={{ marginLeft: "5px" }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {Object.keys(groupedMessages).sort((a, b) => b - a).map((severity) => (
                    <Accordion
                        key={severity}
                        sx={{ backgroundColor: "#333333", color: theme.palette.white, width: "100%" }}
                        defaultExpanded={parseInt(severity) === 3}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.white }} />}>
                            {getSeverityIcon(parseInt(severity))}
                            <Typography sx={{ marginLeft: "5px" }}>
                                {getAlertSeverity(parseInt(severity)).charAt(0).toUpperCase() + getAlertSeverity(parseInt(severity)).slice(1)} ({groupedMessages[severity].length})
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {groupedMessages[severity].map((msg) => (
                                <Alert sx={{ marginBottom: "5px" }} key={msg[1]} severity={getAlertSeverity(msg[0])}>
                                    {msg[1]} {msg[2]}
                                </Alert>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
                {(status === 0) ? "All Good!" : ""}
                {elements}
            </AccordionDetails>
        </Accordion>
    );
};

export default DiagnosticAccordion;