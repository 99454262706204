import React, {useState, useEffect} from 'react';
import {
    Box,
    Divider,
    IconButton,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    TextField,
    useMediaQuery,
    useTheme,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button, Tooltip
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import '../../styles/dashboard.css';
import {checkIn, fetchJudges, fetchProjects, getCheckedInCount, getJudgePassword, changePassword} from "../../api/api";
import DefaultPageContainer from "../reusable/DefaultPageContainer";
import PersonIcon from '@mui/icons-material/Person';

const CheckInPage = (props) => {
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nameSearch, setNameSearch] = useState('');
    const [displayAll, setDisplayAll] = useState(false);
    const [checkedInCount, setCheckedInCount] = useState({
        students_in: 0,
        students_total: 0,
        judges_in: 0,
        judges_total: 0
    });
    const [checkInFilter, setCheckInFilter] = useState('both');
    const [typeFilter, setTypeFilter] = useState('both');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // get query params for default state of display all, display judge, student, only checked in, only checked out, both
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const displayAllParam = queryParams.get('displayAll');
        const checkInFilterParam = queryParams.get('checkInFilter');
        const typeFilterParam = queryParams.get('typeFilter');
        if (displayAllParam !== null) {
            setDisplayAll(displayAllParam === 'true');
        }
        if (checkInFilterParam !== null) {
            setCheckInFilter(checkInFilterParam);
        }
        if (typeFilterParam !== null) {
            setTypeFilter(typeFilterParam);
        }
    }, []);

    useEffect(() => {
        const loadStudents = async () => {
            const res = await fetchJudges(-1, 0);
            const res2 = await fetchProjects(-1, 0);
            const res3 = await getCheckedInCount();

            let people = [];

            if (res) {
                res.forEach((judge) => {
                    if (judge.admin_generated === 1)
                        return;
                    people.push(judge);
                });
            }
            if (res2) {
                res2.forEach((project) => {
                    people = people.concat(project.students);
                });
            }
            if (res3 != null) {
                setCheckedInCount(res3);
            }

            // Sort by Last name
            people.sort((a, b) => {
                if (a.last_name < b.last_name) {
                    return -1;
                }
                if (a.last_name > b.last_name) {
                    return 1;
                }
                return 0;
            });

            setPeople(people);
            setLoading(false);
        };
        loadStudents();
    }, []);

    const getCheckedInText = (res3) => {
        return res3.students_in + "/" + res3.students_total +
            ((res3.students_total === 1) ? " Student is" : " Students are") + " checked in and " +
            res3.judges_in + "/" + res3.judges_total + ((res3.judges_total === 1) ? " Judge is" : " Judges are") + " checked in."
    }

    const handleNameSearchChange = (event) => {
        setNameSearch(event.target.value);
    };

    const handleCheckInToggle = async (checkedIn, personID, isStudent) => {
        const res = await checkIn(checkedIn, personID);
        if (res === null)
            return

        if (res === true) {
            setPeople((prevPeople) =>
                prevPeople.map((student) =>
                    student.id === personID ? {...student, checked_in: (checkedIn) ? 1 : 0} : student
                )
            );
            let delta = 0;
            if (checkedIn === true) {
                delta = 1;
            } else {
                delta = -1;
            }

            if (isStudent) {
                // increment checkedInCount.students_in
                setCheckedInCount({
                    students_in: checkedInCount.students_in + delta,
                    students_total: checkedInCount.students_total,
                    judges_in: checkedInCount.judges_in,
                    judges_total: checkedInCount.judges_total
                });

            } else {
                setCheckedInCount({
                    students_in: checkedInCount.students_in,
                    students_total: checkedInCount.students_total,
                    judges_in: checkedInCount.judges_in + delta,
                    judges_total: checkedInCount.judges_total
                });
            }
        }
    };

    const handleDisplayAllChange = (event) => {
        const isChecked = event.target.checked;
        setDisplayAll(isChecked);

    };

    const filterPeople = () => {
        return people.filter((person) => {
            // apply first filter, Display All or Name Search with 3 min chars
            const matchesMainFilter = displayAll
                ? true
                : (nameSearch.length >= 3 && (
                    `${person.first_name} ${person.last_name}`.toLowerCase().includes(nameSearch.toLowerCase()) ||
                    person.email.toLowerCase().includes(nameSearch.toLowerCase()) ||
                    person.id.toString().includes(nameSearch.toLowerCase())
                ));

            if (!matchesMainFilter) {
                return false;
            }

            // Apply check-in status filter
            const matchesCheckInFilter = (checkInFilter === 'both') ||
                (checkInFilter === 'checked_in' && person.checked_in === 1) ||
                (checkInFilter === 'checked_out' && person.checked_in === 0);

            if (!matchesCheckInFilter) {
                return false;
            }

            // Apply type filter
            const matchesTypeFilter = (typeFilter === 'both') ||
                (typeFilter === 'judge' && person.type === 'judge') ||
                (typeFilter === 'student' && person.type === 'student');

            return matchesTypeFilter;
        });
    };

    const handlePasswordClick = async (personID) => {
        setPassword("Loading...");
        setPasswordDialogOpen(true);
        const res = await getJudgePassword(personID);
        if (res === null) {
            console.error("Error fetching judge password");
            return
        }
        if (res.password === "") {
            setPassword("No password set. An error occurred, please run password generation from the fair management page...");
            setUsername(res.username)
            return
        }
        setPassword(res.password);
        setUsername(res.username)
    };

    const handleCloseDialog = () => {
        setPasswordDialogOpen(false);
        setEditMode(false);
        setNewPassword('');
    };

    const handleEditPassword = () => {
        setEditMode(true);
    };

    const handleConfirmPasswordChange = async () => {
        const res = await changePassword(username, newPassword);
        if (res !== null) {
            setPassword(newPassword);
            setEditMode(false);
            setNewPassword('');
        } else {
            console.error("Error changing password");
        }
    };

    if (loading) {
        return (
            <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
                <div style={{marginBottom: "15px", paddingTop: "10px", paddingLeft: "10px"}}>
                    {isSmallScreen
                        ? <Typography variant="h5">Check In</Typography>
                        : <Typography variant="h3">Check In</Typography>
                    }

                    <Typography variant="body1" sx={{fontSize: "15px"}}>
                        Check In people for the science fair.
                    </Typography>
                </div>
                <CircularProgress/>;
            </DefaultPageContainer>)
    }


    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <div style={{marginBottom: "15px", paddingTop: "10px", paddingLeft: "10px"}}>
                {isSmallScreen
                    ? <Typography variant="h5">Check In</Typography>
                    : <Typography variant="h3">Check In</Typography>
                }

                <Typography variant="body1" sx={{fontSize: "15px"}}>
                    Check In people for the science fair.
                </Typography>
            </div>
            <Typography variant="body1" sx={{padding: 1}}>
                {getCheckedInText(checkedInCount)}
            </Typography>
            <Box style={{alignItems: "center", alignContent: "center", textAlign: "center"}}>
                {isSmallScreen
                    ? <Typography variant="h6">Search Person:</Typography>
                    : <Typography variant="h5">Search Person:</Typography>
                }

                <Box sx={{display: 'flex', gap: 2, marginBottom: 2}}>
                    <TextField
                        placeholder="Start typing... (name, email, ID)"
                        variant="outlined"
                        value={nameSearch}
                        onChange={handleNameSearchChange}
                        fullWidth
                        disabled={displayAll}
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={displayAll}
                                onChange={handleDisplayAllChange}
                                color="primary"
                            />
                        }
                        label="Display All"
                    />
                </Box>

                <Box>
                    <FormControl component="fieldset" sx={{marginTop: 2}}>
                        <FormLabel component="legend">Filter by Check-In Status</FormLabel>
                        <RadioGroup
                            row
                            aria-label="check-in status"
                            name="check-in-status"
                            value={checkInFilter}
                            onChange={(e) => setCheckInFilter(e.target.value)}
                        >
                            <FormControlLabel value="both" control={<Radio/>} label="Show Both"/>
                            <FormControlLabel value="checked_in" control={<Radio/>} label="Show Checked In"/>
                            <FormControlLabel value="checked_out" control={<Radio/>} label="Show Not Checked In"/>
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box>
                    <FormControl component="fieldset" sx={{marginTop: 2}}>
                        <FormLabel component="legend">Filter by Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="type"
                            name="type"
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                        >
                            <FormControlLabel value="both" control={<Radio/>} label="Show Both"/>
                            <FormControlLabel value="judge" control={<Radio/>} label="Show Judges"/>
                            <FormControlLabel value="student" control={<Radio/>} label="Show Students"/>
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Divider sx={{marginTop: 2, marginBottom: 2, backgroundColor: 'black'}}/>

                <Box sx={{marginTop: 2, overflowY: "auto", maxHeight: (isSmallScreen) ? "400px" : "100%"}}>
                    {(displayAll || nameSearch.length >= 3) && filterPeople().map((person) => (
                        <Card
                            key={person.id}
                            sx={{
                                marginBottom: 2,
                                color: "black",
                                backgroundColor: (person.checked_in === 1) ? "#a7eeb0" : "#e8a6a6"
                            }}
                            onDoubleClick={() => handleCheckInToggle(person.checked_in !== 1, person.id, person.type === "student")}
                        >
                            <CardContent>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(person.checked_in === 1)}
                                                onChange={(event) => handleCheckInToggle(event.target.checked, person.id, person.type === "student")}
                                                color="primary"
                                            />
                                        }
                                        label={`Checked In: ${person.checked_in === 1 ? "Yes" : "No"}`}
                                    />
                                    {(person.type === "student") ? "" :
                                        <IconButton
                                            size="small"
                                            onClick={() => handlePasswordClick(person.id)}
                                        >
                                            <PasswordIcon/>
                                        </IconButton>
                                    }
                                </Box>
                                <Typography variant="h6">
                                    {person.first_name} {person.last_name}
                                </Typography>
                                <Typography variant="body2">{person.email}</Typography>
                                {(person.type === "student") ?
                                    <Typography variant="body1">
                                        Project ID: {person.project_id}
                                    </Typography>
                                    :
                                    <Typography variant="body1">
                                        (Judge)
                                    </Typography>
                                }
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
            <Dialog open={passwordDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Judge Login Details</DialogTitle>
                <DialogContent>
                    <Tooltip title=" Their Username">

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            gap: 2
                        }}>
                            <PersonIcon/>
                            <Typography variant="h5" sx={{fontWeight: 'bold'}}>{username}</Typography>

                        </Box>
                    </Tooltip>

                    <Tooltip title="Their Password">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            gap: 2,
                            marginTop: "5px"
                        }}>
                            <PasswordIcon/>
                            {editMode ? (
                                <TextField
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                />
                            ) : (
                                <Typography variant="body1" sx={{fontFamily: 'monospace', fontWeight: 'bold'}}>
                                    {password}
                                </Typography>
                            )}
                        </Box>
                    </Tooltip>

                </DialogContent>
                <DialogActions>
                    {editMode ? (
                        <Button onClick={handleConfirmPasswordChange} color="primary">
                            Confirm
                        </Button>
                    ) : (
                        <Button onClick={handleEditPassword} color="primary">
                            Change Password
                        </Button>
                    )}
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </DefaultPageContainer>
    );
};

export default CheckInPage;