import React, {useEffect, useRef, useState} from 'react';
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    CardHeader,
    Card,
    CardContent,
    InputLabel,
    Select, MenuItem, FormControl
} from '@mui/material';
import {login} from '../../api/auth';
import {getUserData, getPersonData, loadUserDataComplete, get_regions} from "../../api/api";
import useAuth from '../../hooks/useAuth';
import {useNavigate, useLocation} from "react-router-dom";
import {useError} from "../../context/ErrorContext";
import {useTheme} from "@mui/styles";

const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const {setIsAuthenticated, isAuthenticated} = useAuth();
    const {showMessage} = useError();
    const hasShownMessage = useRef(false);

    const [region, setRegion] = useState('');
    const [regions, setRegions] = useState([]);

    const theme = useTheme();

    useEffect(() => {
        const loadRegions = async () => {
            const regionsList = await get_regions();
            if (regionsList === null) {
                console.error('Error loading regions');
                showMessage('Error loading regions', 'error');
                setRegions([]);
                return
            }
            regionsList.reverse();
            if (regionsList.length > 0) {
                setRegion(regionsList[0]);
            }
            setRegions(regionsList);
        }

        loadRegions();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/auth/dashboard');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (!hasShownMessage.current && location.state?.message && location.state?.severity) {
            showMessage(location.state.message, location.state.severity);
            hasShownMessage.current = true;
        }
    }, [location.state, showMessage]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await login({username, password, region});

            const {access_token, expires_at} = response.data;
            localStorage.setItem('token', access_token);
            localStorage.setItem('tokenExpiry', expires_at);

            setIsAuthenticated(true);
            await loadUserDataComplete(props.setUserData)

            navigate('/auth/dashboard', {state: {message: 'Login successful!', severity: 'success'}});
        } catch (error) {
            console.error('Login failed:', error);
            showMessage('Login failed', 'error');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="region-label">Region</InputLabel>
                        <Select
                            labelId="region-label"
                            id="region"
                            value={region}
                            label="Region"
                            onChange={(e) => setRegion(e.target.value)}
                        >
                            {regions.map((region) => (
                                <MenuItem key={region} value={region}>
                                    {region}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                </Box>
                <Card sx={{backgroundColor: theme.palette.pastelRed}}>
                    <CardContent>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "5px"}}>
                            <Typography variant="h5">Forgot Your Password?</Typography>
                        </Box>
                        <ul>
                            <li><Typography variant="body2">If you are an admin, speak to event coordinators to reset
                                your password.</Typography></li>
                            <li><Typography variant="body2">If you are a judge, speak with any admin to retrieve your
                                password.</Typography></li>
                        </ul>
                    </CardContent>
                </Card>
                <Box>
                    {/*<Typography>Not signed up?</Typography>*/}
                    {/*<Button*/}
                    {/*  onClick={() => navigate('/signup')}*/}
                    {/*  fullWidth*/}
                    {/*  variant="contained"*/}
                    {/*  sx={{ mt: 3, mb: 2 }}*/}
                    {/*>*/}
                    {/*  Sign Up*/}
                    {/*</Button>*/}
                </Box>
            </Box>
        </Container>
    );
};

export default LoginForm;
