import LoginForm from "./components/login/LoginForm";
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Dashboard from './components/dashboards/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import useAuth from "./hooks/useAuth";
import Home from "./components/home";
import React, {useState} from "react";
import AdminRoute from "./components/AdminRoute";
import RegistrationManagement from "./components/admin/RegistrationManagement";
import Rubric from "./components/rubric_judge_view/rubric";
import TemplateManagementView from "./components/admin/template-management/TemplateManagementView";
import TemplateEditorView from "./components/admin/template-management/TemplateEditorView";
import BulkAddPage from "./components/admin/BulkAddPage";
import CheckInPage from "./components/admin/CheckInPage";
import RubricListPage from "./components/rubric_list/rubric_list";
import FairManagement from "./components/fair_management/fair_management";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const App = () => {
    const {isAuthenticated} = useAuth();
    const [userData, setUserData] = useState(null);
    const [darkMode, setDarkMode] = useState(false);

    const handleToggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            black: darkMode ? '#ffffff' : '#000000',
            white: darkMode ? '#000000' : '#ffffff',
            darkGrey: darkMode ? '#c9c9c9' : '#383838',
            darkGreyLight: darkMode ? '#afafaf' : '#5b5b5b',

            lightGrey: darkMode ? '#383838' : '#c9c9c9',
            lightGreyLight: darkMode ? '#252525' : '#e7e7e7',
            lightGreyDark: darkMode ? '#3f3f3f' : '#cbcbcb',
            sideBar: darkMode ? '#000000' : '#e3e3e3',
            pastelGreen: darkMode ? '#263b26' : '#8fef8f',
            pastelRed: darkMode ? '#493939' : '#ffd0d0',
            dashboardButton: darkMode ? '#383838' : '#e0e0e0',
            good: darkMode ? '#2cc42c' : '#20a220',
            goodLight: darkMode ? '#24a624' : '#2de02d',
            goodDark: darkMode ? '#31ee31' : '#1e8a1e',
            standardBlue: darkMode ? '#13579b' : '#1976D2',
            standardBlueSelect: darkMode ? '#1c7bd9' : '#1e85ec',

            bad: darkMode ? '#a22020' : '#f53c3c',
            darkMode: darkMode,
            toggleDarkMode: handleToggleDarkMode,
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {textTransform: 'none'},
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <Routes>
                    {/* Public routes */}
                    <Route path="/login" element={<LoginForm userData={userData} setUserData={setUserData}/>}/>
                    {/*<Route path="/signup" element={<SignUpForm />} />*/}
                    <Route path="/" element={<Home/>}/>

                    {/* Admin Route */}
                    <Route path="/auth/admin/*" element={<AdminRoute userData={userData} setUserData={setUserData}/>}>

                        <Route path="dashboard" element={<Dashboard userData={userData} setUserData={setUserData}/>}/>

                        <Route path="management/bulk-add"
                               element={<BulkAddPage userData={userData} setUserData={setUserData}/>}/>
                        <Route path="management/check-in"
                               element={<CheckInPage userData={userData} setUserData={setUserData}/>}/>
                        <Route path="management/fair"
                               element={<FairManagement userData={userData} setUserData={setUserData}/>}/>

                        <Route path="management"
                               element={<RegistrationManagement userData={userData} setUserData={setUserData}/>}/>

                        <Route path="rubrics/*"
                               element={<RubricListPage userData={userData} setUserData={setUserData}/>}/>
                        <Route path="template-management"
                               element={<TemplateManagementView userData={userData} setUserData={setUserData}/>}/>
                        <Route path="template-management/edit/*"
                               element={<TemplateEditorView userData={userData} setUserData={setUserData}/>}/>
                        {/*    Add catch all route*/}
                        <Route path="*" element={<Navigate to="/auth/admin/dashboard"/>}/>
                    </Route>

                    {/* Protected routes for auth/* */}
                    <Route path="/auth/" element={<ProtectedRoute/>}>
                        <Route path="dashboard" element={<Dashboard userData={userData} setUserData={setUserData}/>}/>
                        <Route path="rubric/*" element={<Rubric userData={userData} setUserData={setUserData}/>}/>

                    </Route>

                    {/* Fallback route */}
                    <Route path="*" element={<Navigate to={isAuthenticated ? "/auth/dashboard" : "/login"}/>}/>
                </Routes>
            </Router>

        </ThemeProvider>
    );
};
export default App;