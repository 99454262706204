import React from 'react';
import { Box, Typography} from '@mui/material';
import JudgeSchedule from "./JudgeSchedule";

import '../../styles/dashboard.css';
import DefaultPageContainer from "../reusable/DefaultPageContainer";

const JudgeDashboard = (props) => {

    if (props.userData === null || props.userData === undefined || props.userData.rubricData === null || props.userData.rubricData === undefined) {
        return (
            <div>error loading user data!</div>
        );
    }

    let checkedIn = props?.userData?.personData?.checked_in === 1 || false;

    let rubrics = [];
    rubrics = props.userData.rubricData;

    if (rubrics?.rubrics !== null && rubrics?.rubrics !== undefined) {
        rubrics.rubrics = rubrics.rubrics.sort((a, b) => {
            return a.order - b.order;
        });
    }

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {!checkedIn && (
                    <div style={{ backgroundColor: 'black', color: 'yellow', padding: '10px', textAlign: 'center', fontSize: '20px' }}>
                        YOU ARE NOT CHECKED IN
                    </div>
                )}
                <Typography>Your Schedule:</Typography>
                <div style={{minWidth:"500px", maxWidth:"1000px"}}>
                    <JudgeSchedule rubrics={rubrics.rubrics} />
                </div>
            </Box>
        </DefaultPageContainer>
    );
};

export default JudgeDashboard;