import React, {useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import {exportData} from '../../api/api';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {useTheme} from "@mui/styles";

export const ExportDataCard = () => {
    const theme = useTheme();
    const [selectAll, setSelectAll] = useState(false);
    const [options, setOptions] = useState({
        person: false,
        student: false,
        judge: false,
        project: false,
        user: false,
        rubric: false,
        rubricCategory: false,
        rubricTemplate: false,
        rubricTemplateCategory: false,
    });

    const [fileType, setFileType] = useState('csv');
    const [unifiedFile, setUnifiedFile] = useState(false);
    const [zipAll, setZipAll] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    /**
     * Toggles "Select All" checkboxes
     */
    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        setOptions({
            person: checked,
            student: checked,
            judge: checked,
            project: checked,
            user: checked,
            rubric: checked,
            rubricCategory: checked,
            rubricTemplate: checked,
            rubricTemplateCategory: checked,
        });
    };

    /**
     * Toggles individual checkboxes
     */
    const handleOptionChange = (event) => {
        setOptions((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked,
        }));
    };

    /**
     * Changes file type between CSV and XLSX
     */
    const handleFileTypeChange = (event) => {
        const newType = event.target.value;
        setFileType(newType);

        // If user switches to CSV, "unifiedFile" must be disabled
        if (newType === 'csv') {
            setUnifiedFile(false);
        }
    };

    const anyChecked = Object.values(options).some((val) => val);

    /**
     * Main export function
     */
    const handleExport = async () => {
        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        // Gather all the keys that are "true"
        const checkedOptions = Object.entries(options)
            .filter(([_, isChecked]) => isChecked)
            .map(([key]) => key.toLowerCase());

        // If unifiedFile is true, pass 'true' otherwise 'false'
        const unified = unifiedFile ? 'true' : 'false';

        // Decide whether to zip the files
        const shouldZip = zipAll;
/*
        console.log('Exporting with the following settings:');
        console.log('Checked Options:', checkedOptions);
        console.log('Unified File:', unified);
        console.log('Download as ZIP:', shouldZip);*/

        try {
            // Make the API call
            const res = await exportData(checkedOptions, unified, fileType);

            // console.log('Export Data Response:', res);

            if (!res) {
                throw new Error('No data received from exportData API');
            }

            if (typeof res !== 'object') {
                throw new Error('Export data response is not an object');
            }

            if (shouldZip) {
                // Initialize JSZip
                const zip = new JSZip();

                // If your backend doesn't return keys with the proper extension,
                // we can force them here:
                for (const [key, value] of Object.entries(res)) {
                    // Force a filename with an extension
                    let fileName = key;
                    if (!fileName.endsWith(`.${fileType}`)) {
                        fileName = `${key}.${fileType}`;
                    }

                    if (fileType === 'csv') {
                        // CSV is plain text
                        if (typeof value !== 'string') {
                            console.error(`Data for ${key} is not a string. Skipping.`);
                            continue;
                        }
                        zip.file(fileName, value);
                    } else {
                        // XLSX is Base64-encoded
                        if (typeof value !== 'string') {
                            console.error(`Data for ${key} is not a Base64 string. Skipping.`);
                            continue;
                        }
                        try {
                            const binary = atob(value);
                            const array = [];
                            for (let i = 0; i < binary.length; i++) {
                                array.push(binary.charCodeAt(i));
                            }
                            const uint8Array = new Uint8Array(array);
                            zip.file(fileName, uint8Array);
                        } catch (error) {
                            console.error(`Error decoding Base64 for ${key}:`, error);
                            continue;
                        }
                    }
                }

                // Generate ZIP and trigger download
                try {
                    const zipBlob = await zip.generateAsync({type: 'blob'});
                    saveAs(zipBlob, 'exported_data.zip');
                    setSuccessMessage('ZIP file downloaded successfully.');
                } catch (error) {
                    console.error('Error generating ZIP:', error);
                    setErrorMessage(`Error generating ZIP: ${error.message}`);
                }
            } else {
                // Download files individually
                for (const [key, value] of Object.entries(res)) {
                    // Force a filename with an extension
                    let fileName = key;
                    if (!fileName.endsWith(`.${fileType}`)) {
                        fileName = `${key}.${fileType}`;
                    }

                    if (fileType === 'csv') {
                        // CSV is plain text
                        if (typeof value !== 'string') {
                            console.error(`Data for ${key} is not a string. Skipping.`);
                            continue;
                        }
                        const blob = new Blob([value], {type: 'text/csv;charset=utf-8;'});
                        saveAs(blob, fileName);
                    } else {
                        // XLSX is Base64-encoded
                        if (typeof value !== 'string') {
                            console.error(`Data for ${key} is not a Base64 string. Skipping.`);
                            continue;
                        }
                        try {
                            const binary = atob(value);
                            const array = [];
                            for (let i = 0; i < binary.length; i++) {
                                array.push(binary.charCodeAt(i));
                            }
                            const uint8Array = new Uint8Array(array);
                            const blob = new Blob([uint8Array], {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            });
                            saveAs(blob, fileName);
                        } catch (error) {
                            console.error(`Error decoding Base64 for ${key}:`, error);
                            continue;
                        }
                    }
                }

                setSuccessMessage('Files downloaded successfully.');
            }
        } catch (error) {
            console.error('Error exporting data:', error);
            setErrorMessage(`Error exporting data: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight}}>
            {/* Title Section */}
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IosShareIcon
                    sx={{color: '#1d78a9', marginTop: '10px', marginLeft: '10px'}}
                />
                <Typography sx={{marginTop: '10px', marginLeft: '10px'}} variant="h5">
                    Export Database
                </Typography>
            </Box>

            <CardContent>
                <Typography variant="body2">
                    Download the database as .csv or .xlsx
                </Typography>

                {/* Checkboxes for Entities */}
                <Box sx={{marginTop: '10px'}}>
                    <FormControlLabel
                        control={<Checkbox checked={selectAll} onChange={handleSelectAllChange}/>}
                        label="All"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.person}
                                onChange={handleOptionChange}
                                name="person"
                                disabled={selectAll}
                            />
                        }
                        label="Person"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.student}
                                onChange={handleOptionChange}
                                name="student"
                                disabled={selectAll}
                            />
                        }
                        label="Student"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.judge}
                                onChange={handleOptionChange}
                                name="judge"
                                disabled={selectAll}
                            />
                        }
                        label="Judge"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.project}
                                onChange={handleOptionChange}
                                name="project"
                                disabled={selectAll}
                            />
                        }
                        label="Project"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.user}
                                onChange={handleOptionChange}
                                name="user"
                                disabled={selectAll}
                            />
                        }
                        label="User"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.rubric}
                                onChange={handleOptionChange}
                                name="rubric"
                                disabled={selectAll}
                            />
                        }
                        label="Rubric"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.rubricCategory}
                                onChange={handleOptionChange}
                                name="rubricCategory"
                                disabled={selectAll}
                            />
                        }
                        label="RubricCategory"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.rubricTemplate}
                                onChange={handleOptionChange}
                                name="rubricTemplate"
                                disabled={selectAll}
                            />
                        }
                        label="RubricTemplate"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={options.rubricTemplateCategory}
                                onChange={handleOptionChange}
                                name="rubricTemplateCategory"
                                disabled={selectAll}
                            />
                        }
                        label="RubricTemplateCategory"
                    />
                </Box>

                {/* File Type Selection */}
                <Box sx={{marginTop: '10px'}}>
                    <Typography variant="body2">File Type</Typography>
                    <RadioGroup value={fileType} onChange={handleFileTypeChange}>
                        <FormControlLabel value="csv" control={<Radio/>} label=".csv"/>
                        <FormControlLabel value="xlsx" control={<Radio/>} label=".xlsx"/>
                    </RadioGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={unifiedFile}
                                onChange={(e) => setUnifiedFile(e.target.checked)}
                                disabled={fileType === 'csv'}
                            />
                        }
                        label="Export as one file with multiple sheets (XLSX only)"
                    />
                </Box>

                {/* Additional Options: Zip */}
                <Box sx={{marginTop: '10px'}}>
                    <Typography variant="h6">Options</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={zipAll} onChange={(e) => setZipAll(e.target.checked)}/>}
                        label="Download as ZIP"
                    />
                    <Typography variant="body2" color="text.secondary" sx={{marginLeft: '32px'}}>
                        (If selected, all chosen data will be compressed into a single ZIP file.)
                    </Typography>
                </Box>

                {/* Export Button */}
                <Box sx={{marginTop: '10px'}}>
                    <Button
                        variant="contained"
                        onClick={handleExport}
                        disabled={loading || !anyChecked}
                    >
                        {loading ? <CircularProgress size={24}/> : 'Export'}
                    </Button>
                </Box>
            </CardContent>

            {/* Error Snackbar */}
            <Snackbar
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                onClose={() => setErrorMessage('')}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            {/* Success Snackbar */}
            <Snackbar
                open={Boolean(successMessage)}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Card>
    );
};
