import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box} from '@mui/material';

import ActionBox from "../reusable/Actionbox";
import {getCheckedInCount, getRubricCompletedRatio} from "../../api/api";
import DefaultPageContainer from "../reusable/DefaultPageContainer";
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GradingIcon from '@mui/icons-material/Grading';
import SubjectIcon from '@mui/icons-material/Subject';

const AdminDashboard = (props) => {

    const [checkedInCount, setCheckedInCount] = useState(<>Loading...</>);
    const [rubricCompleteData, setRubricCompleteData] = useState(<>Loading...</>);
    const navigate = useNavigate();

    const goToManagement = () => navigate('/auth/admin/management');
    const gotoRubricTemplateManagement = () => navigate('/auth/admin/template-management');
    const goToCheckedIn = () => navigate('/auth/admin/management/check-in');
    const goToRubricListing = () => navigate('/auth/admin/rubrics');
    const goToFairManagement = () => navigate('/auth/admin/management/fair')

    useEffect(() => {
        const getCount = async () => {
            const res = await getCheckedInCount();
            if (res != null) {

                // Put school icon student count
                // then put AssignmentIndIcon judge count
                const out = (
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Box sx={{display: "flex", alignItems: "center", marginRight: "20px"}}>
                            <SchoolIcon sx={{marginRight: "5px"}}/>
                            <Box>{res.students_in}/{res.students_total}</Box>
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <AssignmentIndIcon sx={{marginRight: "5px"}}/>
                            <Box>{res.judges_in}/{res.judges_total}</Box>
                        </Box>
                    </Box>
                );

                setCheckedInCount(out);
            }
            const res2 = await getRubricCompletedRatio();
            if (res2 != null) {
                setRubricCompleteData(
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Box sx={{display: "flex", alignItems: "center", marginRight: "20px"}}>
                            <GradingIcon sx={{marginRight: "5px"}}/>
                            <Box>{res2.completed}</Box>
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <SubjectIcon sx={{marginRight: "5px"}}/>
                            <Box>{res2.incomplete}</Box>
                        </Box>
                    </Box>
                );
            }
        };
        getCount();
    }, []);

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "50px"}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ActionBox
                        title="Registration Management"
                        buttonTitle="Manage Registration"
                        description="Add, remove, modify Judges, Students, and Projects"
                        onClick={goToManagement}
                    />
                    <ActionBox
                        title={"Checked-In"}
                        buttonTitle="View Checked-In Participants"
                        description={checkedInCount}
                        onClick={goToCheckedIn}
                    />
                    <ActionBox
                        title="Rubrics"
                        buttonTitle="View Rubrics"
                        description={rubricCompleteData}
                        onClick={goToRubricListing}
                    />
                    <ActionBox
                        title="Rubric Templates"
                        buttonTitle="Manage Rubric Templates"
                        description="Modify your region's rubrics before they're created."
                        onClick={gotoRubricTemplateManagement}
                    />
                    <ActionBox
                        title="Fair Management"
                        buttonTitle="Enter Fair Management"
                        description="Manage global settings for the fair."
                        onClick={goToFairManagement}
                    />
                    {(props.isSuperAdmin) ?
                        <ActionBox
                            title="Admin Management"
                            buttonTitle="Enter Admin Management"
                            description="Manage fair admins."
                            onClick={goToRubricListing}
                        />
                        : ""}
                </Box>
            </Box>
        </DefaultPageContainer>
    );
};

export default AdminDashboard;