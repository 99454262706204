import React, { useState } from 'react';
import { Box, Divider, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import IconSwitch from "../../reusable/IconSwitch";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlineCheckCircle } from "react-icons/md";
import {
    activateRubricTemplate,
    deactivateRubricTemplate,
    deleteRubricTemplate,
    setRubricDefault
} from "../../../api/api";
import {useTheme} from "@mui/styles";

const TemplateManagementButton = (props) => {
    const [activeChecked, setActiveChecked] = React.useState(props.data.is_active === 1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();

    const changeActive = async (checked) => {
        let res = null;

        if (checked === true) {
            res = await activateRubricTemplate(props.data.id);
        } else {
            res = await deactivateRubricTemplate(props.data.id);
        }

        if (res === null || res === undefined || res === false) {
            return false;
        }

        setActiveChecked(checked);
        props.data.is_active = checked ? 1 : 0;
        return true;
    };

    const promoteToDefault = async () => {
        const response = await setRubricDefault(props.data.id);
        if (response === null || response === undefined) {
            return;
        }
        if (response === false) {
            return;
        }
        props.removeAllDefaults();
        props.data.is_default = true;

        // Update the templates state in the parent component
        props.setTemplates(prevTemplates => prevTemplates.map(template =>
            template.id === props.data.id ? { ...template, is_default: 1 } : { ...template, is_default: 0 }
        ));
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const confirmDeleteTemplate = async () => {
        const response = await deleteRubricTemplate(props.data.id);

        if (response === null || response === undefined) {
            return;
        }

        if (response === false) {
            return;
        }

        props.deleteEntry(props.data.id);
        handleDialogClose();
    }

    const navigate = useNavigate();

    const goToTemplatePage = () => {
        navigate(`edit/${props.data.id}`);
    };

    const customStyles = {
        mainBackground: {
            backgroundColor: (props.data.is_default === 1) ? '#bc40c7' : (activeChecked) ? '#1976D2' : '#082b4d',
            color: '#FFFFFF',
            padding: '10px',
            margin: '10px',
            borderRadius: 1,
            width: '550px', // Fixed width
            minWidth: "550px",
            minHeight: '200px', // Fixed height
            position: 'relative',
            '@media (max-width: 900px)': {
                width: '400px',
                height: 'auto',
                minWidth: '400px',
                margin: '10px auto',
                padding: '10px',
            },
            '@media (max-width: 400px)': {
                width: '90%',
                height: 'auto',
                minWidth: '350px',
                margin: '10px auto',
                padding: '10px',
            }
        },
        statusLabel: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            fontStyle: 'italic',
            color: '#FFFFFF',
            padding: '5px'
        }
    };

    return (
        <div>
            <Box sx={customStyles.mainBackground}>
                <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">{props.data.name}</Typography>
                    {(props.data.is_default === 1) ? <></> :
                        <IconSwitch
                            iconBefore={<ImCancelCircle color="#4aa5ff" />}
                            iconAfter={<MdOutlineCheckCircle color="#4aa5ff" />}
                            thumb="#FFFFFF"
                            rail="#DCDCDC"
                            border="#FFFFFF"
                            defaultCheckedValue={props.data.is_active === 1}
                            onChangeHook={(checked) => changeActive(checked)}
                        />}
                </Box>
                <Typography style={{ padding: '10px' }} variant="body1">{props.data.description}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                    <Box>
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                color: '#1976D2',
                                backgroundColor: '#FFFFFF',
                                marginRight: 1,
                                '&:hover': {
                                    backgroundColor: '#cfcfcf',
                                    color: '#d9811e'
                                }
                            }}
                            onClick={goToTemplatePage}
                        >
                            <EditIcon />
                        </IconButton>
                        {(props.data.is_default === 1 ) ? <></> :
                            (props.data.is_active === 1)?
                                <IconButton onClick={promoteToDefault}
                                            sx={{
                                                color: '#1976D2',
                                                backgroundColor: '#FFFFFF',
                                                marginRight: 1,
                                                '&:hover': {
                                                    backgroundColor: '#cfcfcf',
                                                    color:'#ffda31'
                                                }
                                            }}
                                >
                                    <StarIcon />
                                </IconButton> :
                                <IconButton onClick={handleDialogOpen}
                                            sx={{
                                                color: '#1976D2',
                                                backgroundColor: '#FFFFFF',
                                                marginRight: 1,
                                                '&:hover': {
                                                    backgroundColor: '#cfcfcf',
                                                    color: '#d22929'
                                                }
                                            }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                        }
                    </Box>
                </Box>
                <Typography sx={customStyles.statusLabel}>
                    {activeChecked ? 'Active' : 'Inactive'}
                </Typography>
            </Box>
            {(props.data.is_default === 1)?
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Divider style={{ backgroundColor: "#6605B8", width: "100%" }} />
                </Box> :

                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}/>
            }

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this template?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteTemplate} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TemplateManagementButton;