import React from 'react'
import {Alert, Box, Button, Card, CardContent, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {useNavigate} from "react-router-dom";
import {getMetadata, setEndDateTime, setStartDateTime} from "../../api/api";
import {useTheme} from "@mui/styles";

dayjs.extend(utc);
dayjs.extend(timezone);
const EST = 'America/New_York';

export const FairActiveStatusCard = () => {
    const theme = useTheme();
    const [loadedData, setLoadedData] = React.useState(false);
    const navigate = useNavigate();
    const [data, setData] = React.useState({});
    const [selectedStartDate, setSelectedStartDate] = React.useState(dayjs());
    const [selectedEndDate, setSelectedEndDate] = React.useState(dayjs());
    const [isFairActive, setIsFairActive] = React.useState(null);
    const [workingStopFair, setWorkingStopFair] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(dayjs().tz(EST));

    const fairActive = () => {
        if (!data) return false;

        const start = dayjs.utc(data["fair.start_time"]);
        const end = dayjs.utc(data["fair.end_time"]);
        const now = dayjs().utc();

        return now.isAfter(start) && now.isBefore(end);
    };

    const loadData = async () => {
        const res = await getMetadata();
        if (!res) {
            console.error("Could not fetch metadata");
            navigate('/auth/admin/dashboard', {
                state: {
                    message: "Couldn't fetch fair metadata!",
                    severity: 'error'
                }
            });
        } else {
            setData(res);
            setSelectedStartDate(dayjs.utc(res["fair.start_time"]).tz(EST));
            setSelectedEndDate(dayjs.utc(res["fair.end_time"]).tz(EST));
            setLoadedData(true);

            // Immediate fairActive update
            const start = dayjs.utc(res["fair.start_time"]);
            const end = dayjs.utc(res["fair.end_time"]);
            const now = dayjs().utc();

            setIsFairActive(now.isAfter(start) && now.isBefore(end));
        }
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setIsFairActive(fairActive());
            setCurrentTime(dayjs().tz(EST));
        }, 500); // Run twice every second

        return () => clearInterval(timer);
    }, [data]);


    const handleTimeChange = (field, newValue) => {
        if (newValue && dayjs.isDayjs(newValue)) {
            const isoString = newValue.tz(EST).utc().toISOString();
            setData((prevData) => ({
                ...prevData,
                [field]: isoString,
            }));
            if (field === 'fair.start_time') {
                setSelectedStartDate(newValue);
                setStartDateTime(isoString);
            } else if (field === 'fair.end_time') {
                setSelectedEndDate(newValue);
                setEndDateTime(isoString);
            }
        }
    };

    const setEndTimeToNow = async () => {
        setWorkingStopFair(true);

        // Get the current time in EST and convert to UTC for storage
        const now = dayjs().tz(EST);
        const nowUtc = now.utc().toISOString();

        // Call the API to store the UTC time
        const res = await setEndDateTime(nowUtc);
        if (res === null) {
            console.error("Could not update end time");
            navigate('/auth/admin/management/fair', {
                state: {
                    message: "Couldn't update fair end time!",
                    severity: 'error'
                }
            });
        }

        handleTimeChange('end', now);
        setSelectedEndDate(now);
        setIsFairActive(false);
        setWorkingStopFair(false);
    };

    React.useEffect(() => {
        loadData();

        // const timer2 = setInterval(() => {
        //     loadData();
        // }, 10000); // Run every 10 seconds
        // return () => clearInterval(timer2);
    }, [loadedData]);

    const formatTimePretty = (time) => {
        return time.format("MMMM D, YYYY [at] hh:mm A");

    }

    if (!loadedData) {
        return (
            <Card sx={{backgroundColor: theme.palette.lightGreyLight}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{marginTop: "10px", marginLeft: "10px"}} variant="h5">Fair Status</Typography>
                </Box>
                <div style={{marginBottom: "300px"}}>
                    Loading...
                </div>
            </Card>
        )
    }

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {isFairActive ? <CheckCircleIcon sx={{color: "#3db451", marginTop: "10px", marginLeft: "10px"}}/> :
                    <ErrorIcon sx={{color: "#cc2a2a", marginTop: "10px", marginLeft: "10px"}}/>}

                <Typography sx={{marginTop: "10px", marginLeft: "10px"}} variant="h5">Fair Status</Typography>

            </Box>
            <CardContent>
                <Box>
                    <Typography fontWeight="bold"
                                sx={{color: isFairActive ? "#3db451" : "#cc2a2a"}}>
                        The fair is {isFairActive ? "" : "NOT"} ACTIVE
                    </Typography>
                </Box>

                <Typography>
                    {isFairActive ?
                        "Deactivating the fair will log out all judges and refuse any new scores."
                        :
                        "Activating the fair means judge logins will be active and scoring can occur."
                    }
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{marginTop: "15px"}}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {xs: "row", sm: "column"},
                                gap: "15px",
                                alignItems: "left",
                            }}
                        >
                            <Box sx={{flex: 1}}>
                                <Typography variant="h6">Start Time:</Typography>
                                <DateTimePicker
                                    value={selectedStartDate}
                                    onChange={(newValue) => handleTimeChange('fair.start_time', newValue)}
                                    renderInput={(params) => <Box component="input" {...params} />}
                                />
                            </Box>
                            <Box sx={{flex: 1}}>
                                <Typography variant="h6">End Time:</Typography>
                                <DateTimePicker
                                    value={selectedEndDate}
                                    onChange={(newValue) => handleTimeChange('fair.end_time', newValue)}
                                    renderInput={(params) => <Box component="input" {...params} />}
                                />
                            </Box>
                        </Box>
                        <Typography variant="caption" sx={{marginTop: "10px"}}>
                            All times are in Eastern Time (EST)
                        </Typography>
                    </Box>
                    <Box sx={{marginTop: "15px"}}>
                        <div>
                            <br/>
                            {isFairActive ? (
                                <Button
                                    onClick={setEndTimeToNow}
                                    variant="contained"
                                    disabled={workingStopFair}
                                    sx={{
                                        marginTop: "5px",
                                        width: "100%",
                                        backgroundColor: "#cc2a2a",
                                        "&:hover": {backgroundColor: "#701010"}
                                    }}
                                >
                                    Stop Fair Now
                                </Button>
                            ) : (
                                <div style={{width: "100%"}}>
                                    <Alert icon={<TipsAndUpdatesIcon fontSize="inherit"/>}
                                           severity="info">
                                        Tip: To start the fair, make sure your current time:&nbsp;
                                        <Typography variant="caption" fontWeight="bold">
                                            {formatTimePretty(currentTime)}
                                        </Typography>
                                        <br/>is
                                        within the bounds above.
                                    </Alert>
                                </div>
                            )}
                        </div>
                    </Box>
                </LocalizationProvider>
            </CardContent>
        </Card>
    )
}
