import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useError} from '../../context/ErrorContext';
import useAuth from "../../hooks/useAuth";
import LogoutIcon from '@mui/icons-material/Logout';
import {Box, Button, Typography} from "@mui/material";

const SignOutButton = (props) => {
    const navigate = useNavigate();
    const {showMessage} = useError();
    const {setIsAuthenticated} = useAuth();

    const handleSignOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
        props.setUserData(null)
        setIsAuthenticated(false)
        showMessage('You have been signed out.', 'info');
        navigate('/login', {state: {message: 'You are now logged out', severity: 'success'}});
    };

    if (props.version === "topbar") {
        return (
            <Box>
                <Button
                    onClick={handleSignOut}
                    sx={{
                        display: "flex", flexDirection: "column", alignItems: "center",
                        '&:hover': {
                            backgroundColor: 'rgb(166,37,37)',
                        },
                    }}
                >
                    <div onClick={handleSignOut} aria-label="sign out">
                        <LogoutIcon sx={{color: "#fc3c3c"}}/>
                    </div>
                    <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                        Logout
                    </Typography>
                </Button>
            </Box>
        );
    }

    return (

        <Button
            onClick={handleSignOut}
            sx={{
                textTransform: 'none',
                width: "100%",
                color: 'black',
                '&:hover': {
                    backgroundColor: 'rgb(162,43,43)',
                    color: 'white'
                },
                justifyContent: "flex-start"
            }}
        >
            <div onClick={handleSignOut} aria-label="sign out">
                <LogoutIcon sx={{color: "#c43838"}}/>
            </div>
            <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px"}}>
                Sign Out
            </Typography>
        </Button>
    );


};

export default SignOutButton;