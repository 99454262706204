import React, {useEffect, useState} from 'react';
import {Box, Divider, Drawer, IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightJudgeSidebar from "../sidebar/RightJudgeSidebar";
import {getName} from "./dataFormatters";
import RightAdminSidebar from "../sidebar/RightAdminSidebar";
import {TopBar} from "./TopBar";
import {useTheme} from "@mui/styles";
import LightModeIcon from "@mui/icons-material/LightMode";
import Switch from "@mui/material/Switch";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const DefaultPageContainer = ({children, userData, setUserData}) => {
        const [drawerOpen, setDrawerOpen] = useState(false);

        const [windowHeight, setWindowHeight] = useState(window.innerHeight);

        useEffect(() => {
            const handleResize = () => {
                setWindowHeight(window.innerHeight);
            };

            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        const theme = useTheme();
        const sidebarColor = theme.palette.sideBar;

        const toggleDrawer = (open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setDrawerOpen(open);
        };

        let admin = (userData?.is_admin > 0 || false);
        let real_admin = (userData?.real_admin_level > 0 || false);

        let name = getName({userData: userData});
        const [width, setWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);

            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const smallMode = width <= 750;

        return (
            <>
                <TopBar name={name} userData={userData} setUserData={setUserData}/>
                <div className="container">
                    <IconButton
                        className="dashboard-drawer"
                        edge="start"
                        color="white"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{position: 'fixed', top: 10, left: 10, zIndex: 1100}}
                    >
                        <div className="dashboard-drawer">
                            <MenuIcon sx={{color: "#FFFFFF"}}/>
                        </div>
                    </IconButton>
                    <Drawer
                        className="dashboard-drawer"
                        anchor="left"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        PaperProps={{
                            sx: {
                                backgroundColor: sidebarColor,
                                opacity: 1,
                                maxHeight: windowHeight,
                                height: windowHeight,
                            },
                        }}

                    >
                        <Box
                            sx={{
                                paddingLeft: 2,
                                marginRight: 2,
                                maxWidth: "200px",

                            }}
                        >
                            <LeftSidebar name={name} userData={userData} setUserData={setUserData}/>
                            {admin === true ? (
                                <>
                                    <Divider sx={{borderWidth: "1px", backgroundColor: "black"}}/>
                                    <RightAdminSidebar userData={userData} setUserData={setUserData}/>
                                </>
                            ) : (
                                <>
                                    {real_admin === true && (
                                        <Divider sx={{borderWidth: "1px", backgroundColor: "black"}}/>
                                    )}
                                    <RightJudgeSidebar userData={userData} setUserData={setUserData}/>
                                </>
                            )}
                            <Box
                                sx={{
                                    zIndex: 1000,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: "center",
                                    marginLeft: "35px",
                                    marginBottom: "0px"
                                }}
                            >
                                <LightModeIcon/>
                                <Switch checked={theme.palette.darkMode} onChange={() => {
                                    theme.palette.toggleDarkMode()
                                }}/>
                                <DarkModeIcon/>

                            </Box>
                        </Box>
                    </Drawer>


                    <div className="appbarcontent">
                        <Box
                            sx={{
                                width: '200px',
                                backgroundColor: sidebarColor,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 2,
                                position: 'fixed',
                                left: 0,
                                top: 60,
                                overflow: 'hidden',
                                overflowY: 'auto',
                                zIndex: 1
                            }}
                        >
                            {admin ? (
                                <RightAdminSidebar userData={userData} setUserData={setUserData}/>
                            ) : (
                                <RightJudgeSidebar userData={userData} setUserData={setUserData}/>
                            )}
                            <Box sx={{flexGrow: 1}}/>
                            <Box
                                sx={{
                                    zIndex: 1000,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                    p: 1,
                                    marginLeft: "5px",
                                    marginBottom: "50px"
                                }}
                            >
                                <LightModeIcon/>
                                <Switch checked={theme.palette.darkMode} onChange={() => {
                                    theme.palette.toggleDarkMode()
                                }}/>
                                <DarkModeIcon/>

                            </Box>
                        </Box>
                    </div>
                    <Box sx={{flex: 1, padding: 2, overflow: 'auto'}}>
                        <div style={{marginLeft: (smallMode) ? "0" : "220px"}}>
                            {children}
                        </div>
                    </Box>
                </div>
            </>
        )
            ;
    }
;

export default DefaultPageContainer;