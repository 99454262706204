import React from 'react';
import {massSetCategories} from "../../api/api";
import RubricCategory from "./RubricCategory";

const RubricView = (props) => {
    if (!props.rubric) {
        return (
            <div>
                Rubric is not defined - this could mean that you do not have the
                permissions to edit it!
            </div>
        );
    }

    const extractFormattedCategoryDataForAPI = () => {
        let out = [];

        let valid = true;
        for (let key in props.rubric.categories) {
            // iterate through each entry in the category
            let category = props.rubric.categories[key];
            for (let entry in category.children) {
                let score = category.children[entry].score;
                if (score === 0){
                    valid = false;
                }
                out.push({"id": category.children[entry].id, "score": score});
            }
        }
        return [out, valid]
    }


    const saveCategoryOnly = async() => {
        if (props.showMode === true)
            return

        let [out, valid] = extractFormattedCategoryDataForAPI();
        if (props.onChangeHook !== undefined && props.onChangeHook !== null) {
            props.onChangeHook();
        }
        await massSetCategories(props.rubric.id, out);
    }


    const extractRubricData = (dictData, showMode = false) => {
        const sortedEntries = Object.entries(dictData).sort(([keyA, valueA], [keyB, valueB]) => {
            if (valueA.name === "Rubric") return 1;
            if (valueB.name === "Rubric") return -1;
            return 0;
        });

        return sortedEntries.map(([key, value]) => {
            return (
                <RubricCategory
                    onChange={saveCategoryOnly}
                    key={key}
                    data={value}
                    showMode={showMode}
                />
            );
        });
    }



    return extractRubricData(props.rubric.categories, props.showMode);
};

export default RubricView;