import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Checkbox,
    FormControlLabel,
    TextField,
    Slider, Tooltip, CircularProgress
} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {useTheme} from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import {generateMockData} from "../../api/api";

export const MockDataCard = () => {
    const theme = useTheme();

    // State variables for checkboxes
    const [generateJudgeInterestData, setGenerateJudgeInterestData] = useState(false);
    const [generateTags, setGenerateTags] = useState(false);

    const [generateFakePeople, setGenerateFakePeople] = useState(false);
    const [purgeBeforeAdding, setPurgeBeforeAdding] = useState(false);

    // State variables for input fields
    const [judgesToCreate, setJudgesToCreate] = useState(0);
    const [studentsToCreate, setStudentsToCreate] = useState(0);
    const [averageStudentsPerProject, setAverageStudentsPerProject] = useState(1.0);

    const [loading, setLoading] = useState(false);

    // Handlers for checkbox changes
    const handleGenerateJudgeInterestDataChange = (event) => {
        setGenerateJudgeInterestData(event.target.checked);
    };
    const handleGenerateTagsChange = (event) => {
        setGenerateTags(event.target.checked);
    };


    const handleGenerateFakePeopleChange = (event) => {
        setGenerateFakePeople(event.target.checked);
    };

    const handlePurgeBeforeAddingChange = (event) => {
        setPurgeBeforeAdding(event.target.checked);
    };
    // Handlers for input field changes
    const handleJudgesToCreateChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setJudgesToCreate(isNaN(value) ? 0 : value);
    };

    const handleStudentsToCreateChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setStudentsToCreate(isNaN(value) ? 0 : value);
    };

    // Handler for slider change
    const handleAverageStudentsPerProjectChange = (event, newValue) => {
        setAverageStudentsPerProject(newValue);
    };

    // Handler for the Create button
    const handleCreate = async () => {
        const mockDataConfig = {
            generate_interest_data: generateJudgeInterestData,
            generate_tags: generateTags,
            generate_fake_people: generateFakePeople,
            judges_to_create: generateFakePeople ? judgesToCreate : null,
            students_to_create: generateFakePeople ? studentsToCreate : null,
            avg_students_per_project: generateFakePeople ? averageStudentsPerProject : null,
            purge_before_adding: purgeBeforeAdding
        };

        setLoading(true);

        const res = await generateMockData(mockDataConfig);
        if (res === null) {
            alert("Failed to generate mock data");
            setLoading(false);
            return;
        }
        setLoading(false);

    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight, padding: 2, position: 'relative'}}>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <AutoAwesomeIcon
                    sx={{
                        color: '#d99e20',

                    }}
                />
                <Typography sx={{marginLeft: '10px'}} variant="h5">
                    Generate Mock Data
                </Typography>
            </Box>


            {/* Content Section */}
            <CardContent>
                <Typography variant="body2">
                    Create mass fake data to test features.
                </Typography>
                <br/>

                {/* Checkboxes */}
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>

                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left'}}>

                        <Tooltip
                            title="Generating interest data will just generate random strengths and weaknesses for all existing judges. ONLY APPLIES TO EXISTING JUDGES"
                            arrow>
                            <InfoIcon
                                sx={{
                                    position: 'relative',
                                    right: 8,
                                    color: theme.palette.info.main,
                                    cursor: 'pointer',
                                }}
                            />

                        </Tooltip>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateJudgeInterestData}
                                    onChange={handleGenerateJudgeInterestDataChange}
                                    color="primary"
                                />
                            }
                            label="Generate Judge Interest Data"
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left'}}>

                        <Tooltip
                            title="Generating tags will use a data labelling AI to label project descriptions with 5 subjects as keywords. ONLY APPLIES TO EXISTING PROJECTS."
                            arrow>
                            <InfoIcon
                                sx={{
                                    position: 'relative',
                                    right: 8,
                                    color: theme.palette.info.main,
                                    cursor: 'pointer',
                                }}
                            />

                        </Tooltip>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateTags}
                                    onChange={handleGenerateTagsChange}
                                    color="primary"
                                />
                            }
                            label="Generate Tags With AI"
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left'}}>

                        <Tooltip
                            title="This will generate judges and students with random data. All created judges will have interests generated, and all studenst will have projects assigned to them with pre-generated tags"
                            arrow>
                            <InfoIcon
                                sx={{
                                    position: 'relative',
                                    right: 8,
                                    color: theme.palette.info.main,
                                    cursor: 'pointer',
                                }}
                            />

                        </Tooltip>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateFakePeople}
                                    onChange={handleGenerateFakePeopleChange}
                                    color="primary"
                                />
                            }
                            label="Generate Fake People"
                        />
                    </Box>
                    {/* Conditional Input Fields */}
                    {generateFakePeople && (
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, paddingLeft: 3}}>
                            <TextField
                                label="Judges to create"
                                type="number"
                                value={judgesToCreate}
                                onChange={handleJudgesToCreateChange}
                                InputProps={{inputProps: {min: 0}}}
                                fullWidth
                            />
                            <TextField
                                label="Students to create"
                                type="number"
                                value={studentsToCreate}
                                onChange={handleStudentsToCreateChange}
                                InputProps={{inputProps: {min: 0}}}
                                fullWidth
                            />
                            <Typography variant="body1" sx={{minWidth: '200px'}}>
                                Average Students per project
                            </Typography>
                            <Slider
                                value={averageStudentsPerProject}
                                onChange={handleAverageStudentsPerProjectChange}
                                aria-labelledby="average-students-slider"
                                step={0.1}
                                marks
                                min={1}
                                max={2}
                                valueLabelDisplay="auto"
                                sx={{flexGrow: 1}}
                            />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'left'
                            }}>

                                <Tooltip
                                    title="This option makes the first two irrelevant. This will delete all judges, students, and projects before generating new ones."
                                    arrow>
                                    <InfoIcon
                                        sx={{
                                            position: 'relative',
                                            right: 8,
                                            color: theme.palette.info.main,
                                            cursor: 'pointer',
                                        }}
                                    />

                                </Tooltip>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={purgeBeforeAdding}
                                            onChange={handlePurgeBeforeAddingChange}
                                            color="primary"
                                        />
                                    }
                                    label="Delete Existing Judges, Students, Projects Before Generating"
                                />
                            </Box>

                        </Box>
                    )}
                </Box>
                <br/>

                {/* Create Button */}
                <Box sx={{width: "100%"}}>

                    {
                        (loading) ?

                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <CircularProgress size={24} sx={{color: theme.palette.good}}/>
                            </Box>

                            :
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: theme.palette.good,
                                    width: "100%",
                                    '&:hover': {
                                        backgroundColor: theme.palette.goodDark,
                                    },
                                }}
                                onClick={handleCreate}
                            >
                                Create
                            </Button>
                    }
                </Box>

                {/* Warning Note */}
                <Typography variant="caption">
                    Ensure you create a backup before generating mock data as it will populate various tables in the
                    database.
                </Typography>
            </CardContent>
        </Card>
    );
}
