import React, { useState } from 'react';
import { Box, IconButton, Typography, Collapse, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import TemplateEditorCategorySubheading from "./TemplateEditorCategorySubheadings";
import {
    createRubricTemplateCategoryFull,
    deleteRubricTemplateCategory,
    updateRubricTemplateCategory
} from "../../../api/api";

const TemplateEditorCategoryHeader = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [refresher, setRefresher] = useState(false);
    const [name, setName] = useState("");
    const [maxScore, setMaxScore] = useState(1);
    const [weight, setWeight] = useState(1);

    const [about, setAbout] = useState("");
    const [level1, setLevel1] = useState("");
    const [level2, setLevel2] = useState("");
    const [level3, setLevel3] = useState("");
    const [level4, setLevel4] = useState("");
    const [leveledError, setLeveledError] = useState("");
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(props.data.name);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const onEdit = () => {
        let max_score = 0;
        let weight = 0;
        Object.keys(props.data.children).forEach((key) => {
            max_score += parseFloat(props.data.children[key].max_score);
            weight += parseFloat(props.data.children[key].weight);
        });

        props.data.max_score = max_score;
        props.data.weight = weight;
        setRefresher(!refresher);
    };

    const deleteSelf = async (id) => {
        const response = await deleteRubricTemplateCategory(id, props.data.rubric_id);
        if (response === null || response === undefined) {
            console.error("Error deleting category");
            return;
        }

        delete props.data.children[id];
        onEdit();
    };

    const onDelete = () => {
        handleDeleteDialogOpen();
    };

    const handleConfirmDelete = () => {
        props.deleteSelf(props.data.id);
        handleDeleteDialogClose();
    };

    const handleCreate = async () => {
        if (name.length === 0 || maxScore.length === 0 || weight.length === 0) {
            setLeveledError("One or more fields are empty!");
            return;
        }

        if(name === "Rubric") {
            setLeveledError("'Rubric' is a reserved name.");
            return;
        }

        const response = await createTemplateCategory({ name: name, max_score: maxScore, weight: weight });
        if (response) {
            // Handle successful creation
            handleDialogClose();
        } else {
            console.error("Error creating category");
        }
    };

    const handleCreateRubric = async () => {
        if (about.length === 0 || level1.length === 0 || level2.length === 0 || level3.length === 0 || level4.length === 0) {
            // console.error("Error creating rubric_judge_view, one or more fields are empty");
            setLeveledError("One or more fields are empty!");
            return;
        }
        let concat = "ABOUT: " + about + "\nLEVEL 1: " + level1 + "\nLEVEL 2: " + level2 + "\nLEVEL 3: " + level3 + "\nLEVEL 4: " + level4;
        const response = await createTemplateCategory({ name: about, max_score: 4, weight: 4, description: concat });
        if (response) {
            // Handle successful creation
            handleDialogClose();
        } else {
            console.error("Error creating category");
        }
    };

    const createTemplateCategory = async (data) => {
        const response = await createRubricTemplateCategoryFull(parseInt(props.data.rubric_id), parseInt(props.data.id), data.name, (data.max_score), (data.weight), data.description);

        if (response === null || response === undefined) {
            console.error("Error creating category");
            return false;
        }

        props.data.children[response.id] = {
            id: response.id,
            rubric_id: props.data.rubric_id,
            name: data.name,
            description: data.description,
            max_score: data.max_score,
            weight: data.weight,
            children: {},
        };
        onEdit();
        setRefresher(!refresher);
        return true;
    };

    const handleEditTitle = async () => {
        if (isEditingTitle) {
            await updateHeaderTitle(headerTitle);
        }
        setIsEditingTitle(!isEditingTitle);
    };


    const updateHeaderTitle = async (name) => {
        if (name.length < 1 || name.length > 256) {
            setHeaderTitle(props.data.name);
            setRefresher(!refresher);
            return;
        }
        if(name === "Rubric")
        {
            setHeaderTitle(props.data.name);
            setRefresher(!refresher);
            return;
        }

        let changes = { category_name: name };
        const response = await updateRubricTemplateCategory(props.data.id, props.data.rubric_id, changes);
        if (response === null || response === undefined) {
            console.error("Error updating category name");
            return;
        }
        props.data.name = name;
        setRefresher(!refresher);
    }

    const styles = {
        card: {
            backgroundColor: (props.isRubric) ? '#acd5ff' : '#e7ccff',
            padding: '10px',
            margin: '10px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'relative',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        buttonRow: {
            display: 'flex',
            alignItems: 'center',
        },
        content: {
            marginTop: '10px',
        },
        editButton: {
            margin: "2px",
            marginLeft: "5px",
            backgroundColor: '#9E32A8FF',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#7a2480',
            },
        },
        editTitleButton: {
            margin: "2px",
            marginLeft: "5px",
            backgroundColor: '#9E32A8FF',
            width: "1em",
            height:"1em",
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#7a2480',
            },
        },
        editTitleIcon: {
            width: "0.7em",
            height:"0.7em",
        },
        expandButton: {
            margin: "2px",
            backgroundColor: '#9E32A8FF',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#7a2480',
            },
        },
        createButton: {
            marginTop: '10px',
            backgroundColor: '#489848',
            color: 'white',
            '&:hover': {
                backgroundColor: '#205d20',
            },
        },
    };

    return (
        <div>
            {(refresher) ? "" : ""}
            <Box sx={styles.card}>
                <Box sx={styles.header}>
                    <Box sx={styles.buttonRow}>
                        <IconButton sx={styles.expandButton}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <IconButton sx={styles.editButton}
                                    aria-label="add"
                                    onClick={handleDialogOpen}
                        >
                            <AddIcon />
                        </IconButton>
                        <IconButton sx={styles.editButton}
                                    aria-label="delete"
                                    onClick={onDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isEditingTitle ? (
                            <TextField
                                value={headerTitle}
                                onChange={(e) => setHeaderTitle(e.target.value)}
                                sx={{ backgroundColor: '#e7ccff', input: { color: 'black' } }}
                            />
                        ) : (
                            <Typography variant="h6" sx={{color:"#000000"}}>{headerTitle}</Typography>
                        )}
                        {(props.isRubric)? "" :
                            <IconButton sx={styles.editTitleButton} onClick={handleEditTitle}>
                                <EditIcon sx={styles.editTitleIcon} />
                            </IconButton>
                        }
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={styles.content}>
                        {Object.keys(props.data.children).length === 0 ?
                            <Typography variant="body1">So empty inside...</Typography>
                            :
                            Object.keys(props.data.children).map((key) => {
                                return <TemplateEditorCategorySubheading key={key} data={props.data.children[key]} deleteSelf={deleteSelf} onEdit={onEdit} isRubric={props.isRubric} />;
                            })
                        }
                    </Box>
                </Collapse>
            </Box>

            <Dialog open={dialogOpen} onClose={() => {
                setLeveledError("");
                handleDialogClose();
            }}>
                <DialogTitle>{props.isRubric ? "Add Rubric" : "Add Category"}</DialogTitle>
                <DialogContent>
                    {props.isRubric ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>About:</Typography>
                                <TextField
                                    type="text"
                                    value={about}
                                    onChange={(e) => setAbout(e.target.value)}
                                    sx={{ backgroundColor: '#ffffff', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Level 1:</Typography>
                                <TextField multiline
                                    type="text"
                                    value={level1}
                                    onChange={(e) => setLevel1(e.target.value)}
                                    sx={{ backgroundColor: '#D2D2D2FF', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Level 2:</Typography>
                                <TextField multiline
                                    type="text"
                                    value={level2}
                                    onChange={(e) => setLevel2(e.target.value)}
                                    sx={{ backgroundColor: '#D2D2D2FF', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Level 3:</Typography>
                                <TextField multiline
                                    type="text"
                                    value={level3}
                                    onChange={(e) => setLevel3(e.target.value)}
                                    sx={{ backgroundColor: '#D2D2D2FF', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Level 4:</Typography>
                                <TextField multiline
                                    type="text"
                                    value={level4}
                                    onChange={(e) => setLevel4(e.target.value)}
                                    sx={{ backgroundColor: '#d2d2d2', input: { color: 'black' } }}
                                />
                            </Box>
                            <Button sx={styles.createButton} onClick={handleCreateRubric}>
                                Create
                            </Button>
                            <p style={{ color: 'red' }}>{leveledError}</p>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Name:</Typography>
                                <TextField
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    inputProps={{ maxLength: 255 }}
                                    sx={{ backgroundColor: '#ffffff', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Max Score:</Typography>
                                <TextField
                                    type="number"
                                    value={maxScore}
                                    onChange={(e) => setMaxScore(Math.max(1, parseFloat(e.target.value)))}
                                    inputProps={{ min: 1 }}
                                    sx={{ backgroundColor: '#d2d2d2', input: { color: 'black' } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>Weight:</Typography>
                                <TextField
                                    type="number"
                                    value={weight}
                                    onChange={(e) => setWeight(Math.max(1, e.target.value))}
                                    inputProps={{ min: 1 }}
                                    sx={{ backgroundColor: '#d2d2d2', input: { color: 'black' } }}
                                />
                            </Box>
                            <Button sx={styles.createButton} onClick={handleCreate}>
                                Create
                            </Button>
                            <p style={{ color: 'red' }}>{leveledError}</p>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this category?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TemplateEditorCategoryHeader;