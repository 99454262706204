import React, {useState, useEffect, useReducer} from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {massSetCategories, updateRubric} from "../../api/api";
import RubricCategory from "./RubricCategory";
import RubricView from "./RubricView";

const RubricJudgeView = (props) => {
    const [publicFeedback, setPublicFeedback] = useState('');
    const [privateFeedback, setPrivateFeedback] = useState('');
    const [recommend, setRecommend] = useState(false);
    const [defaultRecommendValue, setDefaultRecommendValue] = useState('not_recommend');
    const [submittedRubric, setSubmittedRubric] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    useEffect(() => {
        if (props.rubric) {
            setPublicFeedback(props.rubric.public_feedback || '');
            setPrivateFeedback(props.rubric.private_feedback || '');
            setRecommend((props.rubric.recommended === 1) || false);
            setDefaultRecommendValue((props.rubric.recommended === 1) ? 'recommend' : 'not_recommend');
        }
    }, [props.rubric]);

    if (!props.rubric) {
        return (
            <div>
                Rubric is not defined - this could mean that you do not have the
                permissions to edit it!
            </div>
        );
    }

    const extractFormattedCategoryDataForAPI = () => {
        let out = [];

        let valid = true;
        for (let key in props.rubric.categories) {
            // iterate through each entry in the category
            let category = props.rubric.categories[key];
            for (let entry in category.children) {
                let score = category.children[entry].score;
                if (score === 0){
                    valid = false;
                }
                out.push({"id": category.children[entry].id, "score": score});
            }
        }
        return [out, valid]
    }

    const isValid = () => {
        let valid = true;
        for (let key in props.rubric.categories) {
            // iterate through each entry in the category
            let category = props.rubric.categories[key];
            for (let entry in category.children) {
                let score = category.children[entry].score;
                if (score === 0){
                    valid = false;
                }
            }
        }
        return valid;
    }

    const saveFeedback = async () => {
        if (submittedRubric) return;
        setSubmittedRubric(true);
        setLoading(true);

        // collect each category's score
        let [out, valid] = extractFormattedCategoryDataForAPI();

        await massSetCategories(props.rubric.id, out);

        await updateRubric(publicFeedback, privateFeedback, recommend, props.rubric.id, valid);

        props.rubric.public_feedback = publicFeedback;
        props.rubric.private_feedback = privateFeedback;
        props.rubric.recommended = recommend ? 1 : 0;
        props.rubric.completed = (valid) ? 1 : 0;

        setLoading(false);
        if(valid) {
            navigate('/auth/dashboard', { state: { message: 'Your submission was received!', severity: 'success' } });
        }
        else{
            navigate('/auth/dashboard', { state: { message: 'Your submission was received but is incomplete...', severity: 'warning' } });
        }
    }

    const saveCategoryOnly = async() => {
        let [out, valid] = extractFormattedCategoryDataForAPI();
        await massSetCategories(props.rubric.id, out);
    }

    const saveFeedbackOnly = () => {
        let [out, valid] = extractFormattedCategoryDataForAPI();
        updateRubric(publicFeedback, privateFeedback, recommend, props.rubric.id, valid, false).then(()=> {
                    forceUpdate();
        });
    }

    const quit = () => {
        navigate('/auth/dashboard', { state: { message: 'You did not submit anything', severity: 'warning' } });
    }


    const getStepOne = () => {
        return (<RubricView rubric={props.rubric} showMode={false} onChangeHook={forceUpdate}/>);
    }

    const getStepTwo = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Private Feedback
                </Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2" sx={{ mt: 1 }}>
                    For judging notes - only PRSF coordinators can see this feedback when compiling scores.
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={privateFeedback}
                    onChange={(e) => setPrivateFeedback(e.target.value)}
                    onBlur={saveFeedbackOnly}
                    sx={{ mt: 1 }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Public Feedback
                </Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2" sx={{ mt: 1 }}>
                    The student will be presented with this feedback - please be nice.
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={publicFeedback}
                    onChange={(e) => setPublicFeedback(e.target.value)}
                    onBlur={saveFeedbackOnly}
                    sx={{ mt: 1 }}
                />
                <Typography sx={{ mt: 2 }} variant="h6">Recommendation</Typography>
                <Typography style={{ color: '#4b4b4b' }} variant="body2">
                    Do you wish to recommend this project to represent Peel at the Canada Wide Science Fair?
                </Typography>
                <RadioGroup
                    defaultValue={defaultRecommendValue ?? 'not_recommend'}
                    onChange={(e) => setRecommend(e.target.value === 'recommend')}
                >
                    <FormControlLabel
                        value="recommend"
                        control={<Radio color="primary" />}
                        label="I recommend this project."
                    />
                    <FormControlLabel
                        value="not_recommend"
                        control={<Radio color="primary" />}
                        label="I do NOT recommend this project."
                    />
                </RadioGroup>
            </div>
        );
    }

    return (
        <div>
            {getStepOne()}
            {getStepTwo()}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    style={{ color: "#FFFFFF", backgroundColor: "#910000" }}
                    onClick={quit}
                    sx={{ mr: 1 }}
                >
                    Exit
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button
                        disabled={submittedRubric || !isValid()}
                        style={{ color: "#FFFFFF", backgroundColor: "#12824C" /*set disabled color*/, opacity: (submittedRubric || !isValid()) ? 0.5 : 1}}
                        onClick={saveFeedback}
                    >
                        Submit
                    </Button>
                )}
            </Box>
            <Typography
                style={{ textAlign: "center", color: "#4B4B4BFF", marginTop: "5px", marginBottom: "25px" }}
                variant="body1"
            >
                You can always modify your submission before the deadline.
            </Typography>
            <Box sx={{ height: '100px' }} />
        </div>
    );
};

export default RubricJudgeView;