import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid2,
    Box, Typography,
} from '@mui/material';
import ProjectListDialog from './ProjectListDialog';
import JudgeListDialog from './JudgeListDialog';
import TemplateListDialog from './TemplateListDialog';
import {regionDefaultTemplate} from "../../api/api";
import SmartJudgeListDialog from "./SmartJudgeListDialog"; // Assume you will create this component

const RubricCreateDialog = ({ open, onCreate, onCancel, currentUserID }) => {
    const [newRubric, setNewRubric] = useState({
        project_id: '',
        judge_id: '',
        template_id: '',
        score: 0,
        outdated: 0,
        completed: 0,
        recommended: 0,
        priv_feed: '',
        pub_feed: '',
    });
    const [projectSelectorOpen, setProjectSelectorOpen] = useState(false);
    const [judgeSelectorOpen, setJudgeSelectorOpen] = useState(false);
    const [templateSelectorOpen, setTemplateSelectorOpen] = useState(false);
    const [error, setError] = useState(null);

    useEffect( () => {
        const openCheck = async () => {
            if (open) {
                let defaultID = 0
                const res = await regionDefaultTemplate()
                if (res !== null) {
                    defaultID = res.id;
                }
                setNewRubric( {
                    project_id: '',
                    judge_id: '',
                    template_id: defaultID,
                    score: 0,
                    outdated: 0,
                    completed: 0,
                    recommended: 0,
                    priv_feed: '',
                    pub_feed: '',
                });
            }
        }

        openCheck();
    }, [open]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = value;

        if (type === 'checkbox') {
            newValue = checked ? 1 : 0;
        }

        setNewRubric((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    const handleSave = () => {
        if (newRubric.project_id === '' || newRubric.judge_id === '' || newRubric.template_id === '') {
            setError('Please select a project, judge, and template.');
            return;
        }
        onCreate(newRubric);
    };

    const handleOpenProjectSelector = () => {
        setProjectSelectorOpen(true);
    };

    const handleProjectSelect = (selectedId) => {
        setProjectSelectorOpen(false);
        if (selectedId !== null) {
            setNewRubric((prev) => ({
                ...prev,
                project_id: selectedId,
            }));
        }
    };

    const handleOpenJudgeSelector = () => {
        setJudgeSelectorOpen(true);
    };

    const handleJudgeSelect = (selectedId) => {
        setJudgeSelectorOpen(false);
        if (selectedId !== null) {
            setNewRubric((prev) => ({
                ...prev,
                judge_id: selectedId,
            }));
        }
    };

    const handleOpenTemplateSelector = () => {
        setTemplateSelectorOpen(true);
    };

    const handleTemplateSelect = (selectedId) => {
        setTemplateSelectorOpen(false);
        if (selectedId !== null) {
            setNewRubric((prev) => ({
                ...prev,
                template_id: selectedId,
            }));
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
                <DialogTitle>Create Rubric</DialogTitle>
                <DialogContent>
                    <Grid2 container spacing={1} direction="column" sx={{ marginTop: 1 }}>
                        <Grid2 xs={12} sm={12}>
                            <TextField
                                label="Project ID"
                                name="project_id"
                                value={newRubric.project_id || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                disabled={true}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenProjectSelector}
                                    fullWidth
                                >
                                    Select Project
                                </Button>
                            </Box>
                        </Grid2>
                        <Grid2  xs={12} sm={12}>
                            <TextField
                                label="Judge ID"
                                name="judge_id"
                                value={newRubric.judge_id || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                disabled={true}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenJudgeSelector}
                                    fullWidth
                                    disabled={newRubric.project_id === ''}
                                >
                                    Select Judge
                                </Button>
                            </Box>
                        </Grid2>
                        <Grid2  xs={12} sm={12}>
                            <TextField
                                label="Template ID"
                                name="template_id"
                                value={newRubric.template_id || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                disabled={true}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenTemplateSelector}
                                    fullWidth
                                    disabled={newRubric.judge_id === ''}

                                >
                                    Select Template
                                </Button>
                            </Box>
                        </Grid2>
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <ProjectListDialog
                open={projectSelectorOpen}
                onClose={handleProjectSelect}
            />
            <SmartJudgeListDialog
                open={judgeSelectorOpen}
                onClose={handleJudgeSelect}
                currentUserID={currentUserID}
                projectID={newRubric.project_id}
            />
            <TemplateListDialog
                open={templateSelectorOpen}
                onClose={handleTemplateSelect}
            />
        </>
    )
};

export default RubricCreateDialog;