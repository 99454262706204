import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import ProjectListDialog from './ProjectListDialog';

const EditDialog = ({ open, onClose, onSubmit, initialValues, fields, title }) => {
  const [formValues, setFormValues] = useState(initialValues || {});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    fields.forEach((field) => {
      if (field.required && (!formValues[field.name] || String(formValues[field.name]).trim() === '')) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit(formValues);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          {fields.map((field) => (
            <TextField
              key={field.name}
              required={field.required}
              fullWidth
              margin="normal"
              label={field.label}
              name={field.name}
              value={formValues[field.name] || ''}
              onChange={handleChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              select={field.options !== undefined}
              multiline={field.multiline}
              rows={field.multiline ? 4 : undefined}
              inputProps={
                field.name === 'project_id'
                  ? { readOnly: true, onClick: () => field.onClick?.() }
                  : {}
              }
            >
              {field.options &&
                field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const JudgeEditDialog = ({ open, onClose, onSubmit, initialValues }) => {
  const fields = [
    { name: 'first_name', label: 'First Name', required: true },
    { name: 'last_name', label: 'Last Name', required: true },
    { name: 'email', label: 'Email', required: true },
    { name: 'highest_education_obtained', label: 'Highest Education', options: ['Bachelors', 'Masters', 'Doctorate'] },
    { name: 'strengths', label: 'Strengths (comma-separated)' },
    { name: 'avoid', label: 'Avoid (comma-separated)' },
    { name: 'checked_in', label: 'Checked In', required: true, options: ['Yes', 'No'] },

  ];
  if  (initialValues.highest_education_obtained !== undefined) {
    // capitalize first letter and add s to end unless its doctorate, then just capitalize
    initialValues.highest_education_obtained = initialValues.highest_education_obtained.charAt(0).toUpperCase() + initialValues.highest_education_obtained.slice(1);
    if (initialValues.highest_education_obtained !== "Doctorate") {
      initialValues.highest_education_obtained += "s";
    }
  }

  return (
    <EditDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
      fields={fields}
      title="Edit Judge"
    />
  );
};

export const StudentEditDialog = ({ open, onClose, onSubmit, initialValues }) => {
  const [formValues, setFormValues] = useState(initialValues || {});
  const [isProjectDialogOpen, setProjectDialogOpen] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    }
  }, [initialValues]);

  const fields = [
    { name: 'first_name', label: 'First Name', required: true },
    { name: 'last_name', label: 'Last Name', required: true },
    { name: 'email', label: 'Email', required: true },
    { name: 'age_group', label: 'Age Group', required: true, options: ['Senior', 'Junior', 'Intermediate'] },
    {
      name: 'project_id',
      label: 'Project ID',
      required: true,
      onClick: () => setProjectDialogOpen(true),
    },
    { name: 'checked_in', label: 'Checked In', required: true, options: ['Yes', 'No'] },
  ];

  if (initialValues.age_group !== undefined) {
    if(initialValues.age_group === "?") {
        initialValues.age_group = "";
    }
  }

  const handleProjectDialogClose = (result) => {
    setProjectDialogOpen(false);
    if (result) {
      setFormValues((prevValues) => ({ ...prevValues, project_id: result }));
    }
  };

  return (
    <>
      <EditDialog
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        initialValues={formValues}
        fields={fields}
        title="Edit Student"
      />

      <ProjectListDialog
        open={isProjectDialogOpen}
        onClose={handleProjectDialogClose}
      />
    </>
  );
};

export const ProjectEditDialog = ({ open, onClose, onSubmit, initialValues }) => {
  const fields = [
    { name: 'title', label: 'Title', required: true },
    { name: 'challenge', label: 'Challenge (Optional)' },
    { name: 'type', label: 'Type (Optional)' },
    { name: 'tags', label: 'Tags (Comma Separated, Optional)' },
    { name: 'description', label: 'Description (Optional)', multiline: true },
  ];

  return (
    <EditDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
      fields={fields}
      title="Edit Project"
    />
  );
};

export default EditDialog;
