import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid2,
    FormControlLabel,
    Checkbox,
    Box,
} from '@mui/material';
import ProjectListDialog from './ProjectListDialog';
import JudgeListDialog from './JudgeListDialog';
import SmartJudgeListDialog from "./SmartJudgeListDialog";

const RubricEditDialog = ({ open, rubric, onChange, onCancel, currentUserID }) => {
    const [editedRubric, setEditedRubric] = useState({});
    const [projectSelectorOpen, setProjectSelectorOpen] = useState(false);
    const [judgeSelectorOpen, setJudgeSelectorOpen] = useState(false);
    if (currentUserID === undefined || currentUserID === null)
        currentUserID = -1;

    useEffect(() => {
        if (rubric) {
            setEditedRubric({ ...rubric });
        }
    }, [rubric]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = value;

        if (type === 'checkbox') {
            newValue = checked ? 1 : 0;
        }

        setEditedRubric((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    const handleSave = () => {
        onChange(editedRubric);
    };

    const handleOpenProjectSelector = () => {
        setProjectSelectorOpen(true);
    };

    const handleProjectSelect = (selectedId) => {
        setProjectSelectorOpen(false);
        if (selectedId !== null) {
            setEditedRubric((prev) => ({
                ...prev,
                project_id: selectedId,
            }));
        }
    };

    const handleOpenJudgeSelector = () => {
        setJudgeSelectorOpen(true);
    };

    const handleJudgeSelect = (selectedId) => {
        setJudgeSelectorOpen(false);
        if (selectedId !== null) {
            setEditedRubric((prev) => ({
                ...prev,
                judge_id: selectedId,
            }));
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Rubric</DialogTitle>
                <DialogContent>
                    <Grid2 container spacing={2} direction="column" sx={{ marginTop: 1 }}>
                        <Grid2 xs={12}>
                            <TextField
                                label="Project ID"
                                name="project_id"
                                value={editedRubric.project_id || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                disabled={true}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenProjectSelector}
                                    fullWidth
                                >
                                    Select Project
                                </Button>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12}>
                            <TextField
                                label="Judge ID"
                                name="judge_id"
                                value={editedRubric.judge_id || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                disabled={true}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenJudgeSelector}
                                    fullWidth
                                >
                                    Select Judge
                                </Button>
                            </Box>
                        </Grid2>
                        <Grid2 container xs={12} spacing={2}>
                            <Grid2 xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editedRubric.completed === 1}
                                            onChange={handleInputChange}
                                            name="completed"
                                        />
                                    }
                                    label="Completed"
                                />
                            </Grid2>
                            <Grid2 xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editedRubric.recommended === 1}
                                            onChange={handleInputChange}
                                            name="recommended"
                                        />
                                    }
                                    label="Recommended"
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12}>
                            <TextField
                                label="Private Feedback"
                                name="priv_feed"
                                value={editedRubric.priv_feed || ''}
                                onChange={handleInputChange}
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ paddingBottom: "5px" }}
                            />
                            <TextField
                                label="Public Feedback"
                                name="pub_feed"
                                value={editedRubric.pub_feed || ''}
                                onChange={handleInputChange}
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <TextField
                                label="Order"
                                name="order"
                                value={editedRubric.order || 0}
                                onChange={handleInputChange}
                                fullWidth
                                InputProps={{ inputProps: { min: 0, max: 10 } }}
                                type="number"
                            />

                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ProjectListDialog
                open={projectSelectorOpen}
                onClose={handleProjectSelect}
            />
            <SmartJudgeListDialog
                open={judgeSelectorOpen}
                onClose={handleJudgeSelect}
                currentUserID={currentUserID}
                projectID={editedRubric.project_id || rubric?.project_id}
            />
        </>
    )
};

export default RubricEditDialog;