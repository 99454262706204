import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import ProjectListDialog from './ProjectListDialog';

const StudentAddDialog = ({ open, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    ageGroup: '',
    projectId: '',
  });
  const [errors, setErrors] = useState({});
  const [isProjectDialogOpen, setProjectDialogOpen] = useState(false);

  const ageGroupOptions = ['Junior', 'Intermediate', 'Senior'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formValues.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formValues.email.trim()) newErrors.email = 'Email is required';
    if (!formValues.ageGroup.trim()) newErrors.ageGroup = 'Age group is required';
    if (!String(formValues.projectId).trim()) newErrors.projectId = 'Project ID is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit(formValues);
      onClose();
    }
  };

  const handleProjectDialogClose = (result) => {
    setProjectDialogOpen(false);
    if (result) {
      setFormValues({ ...formValues, projectId: result });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Student</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              required
              fullWidth
              margin="normal"
              label="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              label="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              select
              required
              fullWidth
              margin="normal"
              label="Age Group"
              name="ageGroup"
              value={formValues.ageGroup}
              onChange={handleChange}
              error={!!errors.ageGroup}
              helperText={errors.ageGroup}
            >
              {ageGroupOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              fullWidth
              margin="normal"
              label="Project ID"
              name="projectId"
              value={formValues.projectId}
              onClick={() => setProjectDialogOpen(true)}
              error={!!errors.projectId}
              helperText={errors.projectId}
              InputProps={{ readOnly: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <ProjectListDialog
        open={isProjectDialogOpen}
        onClose={handleProjectDialogClose}
      />
    </>
  );
};

export default StudentAddDialog;
