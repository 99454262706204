import React, {useEffect, useRef} from 'react';
import '../../hooks/useAuth';
import useAuth from "../../hooks/useAuth";
import JudgeDashboard from "../judge/JudgeDashboard";
import AdminDashboard from "../admin/AdminDashboard";
import {useError} from "../../context/ErrorContext";
import {Navigate, useLocation} from "react-router-dom";
import {loadUserDataComplete} from "../../api/api";

const Dashboard = (props) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const [loadedData, setLoadedData] = React.useState(false);
    let isAdmin = false;
    let isSuperAdmin = false;

    if(props.userData !== null){
        if(props.userData.is_admin !== null){
            isAdmin = props.userData.is_admin > 0;
            isSuperAdmin = props.userData.is_admin > 1;
        }
    }

    const { showMessage } = useError();
    const hasShownMessage = useRef(false);
    useEffect(() => {
        if (!hasShownMessage.current && location.state?.message && location.state?.severity) {
            showMessage(location.state.message, location.state.severity);
            hasShownMessage.current = true;
        }

    }, [location.state, showMessage]);

    useEffect(() => {
        if (!loadedData) {
            setLoadedData(true);
            loadUserDataComplete(props.setUserData);
        }
    }, [loadedData, props.setUserData]);

    // if isAdmin and the current route is /auth/dashboard reroute to /auth/admin/dashboard
    if(isAdmin && location.pathname === '/auth/dashboard'){
        return <Navigate to="/auth/admin/dashboard" />;
    }

    if(props.userData === null){
        return (
            <div>
                <h1>Dashboard</h1>
                <p>Loading...</p>
                <p>If you are not logged in you should be redirected, you will not be able to access this page logged out.</p>
            </div>
        );
    }

    // if isAdmin, show admin dashboard
    return (
        <div>
            {isAdmin ? <AdminDashboard isSuperAdmin={isSuperAdmin} userData={props.userData} setUserData={props.setUserData} setLoadedData={setLoadedData} /> :
                <JudgeDashboard userData={props.userData} setUserData={props.setUserData}/>}
        </div>
    );
}

export default Dashboard;