import React from 'react';
import {
    Box, Button,
    Card,
    CardContent, CircularProgress,
    Typography,

} from "@mui/material";
import {useTheme} from "@mui/styles";
import PeopleIcon from '@mui/icons-material/People';
import {massGenerateUsers} from "../../api/api";

export const MassGenerateUsers = () => {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const generateUsers = async () => {
        setLoading(true);
        const res = await massGenerateUsers();
        if (res === null) {
            setLoading(false);
            setError("Failed to generate users");
            return;
        }
        setLoading(false);
        setError("");
    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight, padding: 2, position: 'relative'}}>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <PeopleIcon
                    sx={{
                        color: '#2061d9',

                    }}
                />
                <Typography sx={{marginLeft: '10px'}} variant="h5">
                    Generate Judge User Accounts
                </Typography>
            </Box>

            <CardContent>
                <Typography variant="body2">
                    Generate accounts so judges can log into the system. This will create accounts for all judges in the
                    system. Judges cannot actually log in until the fair is started, but this will create the accounts.
                    <br/><br/>
                    This is a REQUIRED ACTION BEFORE STARTING THE FAIR.
                </Typography>
                <br/>

                <Box sx={{width: "100%"}}>
                    <Typography sx={{color: "#d93434"}}>{error}</Typography>
                    {
                        (loading) ?

                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <CircularProgress size={24} sx={{color: theme.palette.standardBlue}}/>
                            </Box>

                            :
                            <Button
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    backgroundColor: theme.palette.standardBlue,
                                    width: "100%",
                                    '&:hover': {
                                        backgroundColor: theme.palette.standardBlueSelect,
                                    },
                                }}
                                onClick={generateUsers}
                            >
                                Generate User Accounts
                            </Button>
                    }

                </Box>
            </CardContent>
        </Card>)
}
