import React, {useEffect, useState} from 'react';
import {useMediaQuery, useTheme} from "@mui/material";

const ScaleApp = ({children}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div
            style={{
                zoom: (isSmallScreen ? .8 : .8),
            }}
        >
            {children}
        </div>
    );
};

export default ScaleApp;
