import React from 'react'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Typography
} from "@mui/material";
import {runDiagnostics} from "../../api/api";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import Table from "@mui/material/Table";
import DiagnosticAccordion from "./DiagnosticAccordion";
import {useTheme} from "@mui/styles";

export const RunDiagnosticsCard = () => {
    const theme = useTheme();
    const [workingRunningDiagnostics, setWorkingRunningDiagnostics] = React.useState(false);
    const [ranDiagnostics, setRanDiagnostics] = React.useState(false);
    const [results, setResults] = React.useState(null);

    const openRelativePage = (url) => {
        const relativeUrl = `${window.location.origin}/${url}`;

        window.open(relativeUrl, "_blank", "noopener,noreferrer");
    };

    const fixButton = (url, text = "View") => {
        return (
            <Button
                variant="contained"
                sx={{
                    height: "16px",
                    minWidth: "50px",
                    padding: "0 10px",
                    fontSize: "11px",
                    lineHeight: "1",
                    backgroundColor: "#bd9127"
                }} onClick={() => openRelativePage(url)}>{text}</Button>
        )
    }

    const getDiagnostics = async () => {
        setWorkingRunningDiagnostics(true);
        setRanDiagnostics(false);

        const res = await runDiagnostics();

        setResults(res);
        setRanDiagnostics(true);
        setWorkingRunningDiagnostics(false);
    }

    const formatCheckedIn = () => {
        let status = 0;
        let msgs = [];
        if (results.checked_in_judges < 0.5 * results.checked_out_judges) {
            status = Math.max(status, 2);
            msgs.push([2, "More judges are checked out than checked in!", fixButton("auth/admin/management/check-in?typeFilter=judge&checkInFilter=checked_out&displayAll=true")]);
        }

        if (results.checked_in_students < 0.5 * results.checked_out_students) {
            status = Math.max(status, 2);
            msgs.push([2, "More students are checked out than checked in!", fixButton("auth/admin/management/check-in?typeFilter=student&checkInFilter=checked_out&displayAll=true")]);
        }

        if (results.checked_in_students === 0) {
            status = Math.max(status, 3);
            msgs.push([3, "No students are checked in, this could be an issue with the check in system!", fixButton("auth/admin/management/check-in?typeFilter=student&displayAll=true")]);
        }

        if (results.checked_in_judges === 0) {
            status = Math.max(status, 3);
            msgs.push([3, "No judges are checked in, this could be an issue with the check in system!", fixButton("auth/admin/management/check-in?typeFilter=judge&displayAll=true")]);
        }

        return [status, msgs, <div>
            <Typography>Stats: </Typography>
            <Table sx={{
                border: "1px dashed white",
                borderCollapse: "collapse",
                width: "100%",
            }}>
                <tbody>
                <tr>
                    <td>Checked In</td>
                    <td>{results.checked_in_total}</td>
                </tr>
                <tr>
                    <td>Checked Out</td>
                    <td>{results.checked_out_total}</td>
                </tr>
                <tr>
                    <td>Students Checked In</td>
                    <td>{results.checked_in_students}</td>
                </tr>
                <tr>
                    <td>Judges Checked In</td>
                    <td>{results.checked_in_judges}</td>
                </tr>
                <tr>
                    <td>Students Checked Out</td>
                    <td>{results.checked_out_students}</td>
                </tr>
                <tr>
                    <td>Judges Checked Out</td>
                    <td>{results.checked_out_judges}</td>
                </tr>
                </tbody>

            </Table>
        </div>]
    }

    const formatProjects = () => {

        let status = 0;
        let msgs = [];

        if (results.project_count === 0) {
            status = Math.max(status, 3);
            msgs.push([3, "No projects are in the database!", fixButton("auth/admin/management?startTab=project")]);
        }
        let dups = results.projects_duplicate_names
        if (dups.length !== 0) {
            status = Math.max(status, 1);
            // pair up each dupicate by dup[i].title, then create a separate alert for each one which includes the fix button which includes
            // unique search query to find the duplicate projects
            let filtered = {}

            // dont put two of the same project in the same alert so we keep the alerts unique
            for (let i = 0; i < dups.length; i++) {
                if (!(dups[i].title in filtered)) {
                    filtered[dups[i].title] = []
                }
                filtered[dups[i].title].push(dups[i])
            }

            for (let title in filtered) {
                let dupes = filtered[title]
                let alertMsg = `There are ${dupes.length} projects with the same title "${title}"`
                // make sure to url encode the title
                let fixBtn = fixButton(`auth/admin/management?startTab=project&query=${encodeURIComponent(title)}`)
                msgs.push([1, alertMsg, fixBtn])
            }
        }

        let nostud = results.projects_no_students
        if (nostud.length !== 0) {
            status = Math.max(status, 2);
            // create separate alert for each project with no students
            for (let i = 0; i < nostud.length; i++) {
                let alertMsg = `Project "${nostud[i].title}" has no students!`
                let fixBtn = fixButton(`auth/admin/management?startTab=project&query=${encodeURIComponent(nostud[i].title)}`)
                msgs.push([2, alertMsg, fixBtn])
            }
        }

        return [status, msgs,
            <div>
                <Typography>Stats: </Typography>
                <Table sx={{
                    border: "1px dashed white",
                    borderCollapse: "collapse",
                    width: "100%",
                }}>
                    <tbody>
                    <tr>
                        <td>Project Count</td>
                        <td>{results.project_count}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>]
    }

    const formatStudents = () => {

        let status = 0;
        let msgs = [];

        if (results.project_count === 0) {
            status = Math.max(status, 3);
            msgs.push([3, "No projects are in the database!", fixButton("auth/admin/management?startTab=student")]);
        }

        let noproj = results.students_no_project
        if (noproj.length !== 0) {
            status = Math.max(status, 2);
            // create separate alert for each student with no project
            for (let i = 0; i < noproj.length; i++) {
                let alertMsg = `Student "${noproj[i].first_name} ${noproj[i].last_name}" (${noproj[i].email}) has no project!`
                let fixBtn = fixButton(`auth/admin/management?startTab=student&query=${encodeURIComponent(noproj[i].first_name + " " + noproj[i].last_name)}`)
                msgs.push([2, alertMsg, fixBtn])
            }
        }

        return [status, msgs,
            <div>
                <Typography>Stats: </Typography>
                <Table sx={{
                    border: "1px dashed white",
                    borderCollapse: "collapse",
                    width: "100%",
                }}>
                    <tbody>
                    <tr>
                        <td>Student Count</td>
                        <td>{results.student_count}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>]
    }

    const formatJudges = () => {
        let status = 0;
        let msgs = [];

        if (results.judges_checked_in_no_schedule.length > 0) {
            status = Math.max(status, 3);
            results.judges_checked_in_no_schedule.forEach(judge => {
                msgs.push([3, `Judge "${judge.first_name} ${judge.last_name}" (${judge.email}) is checked in but has no schedule!`, fixButton(`auth/admin/management?startTab=judge&query=${encodeURIComponent(judge.first_name + " " + judge.last_name)}`)]);
            });
        }

        if (results.judges_not_checked_in_no_schedule.length > 0) {
            status = Math.max(status, 1);
            results.judges_not_checked_in_no_schedule.forEach(judge => {
                msgs.push([1, `Judge "${judge.first_name} ${judge.last_name}" (${judge.email}) is not checked in and has no schedule.`, fixButton(`auth/admin/management?startTab=judge&query=${encodeURIComponent(judge.first_name + " " + judge.last_name)}`)]);
            });
        }

        if (results.judges_no_login.length > 0) {
            status = Math.max(status, 3);
            results.judges_no_login.forEach(judge => {
                msgs.push([3, `Judge "${judge.first_name} ${judge.last_name}" (${judge.email}) has no login credentials!`, fixButton(`auth/admin/management?startTab=judge&query=${encodeURIComponent(judge.first_name + " " + judge.last_name)}`)]);
            });
        }

        if (results.judges_no_strengths.length > 0) {
            status = Math.max(status, 2);
            results.judges_no_strengths.forEach(judge => {
                msgs.push([2, `Judge "${judge.first_name} ${judge.last_name}" (${judge.email}) has no strengths listed.`, fixButton(`auth/admin/management?startTab=judge&query=${encodeURIComponent(judge.first_name + " " + judge.last_name)}`)]);
            });
        }

        if (results.judges_no_avoid.length > 0) {
            status = Math.max(status, 2);
            results.judges_no_avoid.forEach(judge => {
                msgs.push([2, `Judge "${judge.first_name} ${judge.last_name}" (${judge.email}) has no avoid preferences listed.`, fixButton(`auth/admin/management?startTab=judge&query=${encodeURIComponent(judge.first_name + " " + judge.last_name)}`)]);
            });
        }

        return [status, msgs,
            <div>
                <Typography>Stats: </Typography>
                <Table sx={{
                    border: "1px dashed white",
                    borderCollapse: "collapse",
                    width: "100%",
                }}>
                    <tbody>
                    <tr>
                        <td>Total Judges</td>
                        <td>{results.judge_count_total}</td>
                    </tr>
                    <tr>
                        <td>Real Judges</td>
                        <td>{results.judge_count_real}</td>
                    </tr>
                    <tr>
                        <td>Admin Generated Judges</td>
                        <td>{results.judge_count_admin_generated}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        ];
    }

    const formatPeople = () => {
        let status = 0;
        let msgs = [];

        // check for people_duplicate_names, if they exist handle like we did for projects
        let dups = results.people_duplicate_names
        if (dups.length === 0) {
            status = Math.max(status, 2);
            let filtered = {}

            // dont put two of the same person in the same alert so we keep the alerts unique
            for (let i = 0; i < dups.length; i++) {
                if (!(dups[i].first_name + " " + dups[i].last_name in filtered)) {
                    filtered[dups[i].first_name + " " + dups[i].last_name] = []
                }
                filtered[dups[i].first_name + " " + dups[i].last_name].push(dups[i])
            }

            for (let name in filtered) {
                let dupes = filtered[name]
                let alertMsg = `There are ${dupes.length} people with the same name "${name}"`
                // make sure to url encode the name
                let fixBtn = fixButton(`auth/admin/management?startTab=${filtered[name][0].type}&query=${encodeURIComponent(name)}`)
                msgs.push([2, alertMsg, fixBtn])
            }
        }

        return [status, msgs,
           <></>
        ];
    }

    const formatTemplates = () => {
        let status = 0;
        let msgs = [];

        if (!results.has_default_template) {
            status = Math.max(status, 3);
            msgs.push([3, "There is no default rubric template for this region!", fixButton("auth/admin/template-management")]);
        }

        if (!results.has_active_template) {
            status = Math.max(status, 3);
            msgs.push([3, "There is no active rubric template for this region!", fixButton("auth/admin/template-management")]);
        }

        return [status, msgs,
            <></>
        ];
    }

    const formatRubrics = () => {
        // check for rubrics_no_categories and display rubric_count in table
        let status = 0;
        let msgs = [];

        if (results.rubrics_no_categories.length > 0) {
            status = Math.max(status, 2);
            results.rubrics_no_categories.forEach(rubric => {
                msgs.push([2, `Rubric #${rubric.id} has no categories!`, fixButton(`auth/admin/rubrics?search=${encodeURIComponent("#" + rubric.id)}`)]);
            });

        }

        return [status, msgs,
            <div>
                <Typography>Stats: </Typography>
                <Table sx={{
                    border: "1px dashed white",
                    borderCollapse: "collapse",
                    width: "100%",
                }}>
                    <tbody>
                    <tr>
                        <td>Rubric Count</td>
                        <td>{results.rubric_count}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        ];
    }

    const getIconByStatus = (status) => {
        if (status === 1) {
            return <InfoIcon sx={{color: "#bbbbbb"}}/>
        } else if (status === 0) {
            return <CheckCircleIcon sx={{color: "#5cb728"}}/>
        } else if (status === 2) {
            return <WarningIcon sx={{color: "#f1c40f"}}/>
        } else if (status === 3) {
            return <ReportIcon sx={{color: "#e74c3c"}}/>
        }
    }

    const formatResults = () => {
        if (results === null) {
            return (
                <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", textAlign: "center"}}>
                    No results...<br/>
                    (Did an error occur? 🙁)
                </Box>
            );
        }

        const [checkedInStatus, msgs, checkedInElements] = formatCheckedIn();
        const [projectsStatus, projectsMsgs, projectsElements] = formatProjects();
        const [studentStatus, studentMsgs, studentElements] = formatStudents();
        const [judgeStatus, judgeMsgs, judgeElements] = formatJudges();
        const [peopleStatus, peopleMsgs, peopleElements] = formatPeople();
        const [templateStatus, templateMsgs, templateElements] = formatTemplates();
        const [rubricStatus, rubricMsgs, rubricElements] = formatRubrics();

        const accordionData = [
            {
                title: "Checked In",
                icon: getIconByStatus(checkedInStatus),
                messages: msgs,
                elements: checkedInElements,
                status: checkedInStatus
            },
            {
                title: "People",
                icon: getIconByStatus(peopleStatus),
                messages: peopleMsgs,
                elements: peopleElements,
                status: peopleStatus
            },

            {
                title: "Students",
                icon: getIconByStatus(studentStatus),
                messages: studentMsgs,
                elements: studentElements,
                status: studentStatus
            },
            {
                title: "Judges",
                icon: getIconByStatus(judgeStatus),
                messages: judgeMsgs,
                elements: judgeElements,
                status: judgeStatus
            },
            {
                title: "Projects",
                icon: getIconByStatus(projectsStatus),
                messages: projectsMsgs,
                elements: projectsElements,
                status: projectsStatus
            },
            {
                title: "Rubrics",
                icon: getIconByStatus(rubricStatus),
                messages: rubricMsgs,
                elements: rubricElements,
                status: rubricStatus
            },
            {
                title: "Templates",
                icon: getIconByStatus(templateStatus),
                messages: templateMsgs,
                elements: templateElements,
                status: templateStatus
            },
        ];

        return (
            <Box>
                <Typography variant="h6">Results</Typography>
                <Box sx={{width: "100%", minWidth: "250px"}}>
                    {accordionData.map((data, index) => (
                        <DiagnosticAccordion
                            key={index}
                            title={data.title}
                            icon={data.icon}
                            messages={data.messages}
                            elements={data.elements}
                            status={data.status}
                        />
                    ))}
                </Box>
            </Box>
        );
    };


    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <MonitorHeartIcon sx={{color: "#a91d47", marginTop: "10px", marginLeft: "10px"}}/>
                <Typography sx={{marginTop: "10px", marginLeft: "10px"}} variant="h5">Fair Diagnostics</Typography>
            </Box>
            <CardContent>
                <Typography variant="body2">
                    Diagnose and detect issues early related to database integrity and fair status.
                </Typography>

                {(workingRunningDiagnostics) ?
                    <Box sx={{
                        display: 'flex',
                        marginTop: "10px",
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <CircularProgress size={24} sx={{color: "#3db451"}}/>
                    </Box>
                    :
                    <>
                        {(!ranDiagnostics) ?
                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>

                                <Button onClick={getDiagnostics} variant="contained">Run Diagnostics</Button>
                                <div style={{marginTop: "5px"}}/>
                                <Typography variant="caption">Time to run diagnostics varies by database size but should
                                    be quick!</Typography>
                                <div>
                                    <Alert severity="warning">Do not leave this page or your request will be lost!
                                    </Alert>
                                </div>
                            </Box>

                            :
                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                {formatResults()}
                                <Button onClick={getDiagnostics}>Rerun Diagnostics</Button>
                            </Box>


                        }
                    </>
                }
            </CardContent>

        </Card>
    )
}