import React from 'react';
import {enterMockAdmin, enterMockJudge, enterMockSuperAdmin, loadUserDataComplete, setUserID} from '../../api/api';
import {Box, Button, Tooltip, Typography} from '@mui/material';
import JudgeListDialog from './JudgeListDialog';
import {useNavigate} from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {useTheme} from "@mui/styles";

export const MockViewButtons = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();

    let current_admin_level = 0;
    let real_admin_level = 0;
    let real_user_id = 0;
    if (props.userData !== null) {
        current_admin_level = props.userData.is_admin;
        real_admin_level = props.userData.real_admin_level;
        real_user_id = props.userData.real_person_id;
    }

    const [judgeSelectorDialogOpen, setJudgeSelectorDialogOpen] = React.useState(false);

    const onJudgeSelectSelected = async (selectedJudgeID) => {
        setJudgeSelectorDialogOpen(false);
        if (selectedJudgeID === null || selectedJudgeID === undefined) return;

        const res = await setUserID(selectedJudgeID);
        if (res === null) return;

        props.userData.person_id = selectedJudgeID;

        if (current_admin_level === 0) {
            await loadUserDataComplete(props.setUserData);

            if (props.refreshHook !== undefined) props.refreshHook();
            navigate('/auth/dashboard', {
                state: {message: 'Reloaded Judge Mock View', severity: 'info'}
            });
        }
    };

    const enterAdminView = async () => {
        let res = await enterMockAdmin();
        if (res === null) {
            return;
        }
        await loadUserDataComplete(props.setUserData);
        if (props.refreshHook !== undefined) props.refreshHook();
        // get current page position and refresh
        const currentPath = window.location.pathname;
        navigate(currentPath, {
            state: {message: 'Entered Admin View', severity: 'info'}
        });

    }

    const enterJudgeView = async () => {
        let res = await enterMockJudge();
        if (res === null) {
            return;
        }
        await loadUserDataComplete(props.setUserData);

        if (props.refreshHook !== undefined) props.refreshHook();
        navigate('/auth/dashboard', {
            state: {message: 'Entered Judge Mock View', severity: 'info'}
        });
    }


    const judgeViewButton = (version) => {
        if (version === "topbar") {
            return (
                <Box>
                    <Button
                        onClick={enterJudgeView}
                        sx={{
                            display: "flex", flexDirection: "column", alignItems: "center",
                            '&:hover': {
                                backgroundColor: 'rgb(140,140,140)',
                            },
                        }}
                    >
                        <Tooltip title="Enter Judge View">
                            <div onClick={enterJudgeView}
                                         aria-label="admin">
                                <VisibilityIcon sx={{color: "#FFFFFF"}}/>
                            </div>
                        </Tooltip>
                        <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                            Judge View
                        </Typography>
                    </Button>
                </Box>
            )
        }

        return (
            <Button
                onClick={enterJudgeView}
                sx={{
                    textTransform: 'none',
                    width: "100%",
                    justifyContent: "flex-start",
                    color: "#000000",
                    '&:hover': {
                        backgroundColor: 'rgb(171,171,171)',
                        color:"white"
                    },
                }}
            >
                <div onClick={enterJudgeView}
                             aria-label="admin">
                    <VisibilityIcon sx={{color: "#656565"}}/>
                </div>
                <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px", color:theme.palette.black}}>
                    Judge View
                </Typography>
            </Button>
        )
    }

    const adminViewButton = (version) => {
        if (version === "topbar") {
            return (
                <Box>
                    <Button
                        onClick={enterAdminView}
                        sx={{
                            display: "flex", flexDirection: "column", alignItems: "center",
                            '&:hover': {
                                backgroundColor: 'rgb(140,140,140)',
                            },
                        }}
                    >
                        <Tooltip title="Enter Admin View">
                            <div onClick={enterAdminView}  aria-label="admin">
                                <SupervisorAccountIcon sx={{color: "#ffffff"}}/>
                            </div>
                        </Tooltip>
                        <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                            Admin View
                        </Typography>
                    </Button>
                </Box>
            );
        }

        return (
            <Button
                onClick={enterAdminView}
                sx={{
                    textTransform: 'none',
                    width: "100%",
                    justifyContent: "flex-start",
                    color: "#000000",
                    '&:hover': {
                        backgroundColor: 'rgb(59,95,215)',
                        color:"white"
                    },
                }}
            >
                <div onClick={enterAdminView} aria-label="admin">
                    <SupervisorAccountIcon sx={{color: "#033e73"}}/>
                </div>
                <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px", color:theme.palette.black}}>
                    Admin View
                </Typography>
            </Button>
        );
    }

    const enterSuperAdminView = async () => {
        let res = await enterMockSuperAdmin();
        if (res === null) {
            return;
        }
        await loadUserDataComplete(props.setUserData);
        if (props.refreshHook !== undefined) props.refreshHook();

        // get current page position and refresh
        const currentPath = window.location.pathname;
        navigate(currentPath, {
            state: {message: 'Entered Super Admin View', severity: 'info'}
        });
    }

    const superAdminButton = (version) => {
       if (version === "topbar") {
            return (
                <Box>
                    <Button
                        onClick={enterSuperAdminView}
                        sx={{
                            display: "flex", flexDirection: "column", alignItems: "center",
                            '&:hover': {
                                backgroundColor: 'rgb(140,140,140)',
                            },
                        }}
                    >
                        <Tooltip title="Enter Super Admin View">
                            <div onClick={enterSuperAdminView} aria-label="admin">
                                <AdminPanelSettingsIcon sx={{color: "#ffffff"}}/>
                            </div>
                        </Tooltip>
                        <Typography textTransform={"none"} variant="caption" component="span" color="#FFFFFF">
                            Super Admin View
                        </Typography>
                    </Button>
                </Box>
            );
        }

        return (
            <Button
                onClick={enterSuperAdminView}
                sx={{
                    textTransform: 'none',
                    width: "100%",
                    justifyContent: "flex-start",
                    color: "#000000",
                    '&:hover': {
                        backgroundColor: 'rgb(59,95,215)',
                        color:"white"
                    },
                }}
            >
                <div onClick={enterSuperAdminView} aria-label="admin">
                    <AdminPanelSettingsIcon sx={{color: "#033e73"}}/>
                </div>
                <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px", color:theme.palette.black}}>
                    Super Admin View
                </Typography>
            </Button>
        );
    };

    const currentViewLabel = (version) => {
        if (current_admin_level !== 0) return null;
        if (version === "topbar") {
            return (
                <>
                    <Box>
                        <Button
                            onClick={() => {
                                    setJudgeSelectorDialogOpen(true);
                                }}
                            sx={{
                                display: "flex", flexDirection: "column", alignItems: "center",
                                '&:hover': {
                                    backgroundColor: 'rgb(140,140,140)',
                                },
                            }}
                        >
                            <Tooltip title="Select Judge">
                                <div onClick={() => {
                                    setJudgeSelectorDialogOpen(true);
                                }} aria-label="admin">
                                    <PersonSearchIcon sx={{color: "#ffffff"}}/>
                                </div>
                            </Tooltip>
                            <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                                Select Judge
                            </Typography>
                        </Button>
                    </Box>
                    <JudgeListDialog
                        open={judgeSelectorDialogOpen}
                        onClose={onJudgeSelectSelected}
                        currentUserID={real_user_id}
                    />
                </>
            )
        }

        return (
            <>
                <Button
                onClick={() => {
                    setJudgeSelectorDialogOpen(true);
                }}
                sx={{
                    textTransform: 'none',
                    width: "100%",
                    justifyContent: "flex-start",
                    color: "#000000",
                    '&:hover': {
                        backgroundColor: 'rgb(132,109,232)',
                        color:"white"
                    },
                }}
            >
                <div onClick={() => {
                            setJudgeSelectorDialogOpen(true);
                        }} aria-label="admin">
                            <PersonSearchIcon sx={{color: "#664c9a"}}/>
                        </div>
                <Typography variant="body1" sx={{paddingRight: "10px", paddingBottom: "5px", color:theme.palette.black}}>
                    Select Judge
                </Typography>
            </Button>
                <JudgeListDialog
                    open={judgeSelectorDialogOpen}
                    onClose={onJudgeSelectSelected}
                    currentUserID={real_user_id}
                />
            </>

        )
    };

    const mockViewButton = (props) => {

        if (real_admin_level === 0) {
            // Not an admin at all -> show nothing
            return null;
        }

        if (current_admin_level === 0) {
            return (
                <>
                    {(props.version === "topbar") ? currentViewLabel(props.version) : ""}
                    {adminViewButton(props.version)}
                    {(props.version !== "topbar") ? currentViewLabel(props.version) : ""}
                    {real_admin_level === 2 && superAdminButton(props.version)}

                </>
            );
        } else if (current_admin_level === 1) {
            return (
                <>
                    {(props.version === "topbar") ? currentViewLabel(props.version) : ""}

                    {judgeViewButton(props.version)}
                    {(props.version !== "topbar") ? currentViewLabel(props.version) : ""}
                    {real_admin_level === 2 && superAdminButton(props.version)}

                </>
            );
        } else if (current_admin_level === 2) {
            return (
                <>


                    {(props.version === "topbar") ? currentViewLabel(props.version) : ""}
                    {judgeViewButton(props.version)}
                    {adminViewButton(props.version)}
                    {currentViewLabel(props.version)}
                    {(props.version !== "topbar") ? currentViewLabel(props.version) : ""}

                </>
            );
        }
        return null;
    };

    return mockViewButton(props);
};
