import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useTheme} from "@mui/styles";

const ActionBox = ({title, buttonTitle, description, onClick}) => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            margin: 1,
            backgroundColor: theme.palette.dashboardButton,
            borderRadius: 2,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            width: '100%',
            maxWidth: '300px',
            textAlign: 'center',
            minHeight: "200px"
        }}>
            <Typography variant="h6" sx={{marginBottom: 1}}>{title}</Typography>
            <div style={{margin: "5px"}}>
                {description}
            </div>
            <Button variant="contained" onClick={onClick}>{buttonTitle}</Button>
        </Box>
    );
};

export default ActionBox;