import React from 'react';
import {useNavigate} from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import {loadUserDataComplete} from "../../api/api";
import {Box, Button, Typography} from "@mui/material";

const ReloadDataButton = (props) => {
    const navigate = useNavigate();

    const loadUserData = async () => {
        loadUserDataComplete(props.setUserData);
        navigate('/auth/dashboard', {state: {message: 'User data reloaded', severity: 'info'}});
    };
    if (props.version === "topbar") {

        return (
            <Box>
                <Button
                    onClick={loadUserData}
                    sx={{
                        display: "flex", flexDirection: "column", alignItems: "center",
                        '&:hover': {
                            backgroundColor: 'rgb(126,64,30)',
                        },
                    }}
                >
                    <div onClick={loadUserData} aria-label="refresh">
                        <RefreshIcon sx={{color: props.color}}/>
                    </div>
                    <Typography textTransform="none" variant="caption" component="span" color="#FFFFFF">
                        Refresh
                    </Typography>
                </Button>
            </Box>
        );
    }

    return (
        <Button
            onClick={loadUserData}
            sx={{
                textTransform: 'none',
                    width:"100%",
                    justifyContent: "flex-start",
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgb(65,102,162)',
                        color: 'white'
                    },
                }}
            >
                 <div onClick={loadUserData} aria-label="refresh">
                    <RefreshIcon sx={{color: props.color}}/>
                </div>
                <Typography variant="body1" sx={{paddingRight:"10px", paddingBottom: "5px"}}>
                    Refresh
                </Typography>
            </Button>
    );

};

export default ReloadDataButton;