import React from 'react';
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AppBar, Toolbar, Typography, Button, Card, CardContent, CardActions, Box, Container } from '@mui/material';
import {useTheme} from "@mui/styles";

const Home = () => {
    const navigate = useNavigate();
    const year = new Date().getFullYear();
    const { isAuthenticated } = useAuth();
    const theme = useTheme();
    const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.white
    },
    appBar: {
        backgroundColor: '#3f51b5',
    },
    logo: {
        flexGrow: 1,
    },
    contentContainer: {
        flex: 1, // Take up remaining space between header and footer
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    card: {
        maxWidth: '500px',
        width: '100%',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        backgroundColor: theme.palette.lightGreyLight,
    },
    buttonGroup: {
        justifyContent: 'center',
        gap: '10px',
    },
    footer: {
        backgroundColor: '#3f51b5',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,

    },
};

    return (
        <Box sx={styles.pageContainer}>
            {/* Navbar */}
            <AppBar position="static" sx={styles.appBar}>
                <Toolbar>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        marginLeft: "-10px"
                    }}
                >
                    <div className="appbarspace">
                        <Box sx={{width: 20}}/>
                    </div>


                    <img
                        src="/prsflogo.png"
                        alt="Logo"
                        style={{height: '30px'}}
                    />

                    <Box>
                        <Typography variant="h6" noWrap>
                            Peel Region Science Fair, {year}
                        </Typography>
                    </Box>
                </Box>
                </Toolbar>
            </AppBar>

            {/* Main Content */}
            <Container sx={styles.contentContainer}>
                <Card sx={styles.card}>
                    <CardContent>
                        <Typography variant="h4" color="primary" gutterBottom>
                            Peel Region Science Fair {year}
                        </Typography>
                        {isAuthenticated ? (
                            <Typography variant="body1" color="textSecondary">
                                You're already logged in.
                            </Typography>
                        ) : (
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                If you are a judge, log in with the credentials you were provided:
                            </Typography>
                        )}
                    </CardContent>
                    <CardActions sx={styles.buttonGroup}>
                        {isAuthenticated ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/auth/dashboard')}
                            >
                                Dashboard
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/login')}
                            >
                                Log In
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Container>

            {/* Footer */}
            <Box component="footer" sx={styles.footer}>
                <Typography variant="body2">
                    &copy; 2024 University of Toronto Mississauga. Maybe some rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};



export default Home;
