import React, {useState} from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    Tooltip,
} from '@mui/material';
import TemplateManagementButton from './TemplateManagementButton';
import '../../../styles/dashboard.css';
import {createRubricTemplate} from "../../../api/api";

import DefaultPageContainer from "../../reusable/DefaultPageContainer";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

const TemplateManagementView = (props) => {

    const [templates, setTemplates] = useState(props.userData.adminData.templates);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const removeAllDefaults = () => {
        const updatedTemplates = templates.map(template => ({
            ...template,
            is_default: 0
        }));
        setTemplates(updatedTemplates);
    };

    const deleteEntry = async (id) => {
        const updatedTemplates = templates.filter(template => template.id !== id);
        setTemplates(updatedTemplates);
    }


    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCreate = async () => {
        if (name === "" || description === "") {
            return;
        }
        const result = await createRubricTemplate(name, description)
        if (result === null || result === undefined) {
            console.error("Error creating template");
            return;
        }
        setTemplates([...templates, result]);
        handleDialogClose();
    };

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <Box>
                <div style={{alignItems: "left", marginBottom: "20px", marginLeft: "10px", marginTop: "10px"}}>
                    <Typography variant="h5">Template Management</Typography>
                    <Typography variant="body3" sx={{"fontSize": "15px"}}>Modify your region's rubrics before they're created.</Typography>
                </div>

                {/*<Typography variant="h5">Templates for your region:</Typography>*/}
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    {templates
                        .sort((a, b) => b.is_default - a.is_default)
                        .map((template, index) => (
                            <Box key={index} sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <TemplateManagementButton
                                    data={template}
                                    removeAllDefaults={removeAllDefaults}
                                    setTemplates={setTemplates}
                                    deleteEntry={deleteEntry}
                                />
                            </Box>
                        ))}


                    <Button
                        onClick={handleDialogOpen}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(25, 118, 210, 0.1)',
                            },
                        }}
                    >
                        <Tooltip title="Add Template">
                            <LibraryAddIcon sx={{color: "#1976d2"}}/>
                        </Tooltip>
                        <Typography variant="body1">
                            Add Template
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Create New Template</DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography>Name:</Typography>
                            <TextField
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                inputProps={{maxLength: 255}}
                                sx={{backgroundColor: '#d9d9d9', input: {color: 'black'}}}
                            />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography>Description:</Typography>
                            <TextField
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{backgroundColor: '#d9d9d9', input: {color: 'black'}}}
                            />
                        </Box>
                        <Button sx={{
                            backgroundColor: '#197319',
                            color: 'white',
                            '&:hover': {backgroundColor: '#197319'}
                        }} onClick={handleCreate}>
                            Create
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </DefaultPageContainer>
    );
};

export default TemplateManagementView;