import React from 'react';
import {Box, Button, Typography, createTheme, ThemeProvider} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useTheme} from "@mui/styles";

const RightJudgeSidebar = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();


    if (!props.userData?.rubricData) {
        return (
            <Box>
                <Typography sx={{color: "red"}}>Critical Error</Typography>
                <br/>
                <Typography sx={{color: theme.palette.black}}>No rubric data found...</Typography>
            </Box>

        );
    }


    const handleRubricClick = (projectId) => {
        navigate('/auth/rubric/' + projectId);
    };

    const shortenText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    let toDoRubrics = props.userData.rubricData.rubrics.filter((r) => r.completed === 0);
    let doneRubrics = props.userData.rubricData.rubrics.filter((r) => r.completed === 1);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "inherit"
            }}
        >
            <Typography variant="body2">Incomplete Evaluations:</Typography>
            {toDoRubrics.map((rubric) => (
                <Button
                    key={rubric.project_id}
                    onClick={() => handleRubricClick(rubric.project_id)}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: theme.palette.black,
                        '&:hover': {
                            backgroundColor: 'rgb(169,48,48)',
                            color: theme.palette.white
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <div onClick={() => handleRubricClick(rubric.project_id)} aria-label="incomplete evaluation">
                        <ErrorOutlineIcon sx={{color: "#ef4646"}}/>
                    </div>
                    <Typography variant="body1" sx={{paddingLeft: "20px", paddingBottom: "5px"}}>
                        {shortenText(rubric.project_name, 40)}
                    </Typography>
                </Button>
            ))}
            {(toDoRubrics.length === 0) ?
                <Typography variant="caption" sx={{color: theme.palette.black}}>You have no more projects to
                    evaluate!</Typography>
                : ""
            }



            {(doneRubrics.length === 0) ?
               "" : <Typography sx={{marginTop:"10px"}} variant="body2">Complete Evaluations:</Typography>
            }
            {doneRubrics.map((rubric) => (
                <Button
                    key={rubric.project_id}
                    onClick={() => handleRubricClick(rubric.project_id)}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: theme.palette.black,
                        '&:hover': {
                            backgroundColor: 'rgb(56,136,35)',
                            color: theme.palette.white
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <div onClick={() => handleRubricClick(rubric.project_id)} aria-label="complete evaluation">
                        <CheckCircleOutlineIcon sx={{color: "#56c935"}}/>
                    </div>
                    <Typography variant="body1" sx={{paddingLeft: "20px", paddingBottom: "5px"}}>
                        {shortenText(rubric.project_name, 40)}
                    </Typography>
                </Button>
            ))}

        </Box>
    );
};

export default RightJudgeSidebar;
