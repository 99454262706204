import React from 'react';
import DefaultPageContainer from "../reusable/DefaultPageContainer";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FairActiveStatusCard } from "./FairActiveStatusCard";
import { RunDiagnosticsCard } from "./RunDiagnosticsCard";
import { ExportDataCard } from "./ExportDataCard";
import { BackupDataCard } from "./BackupDataCard";
import {MockDataCard} from "./MockDataCard";
import {MassGenerateTagsCard} from "./MassGenerateTagsCard";
import {NukeDBCard} from "./NukeDBCard";
import {MassGenerateUsers} from "./MassGenerateUsers";
import {AssignJudgesCard} from "./AssignJudgesCard";

const FairManagement = (props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <div style={{ marginBottom: "15px", marginTop: "10px", padding: '0 16px' }}>
                <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
                    Fair Management
                </Typography>

                <Typography variant="body2" sx={{ fontSize: "15px", marginBottom: "15px" }}>
                    Manage key fair elements
                </Typography>

                <Box
                    sx={{
                        marginTop: "15px",
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
                        gap: 2,
                        width: "100%",
                    }}
                >
                    <FairActiveStatusCard />
                    <RunDiagnosticsCard />
                    <ExportDataCard />
                    <BackupDataCard />
                    <MockDataCard />
                    <MassGenerateTagsCard/>
                    <MassGenerateUsers/>
                    <AssignJudgesCard/>
                    {(props.userData.is_admin===2)?
                        <NukeDBCard/>
                        : ""}
                </Box>
            </div>
        </DefaultPageContainer>
    );
};

export default FairManagement;
