import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {getPersonData, getUserData, loadUserDataComplete} from "../api/api";

const AdminRoute = (props) => {
    const { isAuthenticated } = useAuth();
    const [loadedData, setLoadedData] = React.useState(false);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (!props.userData) {
        if (loadedData === false) {
            loadUserDataComplete(props.setUserData)
        }
    }
    if(!props.userData) {
        return <p>Loading...</p>;
    }

    if(props.userData.is_admin === 0){
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
}

export default AdminRoute;