import React from "react";
import {loadUserDataComplete} from "../../api/api";

export const loadDataForPage = (loadedData, setLoadedData, props) => {

    if(loadedData)
        return [true, <></>];

    if(props.userData === null || props.userData === undefined) {
        loadUserDataComplete(props.setUserData).then(() => {
                setLoadedData(true);
        });

    }
    else {
        setLoadedData(true);
        return [true, <></>];
    }
    return[false, (
            <div>
                <p>Loading...</p>
                <p>If you are not logged in you should be redirected, you will not be able to access this page logged out.</p>
            </div>
        )];
}