import React, {useEffect, useState} from 'react';
import {AppBar, Box, Divider, Toolbar, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import DashboardButton from '../sidebar/DashboardButton';
import ReloadDataButton from '../sidebar/ReloadDataButton';
import SignOutButton from '../sidebar/SignOutButton';
import {MockViewButtons} from './MockViewButtons';

export const TopBar = (props) => {
    let year = new Date().getFullYear();
    const navigate = useNavigate();

    const [greetingIndex, setGreetingIndex] = useState(0);
    const greetings = [
        'Hello', 'Howdy', 'Greetings', 'Salutations',
        'Hi', 'Hey', "What's up", 'Good day',
        'Hello there', 'Hey there', 'Hi there'
    ];

    useEffect(() => {
        setGreetingIndex(Math.floor(Math.random() * greetings.length));
    }, []);

    return (
        <AppBar position="sticky" overflow="hidden">
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    height:"100%"
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        marginLeft: "-10px"
                    }}
                >
                    <div className="appbarspace">
                        <Box sx={{width: 20}}/>
                    </div>


                    <img
                        onClick={() => navigate('/auth/dashboard')}
                        src="/prsflogo.png"
                        alt="Logo"
                        style={{height: '30px', cursor: 'pointer'}}
                    />

                    <Box
                        sx={{cursor: 'pointer'}}
                        onClick={() => navigate('/auth/dashboard')}
                    >
                        <Typography variant="h6" noWrap>
                            Peel Region Science Fair, {year}
                        </Typography>
                        <Typography variant="body2" className="truncateWrap" noWrap>
                            {greetings[greetingIndex]}, {props.name?.charAt(0).toUpperCase() + props.name?.slice(1).toLowerCase()}.
                        </Typography>
                    </Box>
                </Box>

                <div className="appbarcontent">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1,
                            gap: 0,
                            flexWrap: 'nowrap', height:"100%"
                        }}
                    >
                        <MockViewButtons
                            variant="contained"
                            color="brand"
                            userData={props.userData}
                            setUserData={props.setUserData}
                            horiz={true}

                            version={"topbar"}
                        />
                        {/*vertical divider thick*/}
                        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, margin:"4px", backgroundColor:"#424242" }}/>
                        <DashboardButton
                            variant="contained"
                            color="brand"
                            version={"topbar"}
                        />
                        <ReloadDataButton
                            variant="contained"
                            color="#fc7447"
                            setUserData={props.setUserData}
                            version={"topbar"}
                        />

                        <SignOutButton
                            variant="contained"
                            setUserData={props.setUserData}
                            version={"topbar"}
                        />
                    </Box>
                </div>
            </Toolbar>
        </AppBar>
    );
};
