import React from 'react';
import {
    Box, Button,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Grid2, Alert
} from "@mui/material";
import { useTheme } from "@mui/styles";
import GradingIcon from '@mui/icons-material/Grading';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { generateRubrics } from "../../api/api";
import TemplateListDialog from "../reusable/TemplateListDialog";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export const AssignJudgesCard = () => {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [error, setError] = React.useState("");

    const [helpOpen, setHelpOpen] = React.useState(false);
    const handleHelpOpen = () => setHelpOpen(true);
    const handleHelpClose = () => setHelpOpen(false);

    const [templateSelectorOpen, setTemplateSelectorOpen] = React.useState(false);

    const [config, setConfig] = React.useState({
        remove_from_checked_in: true,
        remove_from_checked_out: true,
        assign_to_checked_in: true,
        assign_to_checked_out: true,
        weighted: true,
        ratio: 2,
        max_rubrics_per_judge: 3,
        min_rubrics_per_judge: 1,
        override_ratio_limit: -1,
        override_template_id: -1,
    });


    const [overrideChecked, setOverrideChecked] = React.useState(
        config.override_ratio_limit !== -1
    );

    React.useEffect(() => {
        if (!overrideChecked) {
            setConfig((prev) => ({ ...prev, override_ratio_limit: -1 }));
        } else {
            setConfig((prev) => ({
                ...prev,
                override_ratio_limit:
                    prev.override_ratio_limit === -1 ? 0 : prev.override_ratio_limit
            }));
        }
    }, [overrideChecked]);

    const handleTemplateSelect = (templateID) => {
        setTemplateSelectorOpen(false);
        if (templateID !== null) {
            setConfig((prev) => ({
                ...prev,
                override_template_id: templateID,
            }));
        }
    };

    const handleOpenTemplateSelector = () => {
        setTemplateSelectorOpen(true);
    };

    const handleCheckboxChange = (key) => (event) => {
        setConfig((prev) => ({
            ...prev,
            [key]: event.target.checked,
        }));
    };

    const handleNumberChange = (key) => (event) => {
        const value = event.target.value;
        setConfig((prev) => ({
            ...prev,
            [key]: value === "" ? "" : parseInt(value, 10)
        }));
    };

    const handleOverrideRatioChange = (event) => {
        const rawValue = event.target.value;
        const parsed = rawValue === "" ? "" : parseInt(rawValue, 10);
        setConfig((prev) => ({
            ...prev,
            override_ratio_limit: isNaN(parsed) ? 0 : parsed
        }));
    };

    const runAssignment = async () => {
        setLoading(true);
        setCompleted(false);

        config.override_template_id = config.override_template_id === -1 ? null : config.override_template_id;
        config.override_ratio_limit = config.override_ratio_limit === -1 ? null : config.override_ratio_limit;

        try {
            const res = await generateRubrics(config);
            if (res === null) {
                setError("Failed to assign rubrics");
                setLoading(false);
                return;
            }
            setError("");
            setCompleted(true);
        } catch (e) {
            setError("Failed to assign rubrics");
        } finally {
            setLoading(false);
        }
    };

    const openRelativePage = (url) => {
        const relativeUrl = `${window.location.origin}/${url}`;
        window.open(relativeUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <Card sx={{ backgroundColor: theme.palette.lightGreyLight, padding: 2, position: 'relative' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <GradingIcon
                        sx={{
                            color: '#1aa921',
                        }}
                    />
                    <Typography sx={{ marginLeft: '10px' }} variant="h5">
                        Assign Rubrics to Judges
                    </Typography>
                </Box>
                <IconButton onClick={handleHelpOpen}>
                    <HelpOutlineIcon />
                </IconButton>
            </Box>

            <CardContent>
                <Typography variant="body2">
                    Runs an extremely fancy algorithm developed by NASA top engineers to assign rubrics to judges.
                    <br />
                    <br />
                    This is a REQUIRED ACTION BEFORE STARTING THE FAIR.
                </Typography>
                <br />

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.remove_from_checked_in}
                                    onChange={handleCheckboxChange("remove_from_checked_in")}
                                />
                            }
                            label="Remove from checked in"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.remove_from_checked_out}
                                    onChange={handleCheckboxChange("remove_from_checked_out")}
                                />
                            }
                            label="Remove from checked out"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.assign_to_checked_in}
                                    onChange={handleCheckboxChange("assign_to_checked_in")}
                                />
                            }
                            label="Assign to checked in"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.assign_to_checked_out}
                                    onChange={handleCheckboxChange("assign_to_checked_out")}
                                />
                            }
                            label="Assign to checked out"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.weighted}
                                    onChange={handleCheckboxChange("weighted")}
                                />
                            }
                            label="Weighted"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1}}>
                        <TextField
                            label="Ratio"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={config.ratio}
                            disabled={overrideChecked}
                            onChange={handleNumberChange("ratio")}
                            sx={{ width: 150, margin:"10px" }}
                        />
                        <TextField
                            label="Override ratio limit"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={
                                config.override_ratio_limit === -1
                                    ? ""
                                    : config.override_ratio_limit
                            }
                            onChange={handleOverrideRatioChange}
                            sx={{ width: 150, margin:"10px" }}

                            disabled={!overrideChecked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={overrideChecked}
                                    onChange={(e) => setOverrideChecked(e.target.checked)}
                                />
                            }
                            label="Override?"
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1}}>
                        <TextField
                            label="Max rubrics per judge"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={config.max_rubrics_per_judge}
                            onChange={handleNumberChange("max_rubrics_per_judge")}
                            sx={{ width: 200, marginRight:"10px", marginBottom:"5px" }}
                        />

                        <TextField
                            label="Min rubrics per judge"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={config.min_rubrics_per_judge}
                            onChange={handleNumberChange("min_rubrics_per_judge")}
                            sx={{ width: 200 }}
                        />
                    </Box>

                    <Box sx={{ backgroundColor: theme.palette.lightGreyDark, p: 2, borderRadius: 1, mb: 1 }}>
                        <Grid2 xs={12} sm={12}>
                            <TextField
                                label="Override Template ID"
                                type="number"
                                inputProps={{ min: -1 }}
                                value={config.override_template_id}
                                onChange={handleNumberChange("override_template_id")}
                                disabled={true}
                                sx={{ width: 150 }}
                            />
                            <Box sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenTemplateSelector}
                                    fullWidth
                                >
                                    Select Template
                                </Button>
                            </Box>
                        </Grid2>
                    </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                    <Typography sx={{ color: "#d93434" }}>{error}</Typography>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <CircularProgress size={24} sx={{ color: theme.palette.standardBlue }} />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
                            <Button
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    backgroundColor: theme.palette.standardBlue,
                                    width: "100%",
                                    color:"#FFFFFF",
                                    '&:hover': {
                                        backgroundColor: theme.palette.standardBlueSelect,
                                    },
                                }}
                                onClick={runAssignment}
                            >
                                Assign Rubrics
                            </Button>
                            {completed && (
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    sx={{
                                        backgroundColor: theme.palette.good,
                                        width: "100%",
                                        '&:hover': {
                                            backgroundColor: theme.palette.goodDark,
                                        },
                                    }}
                                    onClick={() => openRelativePage("auth/admin/rubrics")}
                                >
                                    See Assignments
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            </CardContent>

            <Dialog open={helpOpen} onClose={handleHelpClose}>
                <DialogTitle>Help - Assign Rubrics to Judges</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        This form allows you to configure how rubrics are assigned to judges.
                        Click <strong>"Assign Rubrics"</strong> to start the assignment process.
                    </Typography>

                    <Alert sx={{margin:"15px"}} icon={<TipsAndUpdatesIcon fontSize="inherit"/>}
                           severity="info">
                        The following two options are useful for reassigning rubrics from judges who didn't show up, to
                        ones who did...
                    </Alert>
                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Remove from checked in/out</strong>
                            </Typography>
                            <Typography variant="body2">
                                Unassign existing rubrics from judges that are
                                or are not checked in. I.e if a judge is checked in with a rubric and "Remove from checked in" is
                                selected, their rubric will be deleted.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Assign to checked in/out</strong>
                            </Typography>
                            <Typography variant="body2">
                                Assign new rubrics to judges that are or
                                are not checked in. I.e if a judge is not checked in, but Assign to checked out is selected, the
                                judge will still be a candidate to receive rubrics.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography>If you would like to remove rubrics from judges who didn't show up, and give them to
                        those who did:<br/><br/>
                        <strong>enable</strong> <i>Remove from checked
                            out</i> and <i>Add to checked in</i><br/> <strong>disable</strong> <i>Remove from checked
                            in</i> and <i>Add to checked out</i>
                    </Typography>
                    <Alert sx={{margin:"15px"}} icon={<TipsAndUpdatesIcon fontSize="inherit"/>}
                           severity="info">
                        The following options control how many rubrics are assigned to each judge...
                    </Alert>

                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Max rubrics per judge</strong>
                            </Typography>
                            <Typography variant="body2">
                                The maximum number of rubrics that can be assigned to a judge. This is a hard cap,
                                and even the Override Ratio Limit cannot exceed this value.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Min rubrics per judge</strong>
                            </Typography>
                            <Typography variant="body2">
                                The minimum number of rubrics that must be assigned to a judge. This is a not hard cap as
                                 if there aren't enough projects, ratio will be prioritized over this minimum.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Ratio</strong>
                            </Typography>
                            <Typography variant="body2">
                                The ratio of rubrics per judge. This is an average calculated by:
                                <br/>
                                    <code>
                                        <strong>RubricsPerJudge = ceil((#Projects/#Judges) * ratio)</strong>
                                    </code>
                                <br/>

                                The algorithm will always target this number of rubrics per judge, but may not always
                                achieve it due to the constraints of the other options or some odd number of projects.

                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{margin:"5px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Override Ratio Limit</strong>
                            </Typography>
                            <Typography variant="body2">
                                As stated earlier, the ratio is used in the following equation:
                                <br/>
                                <br/>
                                <code>
                                    <strong>RubricsPerJudge = ceil((#Projects/#Judges) * ratio)</strong>
                                </code>
                                <br/>
                                <br/>
                                But setting the override will always target this number of rubrics per judge, regardless
                                of
                                other constraints (excluding hard caps).<br/><br/>
                                Essentially, the equation becomes:
                                <br/>
                                <br/>
                                <code>
                                    <strong>RubricsPerJudge = min(MaxRubrics, max(MinRubrics,override))</strong>
                                </code>
                                <br/>
                                <br/>

                            </Typography>
                        </CardContent>
                    </Card>

                    <Alert sx={{margin:"15px"}} icon={<TipsAndUpdatesIcon fontSize="inherit"/>}
                           severity="info">
                        The following options are miscellaneous options that control additional behavior which you may want...
                    </Alert>

                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Weighted</strong>
                            </Typography>
                            <Typography variant="body2">
                                Judges are assigned rubrics in accordance with how closely their specialization matches
                                the project description. A point system is used to assign judges points, where the judge
                                with the most points wins the project assignment. <strong>Using a weighted point system is the
                                default</strong> as it takes into consideration of a subject's prominence project's description. I.e
                                if a project is 75% physics and 25% chemistry, a judge with a physics specialization will
                                be more likely to be assigned the project. But with this option disabled, the algorithm will
                                give both a physics specialist and a chemistry specialist an equal chance of being assigned
                                the project.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{margin:"15px", backgroundColor:theme.palette.lightGrey}}>
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Override Template ID</strong>
                            </Typography>
                            <Typography variant="body2">
                                By default, your region's <strong>default rubric template</strong> will be instanced to every
                                assignment. However, if you would like to use a different template, you can specify the
                                template ID here. This will override the default template for all assignments.
                                <br/><br/>
                                This could be useful for A/B testing different rubric structures.
                            </Typography>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHelpClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <TemplateListDialog
                open={templateSelectorOpen}
                onClose={handleTemplateSelect}
            />
        </Card>
    );
};
