import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    CircularProgress
} from "@mui/material";

import {useTheme} from "@mui/styles";
import {deleteDatabase} from "../../api/api";
import DeleteIcon from '@mui/icons-material/Delete';

export const NukeDBCard = () => {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(0);

    const [text, setText] = useState("Destroy");

    // Handler for the Create button
    const handleDelete = async () => {
        if (confirm === 0) {
            setConfirm(1);
            setText("Are you sure?")
            return;
        }
        if (confirm === 1) {
            setConfirm(2);
            setText("Are you REALLY sure?");
            return;
        }
        if (confirm === 2) {
            setConfirm(3);
            setText(":( Really?");
            return;
        }

        setLoading(true);
        const res = await deleteDatabase();
        if (res === null) {
            setText("Error occurred while deleting");
        } else {
            setText("Destroyed");
        }
        setLoading(false);
        setConfirm(0);
    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight, padding: 2, position: 'relative'}}>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <DeleteIcon
                    sx={{
                        color: '#e10e0e',

                    }}
                />
                <Typography sx={{marginLeft: '10px'}} variant="h5">
                    Reset Database
                </Typography>
            </Box>


            {/* Content Section */}
            <CardContent>
                <Typography variant="body2">
                    Reset Fair data to default state. Keeps admin accounts and templates, deletes everything else.
                    <br/><br/>
                    If you somehow accidentally click this, you can restore the database from a backup.
                </Typography>
                <br/>


                <Box sx={{width: "100%"}}>

                    {
                        (loading) ?

                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <CircularProgress size={24} sx={{color: "red"}}/>
                            </Box>

                            :

                            <Box sx={{display: 'flex', flexDirection: 'row', gap: 2}}>

                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    sx={{
                                        backgroundColor: "red",
                                        width: "100%",
                                        '&:hover': {
                                            backgroundColor: "#af1010",
                                        },
                                    }}
                                    onClick={handleDelete}
                                >
                                    {text}
                                </Button>
                                {(confirm === 3)?
                                    <Button
                                    variant="contained"
                                    disabled={loading}
                                    sx={{
                                        backgroundColor: "#1e5be5",
                                        width: "100%",
                                        '&:hover': {
                                            backgroundColor: "#0e4596",
                                        },
                                    }}
                                    onClick={()=> {
                                        setConfirm(0);
                                        setText("Destroy");
                                    }}
                                >
                                    No, I'm Sorry
                                </Button>
                                    : ""}
                            </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}
