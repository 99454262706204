import React, {useState, useEffect, useMemo} from 'react';
import {
    Box,
    Button,
    IconButton,
    Tabs,
    Tab,
    TextField,
    Typography,
    CircularProgress,
    Tooltip,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import {Add, Edit, Delete, Refresh} from '@mui/icons-material';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from "react-router-dom";
import {
    deleteProject,
    deletePerson,
    createJudge,
    createStudent, fetchJudges, fetchStudents, fetchProjects, createProject, updateJudge, updateProject, updateStudent
} from "../../api/api";
import DefaultPageContainer from "../reusable/DefaultPageContainer";
import JudgeAddDialog from "../reusable/JudgeAddDialog";
import StudentAddDialog from "../reusable/StudentAddDialog";
import ProjectAddDialog from "../reusable/ProjectAddDialog";
import {JudgeEditDialog, ProjectEditDialog, StudentEditDialog} from "../reusable/EditDialog";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {TagGenerationDialog} from "../reusable/TagGenerationDialog";

const blacklistColumns = ['type', 'region', 'keywords', 'reg_exp', 'nat_exp'];
const mobileWhitelistColumns = ['id', 'actions', 'last_name', 'email', 'title', 'students'];

const RegistrationManagement = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState({judges: [], projects: [], students: []});
    const [loading, setLoading] = useState({judges: true, projects: true, students: true});
    const [search, setSearch] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [judgeDialogOpen, setJudgeDialogOpen] = useState(false);
    const [studentDialogOpen, setStudentDialogOpen] = useState(false);
    const [projectDialogOpen, setProjectDialogOpen] = useState(false);

    const [judgeEditDialogOpen, setJudgeEditDialogOpen] = useState(false);
    const [studentEditDialogOpen, setStudentEditDialogOpen] = useState(false);
    const [projectEditDialogOpen, setProjectEditDialogOpen] = useState(false);

    const [tagGenerationDialogOpen, setTagGenerationDialogOpen] = useState(false);
    const [tagGenerationInfo, setTagGenerationInfo] = useState(null);

    const [editingObject, setEditingObject] = useState({type: '', id: null});

    const [deleteInfo, setDeleteInfo] = useState({type: '', id: null});

    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    // Handle Search Input Change
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    // Handle Add Button Click
    const handleAdd = (type) => {
        if (type === 'judges') {
            setJudgeDialogOpen(true);
        }
        if (type === 'students') {
            setStudentDialogOpen(true);
        }
        if (type === 'projects') {
            setProjectDialogOpen(true);
        }
    };

    // Handle Edit Button Click
    const handleEdit = (type, id) => {
        setEditingObject(data[type].find((item) => item.id === id));
        if (type === 'judges') {
            setJudgeEditDialogOpen(true);
        }
        if (type === 'students') {
            setStudentEditDialogOpen(true);
        }
        if (type === 'projects') {
            setProjectEditDialogOpen(true);
        }
    };

    const openDeleteDialog = (type, id) => {
        setDeleteInfo({type, id});
        setDialogOpen(true);
    };

    const confirmDelete = async () => {
        const {type, id} = deleteInfo;
        if ((type === "students") || (type === "judges")) {
            const res = await deletePerson(id);
            if (res !== true) return;
        }
        if (type === "projects") {
            const res = await deleteProject(id);
            if (res !== true) return;
        }
        setData((prevData) => ({
            ...prevData,
            [type]: prevData[type].filter((item) => item.id !== id),
        }));
        setDialogOpen(false);
    };

    const handleDelete = (type, id) => {
        openDeleteDialog(type, id);
    };

    const handleTagGeneration = (type, id) => {
        setTagGenerationInfo(data[type].find((item) => item.id === id));
        if (data[type].find((item) => item.id === id) === null) {
            console.error("Project not found for tag generation");
            return;
        }
        setTagGenerationDialogOpen(true);
    }

    const handleRowDoubleClick = (params) => {
        const {id, row} = params;
        const type = tabIndex === 0 ? 'judges' : tabIndex === 1 ? 'students' : 'projects';
        handleEdit(type, id);
    };

    // Filter Data Based on Search Input
    const filteredData = (type) => {
        return data[type].filter((item) => {
                let var1 = Object.values(item).some((val) =>
                    String(val).toLowerCase().includes(search.toLowerCase())
                )
                // also check if first name and last name are in the search
                let var2 = (item.first_name + " " + item.last_name).toLowerCase().includes(search.toLowerCase())
                return var1 || var2
            }
        );
    };

    const getJudges = () => {
        setLoading((prev) => ({...prev, judges: true}));
        fetchJudges(-1, 0).then((response) => {
            response.forEach((judge) => {
                judge.checked_in = judge.checked_in === 1 ? "Yes" : "No";
                judge.admin_generated = judge.admin_generated === 1 ? "Yes" : "No";
            });
            setData((prevData) => ({...prevData, judges: response}));
            setLoading((prev) => ({...prev, judges: false}));
        }).catch((error) => {
            setData((prevData) => ({...prevData, judges: []}));
            setLoading((prev) => ({...prev, judges: false}));
        });

    }

    const getStudents = () => {
        setLoading((prev) => ({...prev, students: true}));
        fetchStudents(-1, 0).then((response) => {
            response.forEach((student) => {
                student.checked_in = student.checked_in === 1 ? "Yes" : "No";
            });

            setData((prevData) => ({...prevData, students: response}));
            setLoading((prev) => ({...prev, students: false}));
        }).catch((error) => {
            setData((prevData) => ({...prevData, students: []}));
            setLoading((prev) => ({...prev, students: false}));
        });
    }

    const getProjects = () => {
        setLoading((prev) => ({...prev, projects: true}));
        fetchProjects(-1, 0).then((response) => {
            setData((prevData) => ({...prevData, projects: response}));
            setLoading((prev) => ({...prev, projects: false}));
        }).catch((error) => {
            setData((prevData) => ({...prevData, projects: []}));

            setLoading((prev) => ({...prev, projects: false}));
        });
    }

    const reloadAllData = async () => {
        await setData({judges: [], projects: [], students: []});
        // Fetch Judges
        getJudges();

        // Fetch Students
        getStudents();

        // Fetch Projects
        getProjects();
    }

    // Fetch data using useEffect
    useEffect(() => {
        reloadAllData();
    }, []);

    // Column Overrides for Custom Rendering and Header Names
    const columnOverrides = {
        id: {headerName: 'ID'},
        first_name: {headerName: 'First Name'},
        last_name: {headerName: 'Last Name'},
        email: {headerName: 'Email'},
        highest_education_obtained: {headerName: 'Education'},
        checked_in: {
            headerName: "Checked In",
            renderCell: (params) => (
                <Tooltip title={params.value || ''}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        project_id: {headerName: "Project ID"},
        students: {
            headerName: 'Students',
            renderCell: (params) => {
                if (Array.isArray(params.value) && params.value.length > 0) {
                    return (
                        <Box
                            sx={{
                                maxHeight: '80px',
                                overflowY: 'auto',
                                pr: 1,
                            }}
                        >
                            {params.value.map((student) => (
                                <Box key={student.id} sx={{mb: 1}}>
                                    <Typography variant="body2" component="div">
                                        <strong>{student.first_name} {student.last_name}</strong> (ID: {student.id})
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    );
                }
                return <Typography variant="body2">No Students</Typography>;
            }
        },
        description: {
            headerName: 'Description',
            renderCell: (params) => (
                <Tooltip title={params.value || ''}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
    };

    // Function to get the initial column visibility model
    const getInitialColumnVisibilityModel = (columns) => {
        const model = {};
        columns.forEach((col) => {
            if (isSmallScreen && !mobileWhitelistColumns.includes(col.field)) {
                model[col.field] = false; // hidden
            } else {
                model[col.field] = true; // visible
            }
        });
        return model;
    };

    // Function to Dynamically Generate Columns
    const getColumns = (type) => {
        if (data[type].length === 0) return [];

        const dataFields = Object.keys(data[type][0]).filter(
            (key) => !blacklistColumns.includes(key)
        );

        // Calculate maximum content length for each field
        const maxContentLengths = {};
        dataFields.forEach((field) => {
            let maxLength = (columnOverrides[field]?.headerName || field).length; // Start with header name length
            data[type].forEach((row) => {
                const value = row[field] !== null && row[field] !== undefined ? String(row[field]) : '';
                if (value.length > maxLength) {
                    maxLength = value.length;
                }
            });
            maxContentLengths[field] = maxLength;
        });

        const columns = dataFields
            .sort((a, b) => a === 'id' ? -1 : b === 'id' ? 1 : 0)
            .map((field) => {
                if (columnOverrides[field]?.hide) return null;

                const headerName =
                    columnOverrides[field]?.headerName ||
                    field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ');

                // Define maximum width
                const maxWidth = 300;
                const minWidth = 80;
                const calculatedWidth = Math.min(
                    Math.max(maxContentLengths[field] * 8 + 32, minWidth),
                    maxWidth
                );

                return {
                    field,
                    headerName,
                    width: calculatedWidth + 10,
                    minWidth: minWidth,
                    maxWidth: maxWidth,
                    renderCell: columnOverrides[field]?.renderCell || ((params) => (
                        <Tooltip title={params.value || ''}>
                            <span>{params.value}</span>
                        </Tooltip>
                    )),
                };
            })
            .filter(Boolean);

        // Add the actions column with fixed width
        columns.push({
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <>
                    <Tooltip title={"Edit"}>
                        <IconButton onClick={() => handleEdit(type, params.id)}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={"Delete"}>
                        <IconButton onClick={() => handleDelete(type, params.id)}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                    {(type === "projects") ?
                        <Tooltip title={"Generate Tags (AI)"}>
                            <IconButton onClick={() => handleTagGeneration(type, params.id)}>
                                <AutoFixHighIcon/>
                            </IconButton>
                        </Tooltip>
                        :
                        ""}
                </>
            ),
        });

        return columns;
    };

    // Memoized versions of columns and initialState
    const judgesColumns = useMemo(() => getColumns('judges'), [data.judges, isSmallScreen]);
    const studentsColumns = useMemo(() => getColumns('students'), [data.students, isSmallScreen]);
    const projectsColumns = useMemo(() => getColumns('projects'), [data.projects, isSmallScreen]);

    // Memoized initialState for each type
    const judgesInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(judgesColumns),
        },
    }), [isSmallScreen, judgesColumns]);

    const studentsInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(studentsColumns),
        },
    }), [isSmallScreen, studentsColumns]);

    const projectsInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(projectsColumns),
        },
    }), [isSmallScreen, projectsColumns]);

    // Adjust DataGrid styles
    const dataGridStyles = {
        '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'center',
        },
        '& .MuiDataGrid-columnHeader': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    const confirmAddJudge = async (data) => {
        let dict = {
            highest_education_obtained: data.highestEducation,
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            reg_exp: 0,
            nat_exp: 0,
            strengths: data.strengths,
            avoid: data.avoid,
            region: null,
            id: null,
            type: 'judge'
        }
        const res = await createJudge(dict);
        if (res !== true) return;
        setData((prevData) => ({
            ...prevData,
            judges: [...prevData.judges, res],
        }));
        getJudges();
    }

    const confirmAddStudent = async (data) => {
        let dict = {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            age_group: data.ageGroup,
            project_id: data.projectId,
            checked_in: 0,
            id: null,
            type: 'student',
            region: null
        }
        const res = await createStudent(dict);
        if (res !== true) return;
        getStudents();
    }

    const confirmAddProject = async (data) => {
        let dict = {
            title: data.title,
            description: data.description,
            challenge: data.challenge,
            number: -1,
            tags: "",
            id: null,
            type: 'project',
        }
        const res = await createProject(dict);
        if (res !== true) return;
        getProjects();
    }

    const confirmEditProject = async (data) => {
        let out = {}
        out.title = data.title
        out.description = data.description
        out.challenge = data.challenge
        out.type = data.type
        out.tags = data.tags

        const res = await updateProject(out, data.id);
        if (res === null) return;
        getProjects();

    }

    const confirmEditStudent = async (data) => {
        let out = {}
        out.first_name = data.first_name
        out.last_name = data.last_name
        out.email = data.email
        out.age_group = data.age_group
        out.project_id = data.project_id
        out.checked_in = (data.checked_in === "No") ? 0 : 1

        if (out.age_group === "") {
            out.age_group = "?"
        }

        let id = data.id;

        const res = await updateStudent(out, id);
        if (res === null) return;
        getStudents();
    }

    const confirmEditJudge = async (data) => {
        let out = {}
        out.first_name = data.first_name
        out.last_name = data.last_name
        out.email = data.email
        if (data.highest_education_obtained === "Doctorate") {
            out.highest_education_obtained = "doctorate"
        } else if (data.highest_education_obtained === "Masters") {
            out.highest_education_obtained = "master"
        } else if (data.highest_education_obtained === "Bachelors") {
            out.highest_education_obtained = "bachelor"
        }

        out.strengths = data.strengths;
        out.avoid = data.avoid;
        out.checked_in = (data.checked_in === "No") ? 0 : 1;

        let id = data.id;


        const res = await updateJudge(out, id);
        if (res === null) return;


        getJudges();
    }

    // Renders a data table for a given type
    const renderTable = (type, columns, initialState) => (
        <Box sx={{flex: '1 1 auto', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{marginTop: "10px", marginBottom: "10px", marginLeft: "10px"}}>
                <TextField label="Search" variant="outlined" value={search} onChange={handleSearchChange}/>
                <Button sx={{marginTop: "10px", marginLeft: "5px"}} onClick={() => {
                    setSearch("")
                }}>Clear</Button>
            </Box>
            <Box sx={{marginTop: "10px", marginBottom: "10px", marginLeft: "10px"}}>
                <Button
                    sx={{marginRight: "10px"}}
                    variant="contained"
                    startIcon={<Add/>}
                    onClick={() => handleAdd(type)}
                >
                    Add {type.charAt(0).toUpperCase() + type.slice(1, type.length - 1)}
                </Button>
                <Button
                    variant="contained"
                    sx={{backgroundColor: "#4fa23f"}}
                    startIcon={<Add/>}
                    onClick={() => navigate("bulk-add")}
                >
                    Bulk Add
                </Button>
                <Tooltip title={"Refresh Data"}>
                    <IconButton sx={{marginLeft: "5px"}} onClick={() => {
                        if (tabIndex === 0) {
                            getJudges();
                        }
                        if (tabIndex === 1) {
                            getStudents();
                        }
                        if (tabIndex === 2) {
                            getProjects();
                        }
                    }}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={{flex: '1 1 auto', width: '100%', pb: 3, overflowX: 'auto'}}>
                {loading[type] ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <Box sx={{}}>
                        <DataGrid
                            key={isSmallScreen ? 'small' : 'large'} // Force re-mount when screen size changes
                            rows={filteredData(type)}
                            columns={columns}
                            initialState={initialState}
                            pageSizeOptions={[10, 20, 50]}
                            sortingOrder={['asc', 'desc']}
                            rowHeight={60}
                            sx={dataGridStyles}
                            onRowDoubleClick={handleRowDoubleClick}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );

    // get queries, look for startTab (judge, student, project) and query (search)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const startTab = urlParams.get('startTab');
        let query = urlParams.get('query');
        if (startTab === 'judge') {
            setTabIndex(0);
        }
        if (startTab === 'student') {
            setTabIndex(1);
        }
        if (startTab === 'project') {
            setTabIndex(2);
        }
        if (query !== null) {
            // decode the query
            query = decodeURIComponent(query);
            setSearch(query);
        }
    }, []);


    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <div>
                {isSmallScreen ? (
                    <Typography sx={{paddingLeft: "10px", paddingTop: "10px"}} variant="h6">Registration
                        Management</Typography>
                ) : (
                    <Typography variant="h4">Registration Management</Typography>
                )}
                <Typography variant={(isSmallScreen) ? "body3" : "body1"}
                            sx={{fontSize: "15px", paddingLeft: (isSmallScreen) ? "10px" : "0", paddingTop: "10px"}}>
                    Add, remove, modify People and Projects
                </Typography>


            </div>

            <Box
                sx={{
                    flex: '1 1 auto',
                    margin: "2 2 2 5",
                    padding: '0 15px 10px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto'
                }}
            >
                <Tabs value={tabIndex} onChange={handleTabChange}
                      TabIndicatorProps={{
                          style: {
                              display: 'none',
                          },
                      }}
                >
                    <Tab label="Judges"/>
                    <Tab label="Students"/>
                    <Tab label="Projects"/>
                </Tabs>
                {tabIndex === 0 && renderTable('judges', judgesColumns, judgesInitialState)}
                {tabIndex === 1 && renderTable('students', studentsColumns, studentsInitialState)}
                {tabIndex === 2 && renderTable('projects', projectsColumns, projectsInitialState)}
            </Box>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this entry?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <JudgeAddDialog open={judgeDialogOpen} onClose={() => {
                setJudgeDialogOpen(false)
            }} onSubmit={confirmAddJudge}/>
            <StudentAddDialog open={studentDialogOpen} onClose={() => {
                setStudentDialogOpen(false)
            }} onSubmit={confirmAddStudent}/>
            <ProjectAddDialog
                open={projectDialogOpen}
                onClose={() => setProjectDialogOpen(false)}
                onSubmit={confirmAddProject}
            />

            <JudgeEditDialog
                open={judgeEditDialogOpen}
                onClose={() => setJudgeEditDialogOpen(false)}
                onSubmit={confirmEditJudge}
                initialValues={editingObject}
            />
            <StudentEditDialog
                open={studentEditDialogOpen}
                onClose={() => setStudentEditDialogOpen(false)}
                onSubmit={confirmEditStudent}
                initialValues={editingObject}
            />
            <ProjectEditDialog
                open={projectEditDialogOpen}
                onClose={() => setProjectEditDialogOpen(false)}
                onSubmit={confirmEditProject}
                initialValues={editingObject}
            />
            <TagGenerationDialog
                open={tagGenerationDialogOpen}
                onClose={() => setTagGenerationDialogOpen(false)}
                project={tagGenerationInfo}
            />
        </DefaultPageContainer>
    );
};

export default RegistrationManagement;
