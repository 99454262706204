import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {AuthProvider} from './context/AuthContext';
import App from './App';
import {ErrorProvider} from "./context/ErrorContext";
import ScaleApp from "./components/reusable/scaleapp";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
    <AuthProvider>
        <ErrorProvider>
            <ScaleApp>
                <App/>
            </ScaleApp>
        </ErrorProvider>
    </AuthProvider>
    // </React.StrictMode>
);