import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    TextField,
    Typography,
} from '@mui/material';
import {fetchTemplates} from "../../api/api";
import {useTheme} from "@mui/styles";

const TemplateListDialog = (props) => {
    const [templates, setTemplates] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const theme = useTheme();
    useEffect(() => {
        if (props.open) { // Optional: Fetch only when dialog is open
            fetchTemplates().then((response) => {
                if (response === null || response === undefined) {
                    console.error("Could not fetch template data...");
                    return;
                }
                setTemplates(response);
            }).catch((error) => {
                console.error("Error fetching templates:", error);
            });
        }
    }, [props.open]); // Re-fetch if 'open' changes

    const filteredTemplates = templates.filter((template) => {
        const name = template.name.toLowerCase();
        const query = searchQuery.toLowerCase();
        return name.includes(query) || template.id.toString().includes(query);
    });

    return (
        <Dialog
            sx={{minWidth: "300px"}}
            open={props.open}
            onClose={() => props.onClose(null)}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Select a Template</DialogTitle>
            <DialogContent dividers>

                <TextField
                    label="Search Templates"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by name or ID"
                />

                {filteredTemplates.length > 0 ? (
                    <List>
                        {filteredTemplates.map((template) => (
                            <ListItem key={template.id} disablePadding>
                                <ListItemButton
                                    sx={{
                                        background: (template.is_default) ? "#1565c0" : theme.palette.lightGreyLight,
                                        borderWidth: "1px",
                                        borderColor: "#000000",
                                        color: template.is_default ? "white" : "black",
                                        padding: "10px",
                                        marginBottom: "5px",
                                        borderRadius: "4px",

                                        '&:hover': {
                                            backgroundColor: (template.is_default) ? "#0d47a1" : theme.palette.lightGreyDark,
                                        },
                                    }}
                                    onClick={() => props.onClose(template.id)}
                                >
                                    <Typography variant="body1">
                                        {template.name} {"<"}ID: {template.id}{">"}{" "}
                                        {(template.is_default) ? "(default)" : ""}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No templates found.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateListDialog;
