import axios from './axios';

export const get_regions = async () => {
  try {
    const response = await axios.get('/valid_regions');

    // Capitalize the regions
    response.data.res = response.data.res.map(region => region.charAt(0).toUpperCase() + region.slice(1));
    return response.data.res;
  } catch (error) {
    console.error("Error loading regions")
      return null
  }
};


export const getUserData = async () => {
  try {
    const response = await axios.get('/user/me');
    return response.data;
  } catch (error) {
    console.error("Error loading user data: " + error)
    return null;
  }
};

export const getPersonData = async (person_id) => {
  try {
    const response = await axios.get('/person/get/' + person_id);
    return response.data;
  } catch (error) {
    console.error("Error loading person data")
    return null;
  }
};

export const getAdminData = async () => {
  let out = {}

  try {
    let response = await axios.get('/rubric/templates');
    // append to out
    out["templates"] = response.data["templates"]

    response = await axios.get('/student/count');
    out["studentCount"] = response.data.count

    response = await axios.get('/judge/count');
    out["judgeCount"] = response.data.count

    response = await axios.get('/project/count');
    out["projectCount"] = response.data.count

    return out;
  } catch (error) {
    console.error("Error loading admin data")
    console.error(error)
    return null;
  }
};

export const getTeamMembers = async (project_id) => {
    try {
        const response = await axios.get('/team/project/' + project_id);
        return response.data;
    } catch (error) {
      console.error("Error loading team members")
      return null;
    }
}

export const getJudgeRubricData = async (person_id) => {
  try {
    const response = await axios.get('/rubric/judge/' + person_id);
    let rubric_data = response.data;
    if(rubric_data === null){
      console.error("Error loading rubric_judge_view data")
      return null;
    }
    for (let i = 0; i < rubric_data.rubrics.length; i++){
      const team = await getTeamMembers(rubric_data.rubrics[i].project_id);
      if(team === null)
        return null;
      rubric_data.rubrics[i].team_members = team;
    }
    return rubric_data;
  } catch (error) {
    console.error("Error loading judge rubric_judge_view data")
    return null;
  }
};


export const loadAdminData = async (userData, setUserData) => {
  const adminData = await getAdminData()
  if (adminData !== null) {
    userData.adminData = adminData;
    setUserData(userData)
  }
  else {
    console.error("Error loading admin data to state")
  }
}

export const loadUserDataComplete = async(setUserData) => {
    if(setUserData === undefined)
        return null;

  const userData = await getUserData();

  if(userData !== null){

    if (userData.person_id !== null){
      const personData = await getPersonData(userData.person_id);
      if(personData !== null){
        userData.personData = personData;
        if(userData.personData.type === "judge" && userData.is_admin === 0){
          const rubricData = await getJudgeRubricData(userData.person_id);
          if(rubricData !== null)
            userData.rubricData = rubricData
          else{
            userData.rubricData = null;
          }
        }

      }
      else {
        userData.personData = null;
        userData.rubricData = null;
        userData.adminData = null;
      }
      if(userData.is_admin > 0){
        const adminData = await getAdminData();
        if(adminData !== null) {
            userData.adminData = adminData
        }
        else{
            userData.adminData = null;
        }
      }
    }
    else {
      userData.personData = null;
      userData.rubricData = null;
      userData.adminData = null;
    }
      if(userData.is_admin > 0){
          const adminData = await getAdminData();
          if(adminData !== null) {
              userData.adminData = adminData
          }
          else{
              userData.adminData = null;
          }
      }
  }
  if(userData === null){
    console.error('Severe error attempting to load user data');
    await logout(setUserData)
    return null;

    // navigate('/login', { state: { message: 'Severe error attempting to load user data', severity: 'error' } });
  }

  setUserData(userData);
}

export const updateRubric = async (public_feedback, private_feedback, recommend, rubric_id, is_valid = true, update_completed_status = true) => {
    try {
        const response = await axios.post('/rubric/update/by_id/' + rubric_id, {
          pub_feed: public_feedback,
          priv_feed: private_feedback,
          recommended: recommend,
          completed: (is_valid? 1 : 0),
          update_completed_status: (update_completed_status? 1: 0)
        });
        return response.data;
    } catch (error) {
        console.error("Error updating rubric_judge_view")
        return null;
    }
}

export const massSetCategories = async (rubric_id, data_arr) => {
  try {
    const response = await axios.post('/rubric/' + rubric_id + '/score/mass_update', {
      rubric_id: rubric_id,
      scores: data_arr,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating rubric_judge_view")
    return null;
  }
}

export const activateRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.post('/rubric/template/activate/' + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error activating rubric_judge_view template")
        return null;
    }
}

export const deactivateRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.post('/rubric/template/deactivate/' + rubric_id);
    return response.data;
  }
  catch (error) {
    console.error("Error activating rubric_judge_view template")
    return null;
  }
}

export const setRubricDefault = async (rubric_id) => {
  try {
    const response = await axios.post("/rubric/template/set_default/" + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error setting rubric_judge_view as default")
        return null;
    }
}

export const deleteRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.delete("/rubric/template/delete/" + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error deleting rubric_judge_view template")
        return null;
    }
}


export const getTemplateDetails = async (template_id, hook = null) => {
  try {
    const response = await axios.get('/rubric/template/get/' + template_id);
    if (hook !== null) {
      hook(response.data);
    }
    return response.data;
  }
    catch (error) {
        console.error("Error loading template details")
        return null;
    }
}

export const createRubricTemplateCategory = async (rubric_id, name) => {
    try {
        const response = await axios.post('/rubric/template/get/' + rubric_id + '/add_category', {
            category_name: name,
            parent_category_id: null,
            template_id: rubric_id,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category")
        return null;
    }
}

export const createRubricTemplateCategoryFull = async (rubric_id, parent_cat_id, name, max_score, weight, description = null) => {
    if (description === undefined || description === null) {
        description = "";
    }
    try {
        const response = await axios.post('/rubric/template/get/' + rubric_id + '/add_category', {
            category_name: name,
            category_description: description,
            parent_category_id: parent_cat_id,
            template_id: rubric_id,
            max_score: max_score,
            weight: weight,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category: " + error)
        console.error({
            category_name: name,
            category_description: description,
            parent_category_id: parent_cat_id,
            template_id: rubric_id,
            max_score: max_score,
            weight: weight,
        })
        return null;
    }
}

export const deleteRubricTemplateCategory = async (category_id, rubric_id) => {
    try {
        const response = await axios.delete('/rubric/template/category/' + rubric_id + '/delete/' + category_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting category")
        return null;
    }
}

export const updateRubricTemplateCategory = async (category_id, rubric_id, changes) => {
    try {
        const response = await axios.post('/rubric/template/category/' + rubric_id + '/update/' + category_id, changes);
        return response.data;
    }
    catch (error) {
        console.error("Error updating category")
        return null;
    }
}

export const createRubricTemplate = async (name, desc) => {
    try {
        const response = await axios.post('/rubric/template/create', {
            name: name,
            description: desc,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category")
        return null;
    }
}

export const parseData = async (data) => {
    try {
        const response = await axios.post('/parser/parse_data', data);
        return response.data;
    }
    catch (error) {
        console.error("Error parsing data")
        return null;
    }
}

export const fetchStudents = async(max, skip) => {
    try {
        const response = await axios.get('/students/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all students")
        return null;
    }
}

export const fetchJudges = async(max, skip) => {
    try {
        const response = await axios.get('/judges/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all judges")
        return null;
    }
}

export const fetchProjects = async(max, skip) => {
    try {
        const response = await axios.get('/projects/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all projects")
        return null;
    }
}


export const checkIn = async(checkedIn, studentID) => {
    try {
        if (checkedIn === true) {
            const response = await axios.post('/person/check-in/' + studentID);
            return response.data;
        }
        else if (checkedIn === false) {
            const response = await axios.post('/person/check-out/' + studentID);
            return response.data;
        }
    }
    catch (error) {
        console.error("Error fetching all projects")
        return null;
    }
}

export const deletePerson = async(person_id) => {
    try {
        const response = await axios.delete('/person/delete/' + person_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting person")
        return null;
    }
}

export const deleteProject = async(project_id) => {
    try {
        const response = await axios.delete('/project/delete/' + project_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting project")
        return null;
    }
}

export const enterMockJudge = async () => {
    try {
        await axios.post("/user/mock/judge")
        return true;
    }
    catch (error) {
        console.error("Couldn't enter mock judge view");
        return null;
    }
}

export const enterMockAdmin = async () => {
    try {
        await axios.post("/user/mock/admin")
        return true;
    }
    catch (error) {
        console.error("Couldn't enter mock admin view");
        return null;
    }
}

export const enterMockSuperAdmin = async () => {
    try {
        await axios.post("/user/mock/superadmin")
        return true;
    }
    catch (error) {
        console.error("Couldn't enter mock admin view");
        return null;
    }
}

export const setUserID = async (person_id) => {
    try {
        const response = await axios.post('/user/person_id/set/' + person_id);
        return response.data;
    }
    catch (error) {
        console.error("Error setting user id")
        return null;
    }
}

export const getCheckedInCount = async () => {
    try {
        const response = await axios.get("/person/checked-in/count")
        return response.data;
    }
    catch (error) {
        console.error("Unauthorized to access checked in count")
        return null;
    }
}

export const getAllRubrics = async () => {
    try {
        const response = await axios.get("/rubric/select/-1/0")
        return response.data;
    } catch (error) {
        console.error("Error when fetching all rubrics")
        return null;
    }
}

export const deleteRubric = async (id) => {
    try {
        const response = await axios.delete('/rubric/delete/' + id);
        return true;
    }
    catch (error) {
        console.error("Error deleting rubric")
        return null;
    }
}

export const changeRubric = async (rubric) => {
    try {
        rubric.update_completed_status = 1;
        const response = await axios.post('/rubric/update/by_id/' + rubric.id, rubric)
        return response.data;
    }
    catch (error) {
        console.error("Error updating rubric")
        return null;
    }
};

export const fetchTemplates = async () => {
    try {
        const response = await axios.get('/rubric/templates');
        return response.data.templates;
    }
    catch (error) {
        console.error("Error fetching templates")
        return null;
    }
}

export const createRubric = async (data) => {
    if(data.project_id === "" || data.project_id === null || data.project_id === undefined) {
        console.error("invalid project id")
        return null;
    }
    if(data.template_id === "" || data.template_id === null || data.template_id === undefined) {
        console.error("invalid template id")
        return null;
    }
    if(data.judge_id === "" || data.judge_id === null || data.judge_id === undefined) {
        console.error("invalid judge id")
        return null;
    }

    try {
        const response = await axios.post('/rubric/add/template/' + data.template_id +
            '/project/' + data.project_id + '/judge/' + data.judge_id);
        return response.data;
    }
    catch (error) {
        console.error("Error creating rubric")
        return null;
    }
}


export const regionDefaultTemplate = async () => {
    try {
        const response = await axios.get('/rubric/template/user_region/default');
        return response.data;
    }
    catch (error) {
        console.error("Error getting default template")
        return null;
    }
}

export const getJudgePassword = async (judgeId) => {
    try {
        const response = await axios.get('/judge/password/get/' + judgeId);
        return response.data;
    }
    catch (error) {
        console.error("Error getting judge password: " + error)
        return null;
    }
}

export const getRubricByID = async (rubricID) => {
    try {
        const response = await axios.get('/rubric/get/' + rubricID);
        return response.data;
    }
    catch (error) {
        console.error("Error getting rubric by ID")
        return null;
    }
}

export const createJudge = async (data) => {
    try {
        const response = await axios.post('/create_judge', data);
        return response.data;
    }
    catch (error) {
        console.error("Error creating judge")
        return null;
    }
}

export const createStudent = async (data) => {
    try {
        const response = await axios.post('/create_student', data);
        return response.data;
    }
    catch (error) {
        console.error("Error creating student")
        return null;
    }
}

export const createProject = async (data) => {
    try {
        const response = await axios.post('/create_project', data);
        return response.data;
    }
    catch (error) {
        console.error("Error creating project")
        return null;
    }
}

export const updateJudge = async (data, id) => {
    try {
        const response = await axios.post('/judge/update/' + id, data);
        return response.data;
    }
    catch (error) {
        console.error("Error updating judge")
        return null;
    }
}

export const updateProject = async (data, id) => {
    try {
        const response = await axios.post('/project/update/' + id, data);
        return response.data;
    }
    catch (error) {
        console.error("Error updating project")
        return null;
    }
}

export const updateStudent = async (data, id) => {
    try {
        const response = await axios.post('/student/update/' + id, data);
        return response.data;
    }
    catch (error) {
        console.error("Error updating student")
        return null;
    }
}

export const getMetadata = async () => {
    try {
        const response = await axios.get('/management/metadata');
        return response.data;
    }
    catch (error) {
        console.error("Error fetching metadata")
        return null;
    }
}

export const setStartDateTime = async (start) => {
    try {
        const response = await axios.post('/management/set_start_time/' + start)
        return response.data
    }
    catch (error) {
        console.error("Error setting fair start time")
        return null;
    }
}

export const setEndDateTime = async (end) => {
    try {
        const response = await axios.post('/management/set_end_time/' + end)
        return response.data
    }
    catch (error) {
        console.error("Error setting fair end time")
        return null;
    }
}

export const runDiagnostics = async () => {
    try {
        const response = await axios.get('/management/diagnostics')
        return response.data
    }
    catch (error) {
        console.error("Error running diagnostics")
        return null;
    }
}

export const getBackupList = async () => {
    //management/backup_list
    try {
        const response = await axios.get('/management/backup/list')
        return response.data
    }
    catch (error) {
        console.error("Error fetching backup list")
        return null;
    }
}

export const getBackupData = async (id) => {
    try {
        const response = await axios.get('/management/backup/fetch/' + id)
        return response.data.backup
    }
    catch (error) {
        console.error("Error fetching backup data")
        return null;
    }
}

export const logout = async (setUserData) => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiry');
  setUserData(null)
}

export const restoreBackup = async (id) => {
    try {
        const response = await axios.post('/management/backup/restore/' + id)
        return response.data
    }
    catch (error) {
        console.error("Error restoring backup")
        return null;
    }
}

export const deleteBackup = async (id) => {
    try {
        const response = await axios.delete('/management/backup/delete/' + id)
        return response.data
    }
    catch (error) {
        console.error("Error deleting backup")
        return null;
    }
}

export const createBackup = async () => {
    try {
        const response = await axios.post('/management/backup/create/scratch')
        return response.data
    }
    catch (error) {
        console.error("Error creating backup")
        return null;
    }
}

export const createBackupFromFile = async (data) => {
    try {
        const response = await axios.post('/management/backup/create/file', {data:data})
        return response.data
    }
    catch (error) {
        console.error("Error creating backup")
        return null;
    }
}

export const exportData = async (columns, unified_file, format) => {
    // management/export
    try {
        const response = await axios.post('/management/export', {export_tables: columns, unified_excel_sheet: unified_file, format: format})
        return response.data
    }
    catch (error) {
        console.error("Error exporting data")
        return null;
    }
}

export const getRubricCompletedRatio = async () => {
    // rubrics/completed_ratio
    try {
        const response = await axios.get('/rubrics/completed_ratio')
        return response.data
    }
    catch (error) {
        console.error("Error fetching rubric completion ratio")
        return null;
    }
}

export const generateProjectTags = async (id) => {
    //project/generate_tags/{project_id}
    try {
        const response = await axios.post('/project/generate_tags/' + id)
        return response.data
    }
    catch (error) {
        console.error("Error generating project tags")
        return null;
    }
}

export const massGenerateProjectTags = async () => {
    // project/mass_generate_tags
    try {
        const response = await axios.post('/project/mass_generate_tags')
        return response.data
    }
    catch (error) {
        console.error("Error generating project tags")
        return null;
    }
}

export const generateMockData = async (data) => {
    // management/mock_data/create
    try {
        const response = await axios.post('/management/mock_data/create', data)
        return response.data
    }
    catch (error) {
        console.error("Error generating mock data")
        return null;
    }
}

export const deleteDatabase = async () => {
    // database
    try {
        const response = await axios.delete('/database')
        return response.data
    }
    catch (error) {
        console.error("Error deleting database")
        return null;
    }
}

export const massGenerateUsers = async () => {
    // /user/mass_create
    try {
        const response = await axios.post('/user/mass_create')
        return response.data
    }
    catch (error) {
        console.error("Error generating users")
        return null;
    }
}

export const generateRubrics = async (config) => {
    // rubrics/assign
    try {
        const response = await axios.post('/rubrics/assign', config)
        return response.data
    }
    catch (error) {
        console.error("Error generating rubrics")
        return null;
    }
}


export const changePassword = async(username, newPassword) => {
    try {
        const response = await axios.post('/user/set_password', {
            username: username,
            new_password: newPassword
        })
        return response.data
    }
    catch (error) {
        console.error("Error changing password")
        return null;
    }
}


export const getJudgeRatingsForProject = async (project) => {
    try {
        const response = await axios.get('/project/judge_ratings/' + project)
        return response.data
    }
    catch (error) {
        console.error("Error fetching judge ratings")
        return null;
    }
}