import axios from './axios';
import qs from 'qs';

export const login = async (credentials) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const data = qs.stringify({
    username: credentials.username,
    password: credentials.password,
  });

  return axios.post('/token/' + credentials.region.toLowerCase(), data, { headers });
};

export const signup = async (credentials, region) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (!region) {
    throw new Error('Region is required');
  }

  try {
    const response = await axios.post(`/user/create/region/${region}`, credentials, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || 'Signup failed');
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};


export const isTokenExpired = () => {
  const token = localStorage.getItem('token');
  const tokenExpiry = localStorage.getItem('tokenExpiry');
  if (!token || !tokenExpiry) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix format
  if (currentTime > tokenExpiry) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    return true;
  }
  return false;
};
