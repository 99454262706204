import React from 'react';
import {useNavigate} from "react-router-dom";

import {Alert, Button, Typography} from "@mui/material";
import RubricJudgeView from "./RubricJudgeView";
import '../../styles/dashboard.css';
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DefaultPageContainer from "../reusable/DefaultPageContainer";
import {loadDataForPage} from  "../reusable/dataLoader";
const Rubric = (props) => {
    const navigate = useNavigate();
    const [loadedData, setLoadedData] = React.useState(false);

    const res = loadDataForPage(loadedData, setLoadedData, props);
    if(res[0] === false) {
        return res[1]
    }

    if(props.userData?.is_admin >= 1){
        navigate('/auth/admin/dashboard/');
    }

    if (props.userData.rubricData === undefined || props.userData.rubricData === null) {
        return (
            <div>
                <h1>Error</h1>
                <p>You likely don't have permission to view this rubric.</p>
            </div>
        )
    }

    let rubrics = props.userData.rubricData.rubrics

    // get rubric_judge_view from url, it comes after last slash
    const url = window.location.href;
    const project_id = url.substring(url.lastIndexOf('/') + 1);
    const relevant_rubric = rubrics.filter((rubric) => String(rubric.project_id).toString() === project_id)[0]

    const navToDashboard = () => {
        navigate('/auth/dashboard/');
    };

    if(relevant_rubric === undefined){
        return (<>
            <p>Error loading rubric page, likely due to a bad URL</p>
            <Button variant="contained" sx={{ mb: 1 }} onClick={navToDashboard}>Back to Dashboard</Button>
        </>)
    }

    return (
        <DefaultPageContainer userData={props.userData} setUserData={props.setUserData}>
            <Alert icon={<TipsAndUpdatesIcon fontSize="inherit"/>} severity="info" sx={{marginBottom:"20px"}}>
                    Your changes will auto-save, feel free to leave this page and come back later.
            </Alert>

            <Typography variant="h5">Grade Project</Typography>
            <Typography>Project: {relevant_rubric.project_name} (#{relevant_rubric.project_id})</Typography>
            <RubricJudgeView userData={props.userData} rubric={relevant_rubric}/>

        </DefaultPageContainer>
    );
};

export default Rubric;