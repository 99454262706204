import React, { useState, useEffect } from 'react';
import {Box, Divider, Typography} from "@mui/material";
import SignOutButton from "./SignOutButton";
import ReloadDataButton from "./ReloadDataButton";
import DashboardButton from "./DashboardButton";
import { MockViewButtons } from "../reusable/MockViewButtons";

const LeftSidebar = (props) => {
    const [greetingIndex, setGreetingIndex] = useState(0);
    const greetings = [
        "Hello", "Howdy", "Greetings", "Salutations", "Hi", "Hey", "What's up",
        "Good day", "Hello there", "What's good", "Hey there", "Hi there",
        "How's life", "👋", "Bonjour", "Hola", "Ciao", "🌞🌞🌞"
    ];

    useEffect(() => {
        setGreetingIndex(Math.floor(Math.random() * greetings.length));
    }, [greetings.length]);

    // Define an array of button configurations for easier mapping
    const buttons = [
        {
            component: <DashboardButton variant="contained"/>,
            label: "Dashboard"
        },
        {
            component: <ReloadDataButton variant="contained" setUserData={props.setUserData} color="#5d81ba" />,
            label: "Reload Data"
        },
        {
            component: <SignOutButton variant="contained" setUserData={props.setUserData} />,
            label: "Sign Out"
        },
        {
          component: <Divider sx={{ borderBottomWidth: 2, backgroundColor: "#000000", marginTop: "2px", marginBottom: "2px"}} />,
            label: "Divider"
        },
        {
            component: <MockViewButtons variant="contained" userData={props.userData} setUserData={props.setUserData} />,
            label: "Mock Views"
        }
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                padding: '0px',
                boxSizing: 'border-box',
                backgroundColor:"inherit"
            }}
        >
            <Typography className="truncateWrap" variant="h6" sx={{ marginTop: "15px", marginBottom: "20px" }}>
                {greetings[greetingIndex]}, {props.name?.charAt(0).toUpperCase() + props.name?.slice(1).toLowerCase()}.
            </Typography>

            {buttons.map((btn, index) => (
                <div key={index}>
                    {btn.component}
                </div>
            ))}

        </Box>
    );
}

export default LeftSidebar;
