import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    TextField, // Import TextField for the search box
    Typography,
} from '@mui/material';
import {fetchJudges} from "../../api/api";
import {useTheme} from "@mui/styles";

const JudgeListDialog = (props) => {
    const [judges, setJudges] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const theme = useTheme();
    useEffect(() => {
        // Fetch Judges when the dialog is opened
        if (props.open) { // Optional: Fetch only when dialog is open
            fetchJudges(-1, 0).then((response) => {
                if (response === null || response === undefined) {
                    console.error("Could not fetch judge data...");
                    return;
                }
                setJudges(response);
            }).catch((error) => {
                console.error("Error fetching judges:", error);
            });
        }
    }, [props.open]); // Re-fetch if 'open' changes

    // Filter judges based on search query
    const filteredJudges = judges.filter((judge) => {
        const firstName = judge.first_name.toLowerCase();
        const lastName = judge.last_name.toLowerCase();
        const query = searchQuery.toLowerCase();
        return firstName.includes(query) || lastName.includes(query) || judge.id.toString().includes(query);
    });

    return (
        <Dialog
            sx={{minWidth: "300px", maxHeight: "600px"}} // Increased minWidth for better layout
            open={props.open}
            onClose={() => props.onClose(null)}
            fullWidth // Make dialog take full width up to minWidth
            maxWidth="sm" // Set a maximum width
        >
            <DialogTitle>Select a Judge</DialogTitle>
            <DialogContent dividers>
                {/* Search Box */}
                <TextField
                    label="Search Judges"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by first or last name"
                />
                {/* Judges List */}
                {filteredJudges.length > 0 ? (
                    <List>
                        {filteredJudges.map((judge) => (
                            <ListItem key={judge.id} disablePadding>
                                <ListItemButton
                                    sx={{
                                        background: props.currentUserID === judge.id ? "#1565c0" : theme.palette.lightGreyLight,
                                        borderWidth: "1px",
                                        borderColor: "#000000",
                                        color: props.currentUserID === judge.id ? "white" : "black",
                                        padding: "10px",
                                        marginBottom: "5px",
                                        borderRadius: "4px",
                                        /* Set hover color */
                                        '&:hover': {
                                            backgroundColor: props.currentUserID === judge.id ? "#0d47a1" : theme.palette.lightGreyDark,
                                        },
                                    }}
                                    onClick={() => props.onClose(judge.id)}
                                >
                                    <Typography variant="body1">
                                        {judge.first_name} {judge.last_name} {"<"}ID: {judge.id}{">"}{" "}
                                        {props.currentUserID === judge.id && "(You!)"}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No judges found.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JudgeListDialog;
