import React from 'react';
import {
    Box, Button,
    Card,
    CardContent, CircularProgress,
    Typography,

} from "@mui/material";
import {useTheme} from "@mui/styles";
import SellIcon from '@mui/icons-material/Sell';
import {massGenerateProjectTags} from "../../api/api";

export const MassGenerateTagsCard = () => {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const generateTags = async () => {
        setLoading(true);
        const res = await massGenerateProjectTags();
        if (res === null) {
            setLoading(false);
            setError("Failed to generate tags");
            return;
        }
        setLoading(false);
        setError("");
    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight, padding: 2, position: 'relative'}}>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <SellIcon
                    sx={{
                        color: '#d92020',

                    }}
                />
                <Typography sx={{marginLeft: '10px'}} variant="h5">
                    Mass Generate Tags
                </Typography>
            </Box>


            {/* Content Section */}
            <CardContent>
                <Typography variant="body2">
                    Generate the tags for all projects in the fair. This uses an AI to pick out subjects from the
                    project descriptions.
                    <br/><br/>
                    It is not 100% accurate, but it can help speed up the process of tagging projects.
                    <br/><br/>
                    Running should take up to a minute.
                </Typography>
                <br/>

                <Box sx={{width: "100%"}}>
                    <Typography sx={{color: "#d93434"}}>{error}</Typography>

                    {
                        (loading) ?

                            <Box sx={{
                                display: 'flex',
                                marginTop: "10px",
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <CircularProgress size={24} sx={{color: theme.palette.standardBlue}}/>
                            </Box>

                            :
                            <Button
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    backgroundColor: theme.palette.standardBlue,
                                    width: "100%",
                                    '&:hover': {
                                        backgroundColor: theme.palette.standardBlueSelect,
                                    },
                                }}
                                onClick={generateTags}
                            >
                                Generate
                            </Button>
                    }

                </Box>
            </CardContent>
        </Card>)
}
