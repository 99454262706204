import React from 'react'
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material'
import {generateProjectTags} from "../../api/api";
import {useTheme} from "@mui/styles";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export const TagGenerationDialog = (props) => {
    const [generating, setGenerating] = React.useState(false);
    const [tags, setTags] = React.useState(props.project?.tags || "");
    const theme = useTheme();

    // use effect to update tags
    React.useEffect(() => {
        setTags(props.project?.tags || "");
    }, [props.project])

    const generateTags = async () => {
        let id = props.project.id;
        setGenerating(true);
        const res = await generateProjectTags(id)
        if (res != null) {
            setTags(res.tags);
            props.project.tags = res.tags;
            if(res.tags === "") {
                setTags("Could not parse out any relevant keywords from the project description.")
            }
        }
        setGenerating(false);
    }

    return (
        <Dialog open={props.open} onClose={() => {
            setTags(props.project?.tags || "")
            props.onClose();
        }}>
                  <DialogTitle>Generate Tags</DialogTitle>

            <DialogContent>
                <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                margin: 1,
                backgroundColor: theme.palette.lightGreyLight,
                borderRadius: 2,
                minWidth: "300px",
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                textAlign: 'center',
            }}>
                                    {(tags === "") ? <Typography>No tags found!</Typography> : <Typography>{tags}</Typography>}

            </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', '&:hover':{color:"#000000"}}}
                 onClick={generateTags}>

                <Button
                    onClick={generateTags}
                    disabled={generating}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: "#FFFFFF",
                        backgroundColor: theme.palette.standardBlue,
                        '&:hover': {
                            backgroundColor: theme.palette.standardBlueSelect,
                            color: "#000000"

                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <AutoFixHighIcon
                                   aria-label="generate tags"/>

                    <Typography variant="body1" sx={{
                        paddingLeft: "25px",
                        color: "inherit",

                    }}>
                        Generate Tags
                    </Typography>
                </Button>
            </Box>
            </DialogContent>
        </Dialog>
    )
}