import React from 'react';
import {Box, Button, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SegmentIcon from '@mui/icons-material/Segment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GradingIcon from '@mui/icons-material/Grading';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import {useTheme} from "@mui/styles";

const RightAdminSidebar = (props) => {
  const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "inherit"
            }}
        >
            <Typography variant="h6" sx={{mb: 2}}>
                Admin Pages:
            </Typography>
            <div style={{padding: "0px"}}>
                <Button
                    onClick={() => navigate("/auth/admin/dashboard")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: "black",
                        '&:hover': {
                            backgroundColor: 'rgb(30,60,117)',
                            color: " #FFFFFF"
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <HomeIcon sx={{color: "#2c4baf"}} onClick={() => navigate("/auth/admin/dashboard")}
                                        aria-label="admin dashboard"/>

                    <Typography variant="body1" sx={{
                        marginLeft: "25px",
                        '&:hover': {color: 'rgb(255,255,255)'}
                    }}>
                        Dashboard
                    </Typography>
                </Button>

                <Button
                    onClick={() => navigate("/auth/admin/management")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: "black",
                        '&:hover': {
                            backgroundColor: 'rgb(37,141,86)',
                            color: " #FFFFFF"
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <ManageAccountsIcon sx={{color: "#2caf69"}} onClick={() => navigate("/auth/admin/management")}
                                        aria-label="registration management"/>

                    <Typography variant="body1" sx={{
                        paddingRight: "10px",
                        '&:hover': {color: 'rgb(255,255,255)'}
                    }}>
                        Registration Management
                    </Typography>
                </Button>

                <Button
                    onClick={() => navigate("/auth/admin/template-management")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: "black",
                        '&:hover': {
                            backgroundColor: 'rgb(141,120,37)',
                            color: "#FFFFFF"
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <SegmentIcon sx={{color: "#b9a72f"}} onClick={() => navigate("/auth/admin/template-management")}
                                 aria-label="template management"/>

                    <Typography variant="body1" sx={{
                        paddingLeft: "20px",
                        '&:hover': {color: 'rgb(255,255,255)'}
                    }}>
                        Template <br/>Management
                    </Typography>
                </Button>

                <Button
                    onClick={() => navigate("/auth/admin/management/check-in")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: "black",
                        '&:hover': {
                            backgroundColor: 'rgb(169,70,48)',
                            color: "#FFFFFF"
                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <HowToRegIcon sx={{color: "#ef5946"}} onClick={() => navigate("/auth/admin/management/check-in")}
                                  aria-label="checked in people"/>


                    <Typography variant="body1" sx={{
                        marginLeft: "10px",
                        '&:hover': {
                            color: "#FFFFFF"
                        }
                    }}>
                        Checked In People
                    </Typography>
                </Button>

                <Button
                    onClick={() => navigate("/auth/admin/rubrics")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(168,47,157)',
                            color: "#FFFFFF"

                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <GradingIcon sx={{color: "#e73fa4"}} onClick={() => navigate("/auth/admin/rubrics")}
                                 aria-label="rubric listing"/>

                    <Typography variant="body1" sx={{
                        paddingLeft: "25px",
                        '&:hover': {color: 'rgb(255,255,255)'}
                    }}>
                        Rubric Listing
                    </Typography>
                </Button>

                <Button
                    onClick={() => navigate("/auth/admin/management/fair")}
                    sx={{
                        textTransform: 'none',
                        width: "100%",
                        mb: 1,
                        color: 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(59,59,59)',
                            color: "#FFFFFF"

                        },
                        justifyContent: "flex-start"
                    }}
                >
                    <SettingsIcon sx={{color: theme.palette.darkGrey }} onClick={() => navigate("/auth/admin/management/fair")}
                                 aria-label="fair management"/>

                    <Typography variant="body1" sx={{
                        paddingLeft: "15px",
                        '&:hover': {color: 'rgb(255,255,255)'}
                    }}>
                        Fair Management
                    </Typography>
                </Button>
            </div>
        </Box>
    );
}

export default RightAdminSidebar;