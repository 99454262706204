import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
    createBackup,
    createBackupFromFile,
    deleteBackup,
    getBackupData,
    getBackupList,
    restoreBackup,
} from '../../api/api';
import SaveIcon from '@mui/icons-material/Save';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import BackupIcon from '@mui/icons-material/Backup';
import {useTheme} from "@mui/styles";

dayjs.extend(utc);
dayjs.extend(timezone);

export const BackupDataCard = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [backupList, setBackupList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [selectedBackup, setSelectedBackup] = useState(null);

    const [downloadingBackup, setDownloadingBackup] = useState(false);
    const [creatingBackup, setCreatingBackup] = useState(false);
    const [errorBackingUp, setErrorBackingUp] = useState(false);

    const [workingBackup, setWorkingBackup] = useState(false);

    const fetchBackupList = async () => {
        setLoading(true);
        const res = await getBackupList();
        if (res) {
            setBackupList(res);
        } else {
            console.error('Error fetching backup list');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchBackupList();
    }, []);

    const handleOpenDialog = (action, backup) => {
        setDialogAction(action);
        setSelectedBackup(backup);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogAction(null);
        setSelectedBackup(null);
    };

    const handleConfirmAction = async () => {
        if (dialogAction && selectedBackup) {
            setWorkingBackup(true);
            setOpenDialog(false);

            if (dialogAction === 'restore') {
                const res = await restoreBackup(selectedBackup);
                if (res === null) {
                    console.error('Backup failed to restore successfully');
                }

            } else if (dialogAction === 'delete') {
                const res = await deleteBackup(selectedBackup);
                if (res === null) {
                    console.error('Error deleting backup');
                }
                fetchBackupList();
            }
            setWorkingBackup(false);
        }
        handleCloseDialog();
    };

    const formatDate = (timestamp) => {
        return dayjs.unix(parseInt(timestamp)).tz('America/New_York').format('MMMM D [@] h:mm A');
    };

    const fetchAndDownloadBackup = async (backup) => {
        setDownloadingBackup(true);

        const res = await getBackupData(backup);
        if (res === null) {
            console.error('Error fetching backup data');
            setDownloadingBackup(false);
            return;
        }

        // Convert base64 string to Blob
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a download link and trigger a click event
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `backup_${backup}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingBackup(false);
    };

    const makeBackup = async () => {
        setCreatingBackup(true);
        const res = await createBackup();
        if (res === null) {
            console.error('Error creating backup');
        }
        await fetchBackupList();
        setCreatingBackup(false);
    };

    const importBackup = async () => {
        setCreatingBackup(true);

        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.xlsx';
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];

            // If user cancels or no file selected, bail out
            if (!file) {
                setCreatingBackup(false);
                return;
            }

            const reader = new FileReader();
            reader.onload = async (evt) => {
                const base64 = evt.target.result.split(',')[1];
                const res = await createBackupFromFile(base64);
                if (res === null) {
                    setErrorBackingUp(true);
                    console.error('Error creating backup');
                }
                await fetchBackupList();
                setCreatingBackup(false);
            };
            reader.readAsDataURL(file);
        };

        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
    };

    return (
        <Card sx={{backgroundColor: theme.palette.lightGreyLight}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <SaveIcon sx={{color: '#be6322', marginTop: '10px', marginLeft: '10px'}}/>
                <Typography sx={{marginTop: '10px', marginLeft: '10px'}} variant="h5">
                    Backup Database
                </Typography>
            </Box>
            <CardContent>
                <Typography variant="body2">
                    Save the current state of database (in the database) or load from a backup
                </Typography>

                <Typography sx={{marginTop: '10px', marginLeft: '10px'}} variant="h6">
                    Create A Backup
                </Typography>
                <Box sx={{marginTop: '10px', marginLeft: '10px'}}>
                    <Button
                        disabled={creatingBackup}
                        onClick={makeBackup}
                        sx={{
                            textTransform: 'none',
                            width: '100%',
                            justifyContent: 'flex-start',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(67,177,217)',
                                color: 'white',
                            },
                        }}
                    >
                        <LibraryAddIcon sx={{color: '#266eb7', mr: 1}}/>
                        <Typography variant="body1">Create Backup</Typography>
                    </Button>

                    <Button
                        onClick={importBackup}
                        disabled={creatingBackup}
                        sx={{
                            textTransform: 'none',
                            width: '100%',
                            justifyContent: 'flex-start',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(67,177,217)',
                                color: 'white',
                            },
                        }}
                    >
                        <BackupIcon sx={{color: '#266eb7', mr: 1}}/>
                        <Typography variant="body1">Import Backup</Typography>
                    </Button>
                </Box>

                <Typography sx={{marginTop: '10px', marginLeft: '10px'}} variant="h6">
                    Backup Listing
                </Typography>

                <Box sx={{maxHeight: '400px', overflowY: 'auto', marginTop: '10px'}}>
                    {loading || workingBackup ? (
                        <CircularProgress size={24}/>
                    ) : (
                        backupList.map((backup) => {
                            const timestamp = backup.split('.')[1];
                            const formattedDate = formatDate(timestamp);

                            return (
                                <Card key={backup} sx={{marginBottom: '10px'}}>
                                    <CardContent>
                                        <Typography variant="h6">{formattedDate}</Typography>

                                        {/* Restore Button */}
                                        <Button
                                            disabled={workingBackup}
                                            onClick={() => handleOpenDialog('restore', timestamp)}
                                            sx={{
                                                textTransform: 'none',
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(67,177,217)',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <RestoreIcon sx={{color: '#266eb7', mr: 1}}/>
                                            <Typography variant="body1">Restore</Typography>
                                        </Button>

                                        {/* Delete Button */}
                                        <Button
                                            disabled={workingBackup}
                                            onClick={() => handleOpenDialog('delete', timestamp)}
                                            sx={{
                                                textTransform: 'none',
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(217,67,67)',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <DeleteForeverIcon sx={{color: '#b72626', mr: 1}}/>
                                            <Typography variant="body1">Delete Forever</Typography>
                                        </Button>

                                        {/* Download Button */}
                                        <Button
                                            disabled={workingBackup}
                                            onClick={() => fetchAndDownloadBackup(timestamp)}
                                            disabled={downloadingBackup}
                                            sx={{
                                                textTransform: 'none',
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(171,171,171)',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <FileDownloadIcon sx={{color: '#656565', mr: 1}}/>
                                            <Typography variant="body1">Download (.xlsx)</Typography>
                                        </Button>
                                    </CardContent>
                                </Card>
                            );
                        })
                    )}
                    {!loading && backupList.length === 0 && <Typography>No backups found</Typography>}
                </Box>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Confirm Backup Action</DialogTitle>
                    <DialogContent>
                        Are you sure you want to{' '} <br/>
                        <span style={{color: 'red'}}>
              {dialogAction === 'restore'
                  ? 'RESTORE THE ENTIRE DATABASE TO THE STATE OF THIS BACKUP?'
                  : 'PERMANENTLY DELETE THIS BACKUP?'}
            </span>

                        <br/>
                        This action cannot be undone.
                        <br/>
                        {dialogAction === 'restore' && (
                            <div style={{marginTop: '5px'}}>
                                Restoring can take a few seconds, please be patient.
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={handleConfirmAction}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
            <Dialog open={errorBackingUp} onClose={() => setErrorBackingUp(false)}>
                <DialogTitle>Error Creating Backup</DialogTitle>
                <DialogContent>
                    There was an error importing the backup. Did you make sure the data was originally exported by this
                    page?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorBackingUp(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};
